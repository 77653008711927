define('web/routes/application', ['exports'], function (exports) {
  var _Ember = Ember;
  var run = _Ember.run;
  exports['default'] = Ember.Route.extend({
    actions: {
      openModal: function openModal(modalName) {
        var renderOptions = {
          into: 'application',
          outlet: 'modal'
        };

        Ember.$('body').addClass('no-scroll');

        this.controller.set('isModalOpen', true);

        return this.render('modals.' + modalName, renderOptions);
      },

      closeModal: function closeModal() {
        var _this = this;

        Ember.$('body').removeClass('no-scroll');
        Ember.$('.modal__bg').fadeOut(300);
        run.later(function () {
          _this.controller.set('isModalOpen', false);
          return _this.disconnectOutlet({
            outlet: 'modal',
            parentView: 'application'
          });
        }, 300);
      }
    }
  });

  Ember.Route.reopen({
    help: Ember.inject.service("help-button"),

    actions: {
      willTransition: function willTransition() {
        if (this.get('currentUser')) {
          this.get('help').link();
        }
      }
    },
    activate: function activate() {
      var cssClass = this.toCssClass();

      if (cssClass !== 'application') {
        Ember.$('html').addClass(cssClass);
      } else {
        Ember.$('html').addClass('home');
      }
    },

    deactivate: function deactivate() {
      Ember.$('html').removeClass(this.toCssClass());
    },
    toCssClass: function toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize();
    }
  });
});