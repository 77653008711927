define('web/models/tax', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    taxId: attr('string'),
    taxName: attr('string'),
    landmarkId: attr('string'),
    landmarkName: attr('string'),
    rate: attr('number'),
    operatorExternalKey: attr('string'),
    partyExternalKey: attr('string'),
    operatorLegacyId: attr('string'),
    active: attr('boolean'),
    createdAt: attr('utc'),
    updatedAt: attr('utc')
  });
});