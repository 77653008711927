define("web/templates/modals/testimonial", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "web/templates/modals/testimonial.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "modal");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-header");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h2");
        dom.setAttribute(el4, "class", "form__header");
        var el5 = dom.createTextNode("Testimonial for Review");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "btn--close-modal btn");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "modal-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "for-review");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "for-review--header");
        var el5 = dom.createTextNode("Status:");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "confirm-status");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        dom.setAttribute(el6, "class", "confirm-status__text");
        var el7 = dom.createTextNode("Blacklist");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("p");
        dom.setAttribute(el6, "class", "confirm-status__text");
        var el7 = dom.createTextNode("Whitelist");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [1, 3]);
        var element3 = dom.childAt(element1, [5, 3]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element3, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element2);
        morphs[2] = dom.createMorphAt(element2, 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        morphs[4] = dom.createAttrMorph(element4, 'class');
        morphs[5] = dom.createElementMorph(element4);
        morphs[6] = dom.createMorphAt(element4, 1, 1);
        morphs[7] = dom.createAttrMorph(element5, 'class');
        morphs[8] = dom.createElementMorph(element5);
        morphs[9] = dom.createMorphAt(element5, 1, 1);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["modal__bg ", ["subexpr", "if", [["get", "isModalOpen", ["loc", [null, [1, 27], [1, 38]]]], "modal--open", "modal--closed"], [], ["loc", [null, [1, 22], [1, 70]]]]]]], ["element", "action", ["closeModal"], [], ["loc", [null, [5, 43], [5, 66]]]], ["inline", "inline-svg", ["assets/images/cross"], ["class", "icon icon-cross"], ["loc", [null, [5, 67], [5, 127]]]], ["inline", "ph-testimonial", [], ["testimonial", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 35], [8, 40]]]]], [], []]], ["loc", [null, [8, 6], [8, 42]]]], ["attribute", "class", ["concat", ["confirm-status--blacklist ", ["subexpr", "if", [["get", "isBlacklisted", ["loc", [null, [13, 51], [13, 64]]]], "blacklist", ""], [], ["loc", [null, [13, 46], [13, 81]]]]]]], ["element", "action", ["clickBlacklist"], [], ["loc", [null, [13, 83], [13, 110]]]], ["inline", "inline-svg", ["assets/images/admin-icons/blacklist-x"], ["class", "icon icon-blacklist-x"], ["loc", [null, [14, 10], [14, 94]]]], ["attribute", "class", ["concat", ["confirm-status--whitelist ", ["subexpr", "if", [["get", "isWhitelisted", ["loc", [null, [17, 51], [17, 64]]]], "whitelist", ""], [], ["loc", [null, [17, 46], [17, 81]]]]]]], ["element", "action", ["clickWhitelist"], [], ["loc", [null, [17, 83], [17, 110]]]], ["inline", "inline-svg", ["assets/images/admin-icons/whitelist-check"], ["class", "icon icon-whitelist-check"], ["loc", [null, [18, 10], [18, 102]]]]],
      locals: [],
      templates: []
    };
  })());
});