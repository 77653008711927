define('web/mixins/has-map-parent', ['exports', 'ember'], function (exports, _ember) {
  var readOnly = _ember['default'].computed.readOnly;
  exports['default'] = _ember['default'].Mixin.create({
    map: null,
    mapbox: readOnly('map.mapbox'),
    contentLayer: readOnly('map.layer'),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('map', this.nearestWithProperty('isMap'));
    }
  });
});