define('web/components/typeahead-input', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var isNone = _ember['default'].isNone;
  var run = _ember['default'].run;

  var UP = 38;
  var DOWN = 40;
  var ENTER = 13;

  var isPromiseALike = function isPromiseALike(maybePromise) {
    return maybePromise && typeof maybePromise.then === 'function';
  };

  var Suggestion = _ember['default'].Object.extend({
    isActive: computed('index', 'typeahead.suggestionIndex', function () {
      return this.get('index') === this.get('typeahead.suggestionIndex');
    }),

    displayValue: computed('item', 'item.name', function () {
      return this.get('item.name') || this.get('item');
    })
  });

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['type-ahead'],

    // public
    selectedItems: null,
    meta: null,
    allowDuplicates: false,

    // actions
    typeaheadCreate: null,
    typeaheadFocused: null,
    typeaheadRemoved: null,
    typeaheadSelected: null,

    // private
    suggestionIndex: null,
    suggestions: null,
    suggestionRequestCounter: 0,
    suggestionRequestLatest: 0,
    isSingle: false,
    search: '',
    isPending: false,
    isRejected: false,
    showSuggestions: false,

    displayItems: computed('selectedItems', 'selectedItems.[]', 'selectedItems.content', function () {
      var selectedItems = this.get('selectedItems');
      if (_ember['default'].isArray(selectedItems)) {
        return selectedItems;
      }

      this.set('isSingle', true);

      if (_ember['default'].isNone(selectedItems)) {
        return [];
      }

      if (isPromiseALike(selectedItems) && !get(selectedItems, 'content')) {
        return [];
      }

      return [selectedItems];
    }),

    canAdd: computed('displayItems.[]', 'max', function () {
      var max = this.get('max');
      return _ember['default'].isBlank(max) || max <= 0 || this.get('displayItems.length') < max;
    }),

    inputPlaceholder: computed('placeholder', 'displayItems.[]', function () {
      var placeholder = this.get('placeholder');
      if (_ember['default'].typeOf(placeholder) === 'function') {
        return placeholder(this.get('selectedItems'));
      }
      return placeholder;
    }),

    focusIn: function focusIn() /*e*/{
      var _this = this;

      if (this.get('isPending') || this.get('suggestions') !== null) {
        this.set('showSuggestions', true);
      } else if (this.get('typeaheadFocused')) {
        this._send('typeaheadFocused', this.attrs.selectedItems.value, function (suggestions) {
          var onSuggestions = function onSuggestions(suggestions) {
            var suggestionIndex = _this.get('suggestionIndex');

            _this.setProperties({
              isPending: false,
              showSuggestions: true,
              suggestions: suggestions.uniq().map(function (s, i) {
                return Suggestion.create({ item: s, index: i, typeahead: _this });
              }),
              suggestionIndex: suggestionIndex === null ? null : Math.min(suggestionIndex, get(suggestions, 'length') - 1)
            });
          };

          if (isPromiseALike(suggestions)) {
            _this.set('isPending', true);

            suggestions.then(onSuggestions, function (err) {
              _ember['default'].Logger.error('TypeaheadInputComponent#_focusIn', err);
              _this.setProperties({
                suggestions: null,
                isRejected: true,
                isPending: false
              });
            });
          } else {
            onSuggestions(suggestions);
          }
        });
      }
    },

    focusOut: function focusOut() {
      if (this.get('showSuggestions')) {
        _ember['default'].run.later(this, this._hideSuggestions, 300);
      }
    },

    keyDown: function keyDown(e) {
      var suggestions = this.get('suggestions');
      var suggestionIndex = this.get('suggestionIndex');
      var keyCode = e.keyCode;
      var hasSuggestions = _ember['default'].isPresent(suggestions);
      var search = this.get('search');

      if (keyCode === DOWN && hasSuggestions) {
        if (suggestionIndex === null) {
          this.set('suggestionIndex', 0);
        } else if (suggestionIndex < suggestions.length - 1) {
          this.incrementProperty('suggestionIndex');
        }
        return false;
      } else if (keyCode === UP && suggestionIndex !== null && hasSuggestions) {
        if (suggestionIndex === 0) {
          this.set('suggestionIndex', null);
        } else {
          this.decrementProperty('suggestionIndex');
        }
        return false;
      } else if (keyCode === ENTER && (search || this.get('showSuggestions'))) {
        e.preventDefault();
        if (suggestionIndex === null) {
          this._requestCreate(search);
        } else if (hasSuggestions) {
          if (this.get('isSingle')) {
            var universe = document.querySelectorAll('input');

            var universeArr = Array.prototype.map.call(universe, function (item) {
              return item;
            });
            var universeIdArr = Array.prototype.map.call(universe, function (item) {
              return item.id;
            });
            var thisId = this.$('input')[0].id;

            _ember['default'].$(universeArr[universeIdArr.indexOf(thisId) + 1]).focus();
          }
          this.send('addSelected', suggestions.objectAt(suggestionIndex));
        }
        return false;
      }
    },

    _requestCreate: function _requestCreate(search) {
      var _this2 = this;

      this._send('typeaheadCreate', search, function (newItem) {
        // handle promise or directly provided item
        var onItemAvailable = function onItemAvailable(availableItem) {
          _this2._clear();

          if (!isNone(availableItem)) {
            _this2._addItem(availableItem);
          }
        };

        if (isPromiseALike(newItem)) {
          newItem.then(onItemAvailable, function (err) {
            _ember['default'].Logger.error('TypeaheadInputComponent#_requestCreate', err);
          });
        } else {
          onItemAvailable(newItem);
        }
      });
    },

    _requestSearch: function _requestSearch(search) {
      var _this3 = this;

      if (_ember['default'].isBlank(search)) {
        this._clear();
        return;
      }

      this.set('search', search);
      this.set('isPending', true);
      this.set('showSuggestions', true);

      var suggestionRequestCounter = this.incrementProperty('suggestionRequestCounter');

      if (this.attrs.typeaheadRequest) {
        this.attrs.typeaheadRequest(search, this.get('selectedItems'), function (suggestions) {
          var onSuggestions = function onSuggestions(suggestions) {
            if (suggestionRequestCounter > _this3.get('suggestionRequestLatest')) {

              var suggestionIndex = _this3.get('suggestionIndex');
              _this3.setProperties({
                isPending: false,
                suggestionRequestLatest: suggestionRequestCounter,
                suggestions: suggestions.uniq().map(function (s, i) {
                  return Suggestion.create({ item: s, index: i, typeahead: _this3 });
                }),
                suggestionIndex: suggestionIndex === null ? null : Math.min(suggestionIndex, get(suggestions, 'length') - 1)
              });
            }
          };

          if (isPromiseALike(suggestions)) {
            _this3.set('isPending', true);

            suggestions.then(onSuggestions, function (err) {
              _ember['default'].Logger.error('TypeaheadInputComponent#_requestSearch', err);
              if (suggestionRequestCounter > _this3.get('suggestionRequestLatest')) {
                _this3.setProperties({
                  suggestions: null,
                  isRejected: true,
                  isPending: false
                });
              }
            });
          } else {
            onSuggestions(suggestions);
          }
        });
      }
    },

    _hideSuggestions: function _hideSuggestions() {
      if (this.get('showSuggestions')) {
        this.setProperties({
          suggestionIndex: null,
          showSuggestions: false
        });
      }
    },

    _clear: function _clear() {
      this.setProperties({
        search: '',
        suggestions: null,
        suggestionIndex: null,
        isPending: false,
        isRejected: false,
        showSuggestions: false
      });
    },

    _addItem: function _addItem(item) {
      if (this.get('isSingle')) {
        this.set('selectedItems', item);
      } else {
        var selectedItems = this.get('selectedItems');
        if (this.get('allowDuplicates') || !selectedItems.contains(item)) {
          selectedItems.pushObject(item);
        }
      }
    },

    _send: function _send(actionName) {
      var meta = this.get('meta');

      for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      if (isNone(meta)) {
        this.sendAction.apply(this, [actionName].concat(args));
      } else {
        this.sendAction.apply(this, [actionName, meta].concat(args));
      }
    },

    actions: {
      changeSearch: function changeSearch(search) {
        run.debounce(this, this._requestSearch, search, 350);
      },

      addSelected: function addSelected(suggestion) {
        var _this4 = this;

        this._clear();
        var item = suggestion.get('item');

        if (this.attrs.typeaheadSelected) {
          this.attrs.typeaheadSelected(item, function (itemToAdd) {
            if (!isNone(itemToAdd)) {
              _this4._addItem(itemToAdd);
            }
          });
        } else {
          this._addItem(item);
        }
      },

      removeSelected: function removeSelected(selectedItem) {
        var _this5 = this;

        var onRemoved = function onRemoved(removeItem) {
          if (!isNone(removeItem)) {
            if (_this5.get('isSingle')) {
              _this5.set('selectedItems', null);
            } else {
              _this5.get('selectedItems').removeObject(removeItem);
            }
          }
        };

        if (this.get('typeaheadRemoved')) {
          this._send('typeaheadRemoved', selectedItem, onRemoved);
        } else {
          onRemoved(selectedItem);
        }
      }
    }
  });
});