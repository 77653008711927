define('web/services/ph-tour', ['exports', 'web/services/tour', 'ember'], function (exports, _webServicesTour, _ember) {
  exports['default'] = _webServicesTour['default'].extend({

    setupTour: function setupTour(authController) {
      this.set('defaults', {
        highlightClass: 'highlight',
        scrollTo: false
      });

      this.set('steps', [{
        id: 'payment-accounts',
        options: {
          attachTo: {
            element: '#shepherd-step-1',
            on: 'right'
          },
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              authController.send('toggleAvatarMenu');
              _ember['default'].run.next(function () {
                resolve();
              });
            });
          },
          builtInButtons: [{
            classes: 'btn btn--sm btn--disabled',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--sm btn--default',
            text: 'Next',
            type: 'next'
          }],
          classes: 'shepherd',
          text: ['Make sure you set up your bank account so you may accept wires from the Admin.', 'This is also where you will set up your payment accounts, ie. Authorize.net or GoEmerchant.'],
          when: {
            hide: function hide() {
              authController.send('toggleAvatarMenu');
            }
          }
        }
      }, {
        id: 'default-pricing',
        options: {
          attachTo: {
            element: '.shepherd-step-2',
            on: 'right'
          },
          builtInButtons: [{
            classes: 'btn btn--sm btn--disabled',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--sm btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--sm btn--default',
            text: 'Next',
            type: 'next'
          }],
          classes: 'shepherd',
          text: ['Once you\'ve set your bank account, make sure to set your default pricing.', 'A "pricing" is a price-point you set for every type of spot, set specifically on each of your lots. Setting a default will ensure there is a fallback, in case any of your lots do not have pricings set up. ', 'You can also set all of your specific lot pricings here.']
        }
      }, {
        id: 'permissions',
        options: {
          attachTo: {
            element: '#shepherd-step-3',
            on: 'right'
          },
          beforeShowPromise: function beforeShowPromise() {
            return new Promise(function (resolve) {
              authController.send('toggleAvatarMenu');
              _ember['default'].run.next(function () {
                resolve();
              });
            });
          },
          builtInButtons: [{
            classes: 'btn btn--sm btn--disabled',
            text: 'Exit',
            type: 'cancel'
          }, {
            classes: 'btn btn--sm btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--sm btn--default',
            text: 'Next',
            type: 'next'
          }],
          classes: 'shepherd',
          text: ['Toggle different options with relation to ticketmaster sales, or user\'s permissions here on the permissions page.'],

          when: {
            hide: function hide() {
              if (authController.get('isAvatarMenuOpen')) {
                authController.send('toggleAvatarMenu');
              }
            }
          }
        }
      }, {
        id: 'create',
        options: {
          attachTo: {
            element: '.shepherd-step-4'
          },
          builtInButtons: [{
            classes: 'btn btn--sm btn--default',
            text: 'Back',
            type: 'back'
          }, {
            classes: 'btn btn--sm btn--default',
            text: 'Done!',
            type: 'next'
          }],
          classes: 'shepherd',
          text: ['This is the Create tab. Once you have your payment processors set up, you can begin to create lots.', 'If you have attendants, managers, or permits you\'d like to set up, you can also do so here.'],
          tetherOptions: {
            attachment: 'top left',
            targetAttachment: 'middle right'
          }
        }
      }]);
    }
  });
});