define('web/controllers/events/calendar', ['exports', 'ember', 'web/mixins/generate-occurrences'], function (exports, _ember, _webMixinsGenerateOccurrences) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  var set = _ember['default'].set;
  var get = _ember['default'].get;

  var DEFAULT_DATES = {
    dateFrom: moment().startOf('week').subtract(2, 'd').toJSON(),
    dateTo: moment().endOf('week').add(2, 'd').toJSON()
  };

  exports['default'] = _ember['default'].Controller.extend({
    showDialog: false,
    dialogEvent: null,
    dateFrom: null,
    dateTo: null,

    occurrences: computed('model.[]', {
      get: function get() {
        if (this.get('model')) {
          return (0, _webMixinsGenerateOccurrences.generateOccurrences)(this.get('model'), 'parkingFrom', 'parkingTo');
        }
        return [];
      }
    }),

    actions: {
      showDialog: function showDialog(ev) {
        var _this = this;

        var setShowEvent = function setShowEvent() {
          set(_this, 'showDialog', true);
          set(_this, 'dialogEvent', ev);
        };

        var setupClearDialog = function setupClearDialog() {
          run.next(function () {
            _ember['default'].$(document).one('click', function () {
              _this.setProperties({
                showDialog: false,
                dialogEvent: null
              });
            });
          });
        };

        if (get(this, 'showDialog')) {
          set(this, 'showDialog', false);
          run.next(function () {
            setShowEvent();
            setupClearDialog();
          });
        } else {
          setShowEvent();
          setupClearDialog();
        }
      },

      calendarNavigateWeek: function calendarNavigateWeek(dir) {
        var dateFrom = DEFAULT_DATES.dateFrom;
        var dateTo = DEFAULT_DATES.dateTo;

        var curDateFrom = this.get('dateFrom') ? this.get('dateFrom') : dateFrom;
        var curDateTo = this.get('dateTo') ? this.get('dateTo') : dateTo;

        if (dir === 1) {
          this.setProperties({
            dateFrom: moment(curDateFrom).add(1, 'week').toJSON(),
            dateTo: moment(curDateTo).add(1, 'week').toJSON()
          });
        }

        if (dir === -1) {
          this.setProperties({
            dateFrom: moment(curDateFrom).subtract(1, 'week').toJSON(),
            dateTo: moment(curDateTo).subtract(1, 'week').toJSON()
          });
        }

        if (dir === 'current') {
          this.setProperties({
            dateFrom: dateFrom,
            dateTo: dateTo
          });
        }

        this.send('invalidateModel');
      },

      calendarAddOccurrence: function calendarAddOccurrence() {
        return;
      },
      calendarUpdateOccurrence: function calendarUpdateOccurrence() {
        return;
      },
      calendarRemoveOccurrence: function calendarRemoveOccurrence() {
        return;
      }
    }
  });
});