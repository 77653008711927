define('web/utils/object-create-deep', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = emberizeData;

  var eKeys = Object.keys;
  var typeOf = _ember['default'].typeOf;

  var emberizeArray = function emberizeArray(a) {
    if (!a) {
      return a;
    }

    var newA = _ember['default'].A();

    for (var i = 0, il = a.length; i < il; ++i) {
      var item = a[i];
      if (typeOf(item) === 'object') {
        newA.push(emberizeObject(item));
      } else {
        newA.push(item);
      }
    }

    return newA;
  };

  var emberizeObject = function emberizeObject(o) {
    if (!o) {
      return o;
    }

    var data = {},
        keys = eKeys(o);
    for (var i = 0, il = keys.length; i < il; ++i) {
      var k = keys[i];
      var val = o[k];
      switch (typeOf(val)) {
        case 'array':
          data[k] = emberizeArray(val);
          break;
        case 'object':
          data[k] = emberizeObject(val);
          break;
        default:
          data[k] = val;
      }
    }

    return _ember['default'].Object.create(data);
  };

  function emberizeData(d) {
    if (typeOf(d) === 'array') {
      return emberizeArray(d);
    }

    return emberizeObject(d);
  }
});