define('web/models/event-option', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    optOut: attr('boolean'),
    priceModifier: attr('number'),
    receiptHeader: attr('string'),
    receiptFooter: attr('string'),
    phoneNumber: attr('string'),
    lotsSellable: hasMany('lot', { async: true }),

    eventId: belongsTo('event', { async: true }),
    userId: belongsTo('user', { async: true })
  });
});