define('web/components/error-messages', ['exports', 'ember'], function (exports, _ember) {
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['notification', 'notification--error', 'new'],
    classNameBindings: ['errorMessagesExist:active'],

    clearErrorMessage: 'clearErrorMessage',
    errorMessagesExist: false,

    _setErrorMessages: (function () {
      var _this = this;

      if (this.get('errorMessages')) {
        later(function () {
          _this.set('errorMessagesExist', true);
        }, 50);
      } else {
        this.set('errorMessagesExist', false);
      }
    }).observes('errorMessages'),

    actions: {
      clearErrorMessage: function clearErrorMessage() {
        this.sendAction('clearErrorMessage');
      }
    }
  });
});