define('web/mixins/records-search', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  var ControllerSearchMixin = _ember['default'].Mixin.create({
    recordSearch: null,
    results: null,
    search: null,

    _setSearch: function _setSearch(search) {
      if (search === '') {
        this.set('search', null);
      } else {
        this.set('search', search);
      }
    },

    actions: {
      changeSearch: function changeSearch(search) {
        run.debounce(this, this._setSearch, search, 750);
      }
    }
  });

  exports.ControllerSearchMixin = ControllerSearchMixin;
  var RouteSearchMixin = _ember['default'].Mixin.create({
    _resetSearchProps: (function () {
      this.controller.setProperties({
        search: null
      });
    }).on('deactivate'),

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
  exports.RouteSearchMixin = RouteSearchMixin;
});