define('web/routes/permit/details', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/mixins/record-editor'], function (exports, _ember, _webMixinsResetScroll, _webMixinsRecordEditor) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], _webMixinsRecordEditor.RouteRecordEditor, {
    afterModel: function afterModel(model) {
      model.get('lots').then(function () {
        return model.save();
      });
    },

    deactivate: function deactivate() {
      this._super();

      if (this.modelFor('permit').get('hasDirtyAttributes')) {
        this.modelFor('permit').rollbackAttributes();
      }

      this.controller.setProperties({
        recordConfirmDelete: false,
        deleteError: false,
        errorMessages: null
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.modelFor('permit').reload();
      }
    }
  });
});