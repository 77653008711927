define('web/helpers/or-dash', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports.orDash = orDash;

  var intOrFloatToString = function intOrFloat(value, round) {
    if (value % 1 === 0) {
      // then it's an int
      return value.toString();
    }

    return value.toFixed(round ? 0 : 2);
  };

  function orDash(_ref) {
    var _ref2 = _slicedToArray(_ref, 2);

    var input = _ref2[0];
    var num = _ref2[1];

    if (input === null || input === undefined) {
      var output = '-';
      if (num) {
        while (--num > 0) {
          output += '-';
        }
      }

      return output;
    }

    if (!num) {
      num = 0;
    }

    if (_ember['default'].typeOf(input) === 'number') {
      return intOrFloatToString(input).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    return input;
  }

  exports['default'] = _ember['default'].Helper.helper(orDash);
});