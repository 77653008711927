define('web/controllers/modals/testimonial', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),
    isWhitelisted: _ember['default'].computed.equal('model.list', 'whitelist'),
    isBlacklisted: _ember['default'].computed.equal('model.list', 'blacklist'),

    actions: {
      clickWhitelist: function clickWhitelist() {
        var self = this;
        if (this.get('model.list') === 'blacklist') {
          this.set('model.list', 'whitelist');
          this.store.find('testimonial', this.get('model.id')).then(function (testimonial) {
            testimonial.save();
            _ember['default'].run.later(function () {
              self.send('closeModal');
            }, 1000);
          });
        }
      },
      clickBlacklist: function clickBlacklist() {
        var self = this;
        if (this.get('model.list') === 'whitelist') {
          this.set('model.list', 'blacklist');
          this.store.find('testimonial', this.get('model.id')).then(function (testimonial) {
            testimonial.save();
            _ember['default'].run.later(function () {
              self.send('closeModal');
            }, 1000);
          });
        }
      }
    }

  });
});