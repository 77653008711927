define('web/components/ph-table/claim-lot-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['tbody__tr'],

    actions: {
      claimLot: function claimLot(cb) {
        this.attrs.claimLot(cb, this.get('lot'));
      }
    }
  });
});