define('web/controllers/create/exemption', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordCreate.ControllerRecordCreate, {
    searches: ['lot'],

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      lotRequest: function lotRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'lot');
      },

      lotFocused: function lotFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'lot', {});
        }
      }
    }
  });
});