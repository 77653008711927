define('web/controllers/modals/confirmation', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),

    actions: {
      submitRequest: function submitRequest() {
        this.get('submitRequest')();
        this.get('application').send('closeModal');
      },

      cancelRequest: function cancelRequest() {
        this.get('cancelRequest')();
        this.get('application').send('closeModal');
      }
    }
  });
});