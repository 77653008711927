define('web/adapters/event', ['exports', 'web/adapters/application'], function (exports, _webAdaptersApplication) {
  exports['default'] = _webAdaptersApplication['default'].extend({
    handleResponse: function handleResponse(status, headers, payload) {
      switch (status) {
        case 409:
          payload.message = 'Conflict error: An event with this name already exists.';
          return payload;
        default:
          return payload;
      }

      return this._super(status, headers, payload);
    }
  });
});