define("web/helpers/print-to-zebra", ["exports", "ember", "web/classes/Zebra"], function (exports, _ember, _webClassesZebra) {
  exports.printReportToZebra = printReportToZebra;

  function printReportToZebra(_ref) {
    var name = _ref.name;
    var stats = _ref.stats;
    var reprints = _ref.reprints;

    var formatter = function formatter(type) {
      var filterArr = stats.filter(function (x) {
        return x.transactionType === type;
      });
      return {
        count: filterArr.length,
        total: filterArr.length > 0 ? "$" + filterArr.map(function (x) {
          return parseInt(x.price.slice(1));
        }).reduce(function (x, y) {
          return x + y;
        }) : "0"
      };
    };

    var cash = stats.cash;
    var credit = stats.credit;
    var prepaid = stats.prepaid;
    var exempt = stats.exempt;
    var totalTrans = cash.totalTransactions + prepaid.totalTransactions + credit.totalTransactions + exempt.totalTransactions;

    function sendToPrinter() {
      var link = "Arrowhead://x-callback-url/cpclcode?code=" + (0, _webClassesZebra.getDocumentBase64Encoded)();
      window.location = link;
    }

    function transformToPrice(number) {
      return "$" + (parseInt(number) / 100).toFixed(2);
    }

    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addBigHeadingTall)("" + name);
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addContent)("" + moment().format("MMMM Do YYYY, h:mm:ss a"));
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addSmallHeading)("Cash - " + cash.totalTransactions + " - " + transformToPrice(cash.totalAmount));
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addSmallHeading)("Credit - " + credit.totalTransactions + " - " + transformToPrice(credit.totalAmount));
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addSmallHeading)("Prepaid - " + prepaid.totalTransactions + " - " + transformToPrice(prepaid.totalAmount));
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addSmallHeading)("Exempt - " + exempt.totalTransactions + " - " + transformToPrice(exempt.totalAmount));
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addBigHeading)("Reprints - " + reprints);
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addBigHeading)("Total Trans - " + totalTrans);
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.addWhiteSpace)();
    (0, _webClassesZebra.getDocument)(); //Returns the text for the document
    (0, _webClassesZebra.getDocumentBase64Encoded)(); //Returns the text encoded in Base64
    sendToPrinter();
    (0, _webClassesZebra.clearDecks)();
  }

  exports["default"] = _ember["default"].Helper.helper(printReportToZebra);
});