define('web/routes/user/operator-cuts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var model = this.modelFor('user');
      if (model.get('role') === 'operator') {
        return this.store.query('operator-cut', { operator_id: model.get('id') }).then(function (resp) {
          var cut = resp.get('firstObject') || _this.store.createRecord('operator-cut', {});
          model.set('operatorCut', cut);
          return model;
        });
      }
      return model;
    },

    deactivate: function deactivate() {
      this._super();
      if (this.controller.get('model.operatorCut.hasDirtyAttributes')) {
        this.controller.get('model.operatorCut').rollbackAttributes();
      }

      this.controller.get('model.operatorCut').unloadRecord();
    }
  });
});