define('web/mixins/pagination', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;

  var NUM_PAGE_LINKS = 10;

  var ControllerMixin = _ember['default'].Mixin.create({
    queryParams: ['limit', 'offset'],
    limit: 20,
    offset: 0,
    total: alias('model.meta.pagination.total'),

    routeName: (function () {
      if (this.get('model')) {
        return this.get('model').type.toString().slice(10, -1) + 's';
      }
    }).property('model.type'),

    hasPages: (function () {
      if (this.get('total') > this.get('limit')) {
        return true;
      }
    }).property('total', 'limit'),

    isFirst: (function () {
      return this.get('offset') === 0;
    }).property('offset'),

    isLast: (function () {
      return this.get('offset') >= this.get('total') - this.get('limit');
    }).property('offset', 'total', 'limit'),

    firstLink: (function () {
      return this.get('offset') - this.get('offset');
    }).property('offset', 'limit'),

    prevLink: (function () {
      return this.get('offset') - this.get('limit');
    }).property('offset', 'limit'),

    nextLink: (function () {
      return this.get('offset') + this.get('limit');
    }).property('offset', 'limit'),

    lastLink: (function () {
      return this.get('total') - this.get('total') % this.get('limit');
    }).property('total', 'limit'),

    pageLinks: (function () {
      var _getProperties = this.getProperties('offset', 'limit', 'total');

      var offset = _getProperties.offset;
      var limit = _getProperties.limit;
      var total = _getProperties.total;

      var lastOffset = offset + NUM_PAGE_LINKS * limit;
      var offsets = [];
      offset += limit;
      var i = Math.floor(offset / limit);

      while (offset <= lastOffset && offset <= total) {
        offsets.push({
          page: i++,
          offset: offset
        });

        offset += limit;
      }

      return offsets;
    }).property('total', 'offset', 'limit')
  });

  exports.ControllerMixin = ControllerMixin;
  var RouteMixin = _ember['default'].Mixin.create({
    queryParams: {
      offset: {
        refreshModel: true
      }
    }
  });
  exports.RouteMixin = RouteMixin;
});