define('web/adapters/lot', ['exports', 'web/adapters/application', 'ember'], function (exports, _webAdaptersApplication, _ember) {
  exports['default'] = _webAdaptersApplication['default'].extend({

    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this._super.apply(this, arguments);
      var query = _ember['default'].get(snapshot, 'adapterOptions.query');
      if (query) {
        url += '?' + _ember['default'].$.param(query); // assumes no query params are present already
      }
      return url;
    },

    updateRecord: function updateRecord(store, type, snapshot) {
      var serializer = store.serializerFor(type.modelName);
      var data = serializer.serialize(snapshot);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      delete data.sellable;

      return this.ajax(url, "PUT", {
        data: { lot: data },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var serializer = store.serializerFor(type.modelName);

      var data = serializer.serialize(snapshot);

      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      delete data.sellable;

      return this.ajax(url, "POST", {
        data: { lot: data },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    handleResponse: function handleResponse(status, headers, payload) {
      var possibleErrors = [400, 401, 403, 404, 409, 500];
      if (possibleErrors.contains(status)) {
        if (status === 400 && payload.message.includes('paymentProcessorId') && payload.message.includes('child') && payload.message.includes('must be a string')) {
          payload.message = 'Merchant Account is required. Please select a merchant account to use with this lot.';
        }
        return payload;
      }
      return this._super.apply(this, arguments);
    }

  });
});