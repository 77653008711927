define('web/routes/error', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      if (model) {
        if (model.statusCode === 401 && this.get('session.isAuthenticated')) {
          controller.set('isAuthenticationError', true);
          return this.get('session').close('parkhub');
        }
      }
    },

    deactivate: function deactivate() {
      this.controller.set('isAuthenticationError', false);
    }
  });
});