define('web/components/value-set-input', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isNone = _ember['default'].isNone;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['type-ahead'],

    // public
    selectedItems: null,

    // private
    enteredVal: '',

    displayItems: computed('selectedItems', 'selectedItems.[]', 'selectedItems.content', function () {
      var selectedItems = this.get('selectedItems');
      if (_ember['default'].isArray(selectedItems)) {
        this.set('isSingle', false);
        return selectedItems;
      }

      this.set('isSingle', true);

      if (_ember['default'].isNone(selectedItems)) {
        return [];
      }

      return [selectedItems];
    }),

    canAdd: computed('displayItems.[]', 'max', function () {
      var max = this.get('max');
      return _ember['default'].isBlank(max) || max <= 0 || this.get('displayItems.length') < max;
    }),

    keyDown: function keyDown(e) {
      var keyCode = e.keyCode;
      var ENTER = 13;
      var value = this.get('enteredVal');

      if (keyCode === ENTER) {
        e.preventDefault();
        if (value) {
          this.send('saveStaged');
        }
        return false;
      }
    },

    focusIn: function focusIn() {
      if (this.attrs.focused) {
        this.attrs.focused(this.get('selectedItems'));
      }
    },

    _clear: function _clear() {
      this.setProperties({
        enteredVal: ''
      });
    },

    actions: {
      changeValue: function changeValue(val) {
        this.set('enteredVal', val);
      },

      saveStaged: function saveStaged() {
        var _this = this;

        var item = this.get('enteredVal');
        this._clear();

        var addItem = function addItem(itemToAdd) {

          if (_this.get('isSingle')) {
            _this.set('selectedItems', itemToAdd);
          } else {
            var selectedItems = _this.get('selectedItems');
            if (!selectedItems.contains(itemToAdd)) {
              selectedItems.pushObject(itemToAdd);
            }
          }

          _this.notifyPropertyChange('displayItems');
        };

        if (_ember['default'].isPresent(item)) {
          if (this.attrs.saveNewValueToSet) {
            this.attrs.saveNewValueToSet(item, addItem);
          } else {
            addItem(item);
          }
        }
      },

      removeSelected: function removeSelected(selectedItem) {
        var _this2 = this;

        var onRemoved = function onRemoved(removeItem) {
          if (!isNone(removeItem)) {
            if (_this2.get('isSingle')) {
              _this2.set('selectedItems', null);
            } else {
              _this2.get('selectedItems').removeObject(removeItem);
            }
          }
        };

        if (this.attrs.removeValueFromSet) {
          this.attrs.removeValueFromSet(selectedItem, onRemoved);
        } else {
          onRemoved(selectedItem);
        }
      }
    }
  });
});