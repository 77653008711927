define('web/controllers/landmarks', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, _webMixinsRecordsSearch.ControllerSearchMixin, {
    queryParams: ['limit', 'offset', 'search'],
    recordName: 'landmark',

    lmCols: [{ colName: 'Name' }, { colName: 'Region' }, {}],

    actions: {
      landmarkTransition: function landmarkTransition(lmId) {
        this.transitionToRoute('landmark', lmId);
      }
    }
  });
});