define('web/routes/user-options', ['exports', 'ember'], function (exports, _ember) {
  var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var promises = [this.store.findAll('user-option'), this.store.findAll('external-validation-type')];

      return _ember['default'].RSVP.all(promises).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2);

        var userOptions = _ref2[0];
        var externalValidationTypes = _ref2[1];

        if (userOptions.get('firstObject').get('lotNameSource') === 'ph_lot_name') {
          _this.set('phLotNameSource', true);
        } else if (userOptions.get('firstObject').get('lotNameSource') === 'external_section_name') {
          _this.set('phLotNameSource', false);
        } else {
          _this.set('phLotNameSource', true);
        }

        return {
          phLotNameSource: _this.get('phLotNameSource'),
          userOptions: userOptions.get('firstObject'),
          externalValidationTypes: externalValidationTypes
        };
      })['catch'](function (err) {
        if (err.statusCode === 404) {
          var userOption = _this.store.createRecord('user-option');

          return userOption.save().then(function (resp) {
            return {
              userOptions: resp,
              externalValidationTypes: _ember['default'].A([])
            };
          });
        }
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});