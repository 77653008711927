define('web/components/inventory-slider', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;

  function calcPercent(sold, total) {
    var remainingPercent = sold / total * 100;
    return remainingPercent || 0;
  }

  var computedSum = function computedSum(props) {
    return computed(props + '.[]', function () {
      var _this = this;

      return props.reduce(function (h, p) {
        return h + _this.get(p);
      }, 0);
    });
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['inventory'],
    classNameBindings: ['oversell:inventory__oversell'],

    willDestroyElement: function willDestroyElement() {
      _ember['default'].set(this, 'percentSold', {});
    },

    spotsSold: computedSum(['cashCounts', 'creditCounts', 'prepaidCounts', 'exemptCounts']),

    percentSold: computed('spotsSold', 'record.totalSpots', function () {
      var totalSold = this.get('spotsSold');
      var totalSpots = this.get('record.totalSpots');
      var oversold = totalSold > totalSpots;
      var percentArray = [calcPercent(this.get('cashCounts'), oversold ? totalSold : totalSpots), calcPercent(this.get('creditCounts'), oversold ? totalSold : totalSpots), calcPercent(this.get('prepaidCounts'), oversold ? totalSold : totalSpots), calcPercent(this.get('exemptCounts'), oversold ? totalSold : totalSpots)];

      return {
        cashPercent: percentArray[0],
        creditPercent: percentArray[1],
        prepaidPercent: percentArray[2],
        exemptPercent: percentArray[3]
      };
    }),

    flexes: [],
    currentFlexes: computed.uniq('flexes'),

    oversell: computed('spotsSold', 'record.totalSpots', function () {
      var oversold = this.get('spotsSold') > this.get('record.totalSpots');
      this.attrs.setOversell(oversold);
      return oversold;
    }),

    remainingFlex: computed('spotsSold', 'record.totalSpots', function () {
      var flex = 100 - this.get('spotsSold') / this.get('record.totalSpots') * 100;
      return flex > 0 ? flex : 0;
    })

  });
});