define('web/initializers/current-user', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'currentUser', 'service:current-user');
    application.inject('route', 'currentUser', 'service:current-user');
    application.inject('component', 'currentUser', 'service:current-user');
  }

  exports['default'] = {
    name: 'currentUser',
    initialize: initialize
  };
});