define('web/serializers/lot', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    currentUser: _ember['default'].inject.service(),
    isNewSerializerAPI: true,

    attrs: {
      images: { serialize: false, deserialize: 'records' },
      claimed: { serialize: false },
      createdAt: { serialize: false },
      updatedAt: { serialize: false },
      pricingIds: { serialize: false },
      reservations: { deserialize: 'records', serialize: false },
      events: { deserialize: 'records', serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var t = this._super(snapshot, options);
      var allowNull = ['operator', 'regionId', 'paymentProcessorId', 'ticketmasterLineNumber'];

      if (snapshot.record.get('isNew')) {
        allowNull = [];
        _ember['default'].changeProperties(function () {
          _ember['default'].set(t, 'assignAllAttendants', undefined);
          _ember['default'].set(t, 'assignments', undefined);
        });
      }

      if (this.get('currentUser.isAdmin')) {
        _ember['default'].changeProperties(function () {
          _ember['default'].set(t, 'assignAllAttendants', undefined);
          _ember['default'].set(t, 'assignments', undefined);
          _ember['default'].set(t, 'paymentProcessorId', undefined);
        });
      }

      if (this.get('currentUser.isOperator') || this.get('currentUser.isSubOperator')) {
        _ember['default'].changeProperties(function () {
          _ember['default'].set(t, 'ticketmasterLineNumber', undefined);
        });
      }

      if (_ember['default'].get(t, 'ticketmasterLineNumber') === '') {
        _ember['default'].set(t, 'ticketmasterLineNumber', null);
      }

      if (_ember['default'].get(t, 'assignments') && _ember['default'].get(t, 'assignments.firstObject') instanceof Object) {
        var assignmentIds = _ember['default'].get(t, 'assignments').map(function (user) {
          return _ember['default'].get(user, 'id');
        });
        _ember['default'].set(t, 'assignments', assignmentIds);
      }

      Object.keys(t).forEach(function (k) {
        if (t[k] === null && !allowNull.contains(k)) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});