define('web/controllers/external-transactions/index', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    associateExternalEvent: _ember['default'].inject.controller('modals.associate-external-event'),
    queryParams: ['getOrphaned', 'getAdopted', 'limit', 'offset'],
    isLoading: false,
    getOrphaned: true,
    getAdopted: null,

    eventCols: [{ colName: 'Assigned' }, { colName: 'External Event Name' }, { colName: 'Date / Time' }, { colName: 'Count' }, {}],

    orphaned: computed('getOrphaned', 'getAdopted', {
      get: function get() {
        return this.get('getOrphaned') || false;
      }
    }),

    groupedExternalEvents: computed('model', {
      get: function get() {
        var groupedEvents = _ember['default'].A([]);

        this.get('model').forEach(function (externalEvent) {
          var eventIdentifier = externalEvent.get('eventNameLong') || externalEvent.get('eventIdentifier');
          var id = eventIdentifier + '-' + externalEvent.get('eventTimestamp');
          var foundEvent = groupedEvents.findBy('id', id);
          var hasEventIdentifier = eventIdentifier;

          if (foundEvent) {
            foundEvent.get('events').push(externalEvent);

            var runningEventCount = parseInt(foundEvent.get('count'));
            var transactionCount = parseInt(externalEvent.get('externalTransactionsCount'));

            foundEvent.set('count', runningEventCount + transactionCount);
          } else if (hasEventIdentifier) {
            groupedEvents.pushObject(_ember['default'].Object.create({
              id: id,
              name: eventIdentifier,
              timestamp: externalEvent.get('eventTimestamp'),
              count: externalEvent.get('externalTransactionsCount'),
              events: _ember['default'].A([externalEvent])
            }));
          }
        });

        return groupedEvents;
      }
    }),

    actions: {
      toggleOrphanedProp: function toggleOrphanedProp() {
        if (this.get('getOrphaned')) {
          this.setProperties({
            getOrphaned: null,
            getAdopted: true
          });
        } else {
          this.setProperties({
            getAdopted: null,
            getOrphaned: true
          });
        }
      },

      associateExternalEvent: function associateExternalEvent(model) {
        this.get('associateExternalEvent').setProperties({
          model: model
        });

        this.send('openModal', 'associate-external-event');
      }
    }
  });
});