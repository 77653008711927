define('web/routes/create/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      if (this.get('currentUser.role') === 'admin') {
        this.transitionTo('create.event');
      } else {
        this.transitionTo('create.lot');
      }
    }
  });
});