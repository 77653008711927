define('web/controllers/authenticated-apps/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    authAppsCols: [{ colName: 'ID' }, { colName: 'Name' }, { colName: 'Updated At' }, {}],

    actions: {
      appTransition: function appTransition(appId) {
        this.transitionToRoute('authenticated-apps.edit', appId);
      }
    }
  });
});