define('web/models/landmark', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;

  var Landmark = _emberData['default'].Model.extend({
    name: attr('string'),
    slug: attr('string'),
    location: attr(),
    address1: attr('string'),
    address2: attr('string'),
    city: attr('string'),
    state: attr('string'),
    zip: attr('string'),
    events: hasMany('event', { async: true }),
    lots: hasMany('lot', { async: true }),
    region: belongsTo('region', { async: true }),
    live: attr('boolean'),
    images: hasMany('image', { async: true }),
    directions: attr('string'),
    information: attr('string'),
    externalValidations: hasMany('external-validation', { async: true }),
    timezoneId: attr('string'),
    taxId: attr('string'),
    trimId: (function () {
      var id = this.get('id');
      return id.slice(-4);
    }).property('id')
  });

  exports['default'] = Landmark;
});