define('web/controllers/ownerships', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more', 'web/config/environment', 'fetch'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore, _webConfigEnvironment, _fetch) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    claimLot: _ember['default'].inject.controller('modals.claim-lot'),
    recordName: 'lot',
    lotClaimed: false,

    queryParams: ['limit', 'offset', 'getUnclaimed', 'getAll', 'search'],
    limit: 20,
    offset: 0,
    getAll: true,
    modelName: 'model.lots',

    lotCols: [{ colName: 'Lot Name' }, { colName: 'Lot Address' }, { colName: 'Lot Region' }, {}],

    getUnclaimed: true,

    actions: {
      claimLot: function claimLot(cb, lot) {
        var _this = this;

        var ownership = _ember['default'].Object.create({
          ownership: {
            lotId: lot.get('id')
          }
        });

        var promise = (0, _fetch['default'])(_webConfigEnvironment['default'].apiUrl + '/api/v2/ownerships', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include',
          body: JSON.stringify(ownership)
        });

        cb(promise);

        promise.then(function (status) {
          if (status.status >= 200 && status.status < 300) {
            return status;
          } else {
            throw status;
          }
        }).then(function () {
          _this.get('claimLot').setProperties({
            model: lot
          });
          _this.send('openModal', 'claim-lot');
        })['catch'](function () {
          return _this.flashMessages.danger('You have already claimed this lot.');
        })['finally'](function () {
          return _this.send('invalidateModel');
        });
      }
    }
  });
});