define('web/components/map-box-circle', ['exports', 'web/config/environment', 'ember', 'web/mixins/has-map-parent'], function (exports, _webConfigEnvironment, _ember, _webMixinsHasMapParent) {

  var L = window.L; // TODO: would be nice to import this

  var _get = _ember['default'].get;
  var isBlank = _ember['default'].isBlank;
  var observer = _ember['default'].observer;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend(_webMixinsHasMapParent['default'], {
    tagName: '',

    coordinates: null,
    radius: null,
    options: computed('circleOptions', {
      get: function get() {
        return this.get('circleOptions') || _get(_webConfigEnvironment['default'], 'mapbox.circleOptions') || null;
      }
    }),

    _curCircle: null,

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var contentLayer = this.get('contentLayer');
      if (contentLayer) {
        var curCircle = this.get('_curCircle');
        if (curCircle) {
          contentLayer.removeLayer(curCircle);
          this.set('_curCircle', null);
        }
      }
    },

    _observeCoordinates: observer('contentLayer', 'coordinates', 'radius', 'options', function () {
      _ember['default'].run.once(this, this._updateCoordinates);
    }).on('init'),

    _updateCoordinates: function _updateCoordinates() {
      var contentLayer = this.get('contentLayer');
      if (!contentLayer) {
        return;
      }

      var curCircle = this.get('_curCircle');
      if (curCircle) {
        contentLayer.removeLayer(curCircle);
        this.set('_curCircle', null);
      }

      var coordinates = this.get('coordinates');
      var radius = this.get('radius');
      if (isBlank(coordinates) || isBlank(radius) || radius <= 0) {
        return;
      }

      var circle = L.circle(coordinates, radius, this.get('options'));

      circle.addTo(contentLayer);

      this.set('_curCircle', circle);

      this.get('map').contentChanged();
    }
  });
});