define('web/controllers/modals/ph-tour', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    phTour: _ember['default'].inject.service('ph-tour'),
    isModalOpen: computed.alias('application.isModalOpen'),

    actions: {
      startTour: function startTour() {
        this.send('closeModal');
        this.get('phTour').trigger('start');
      }
    }
  });
});