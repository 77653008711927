define('web/controllers/modals/unsaved-changes', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    isModalOpen: computed.alias('application.isModalOpen'),
    isAbandoned: false,

    actions: {
      retryTransition: function retryTransition() {
        this.set('isAbandoned', true);

        var transition = this.get('transition');
        if (transition) {
          this.set('transition', null);
          transition.retry();
        }
        this.send('closeModal');
      },

      keepWorking: function keepWorking() {
        this.send('closeModal');
      }
    }
  });
});