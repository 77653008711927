define("web/templates/search", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 0
          }
        },
        "moduleName": "web/templates/search.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "search-bar", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 8], [2, 13]]]]], [], []], "searchChanged", ["subexpr", "action", ["searchChanged"], [], ["loc", [null, [3, 16], [3, 40]]]], "searchVal", ["subexpr", "@mut", [["get", "searchVal", ["loc", [null, [4, 12], [4, 21]]]]], [], []]], ["loc", [null, [1, 0], [4, 23]]]], ["inline", "search-results", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [12, 8], [12, 13]]]]], [], []], "limit", ["subexpr", "@mut", [["get", "limit", ["loc", [null, [13, 8], [13, 13]]]]], [], []], "offset", ["subexpr", "@mut", [["get", "offset", ["loc", [null, [14, 9], [14, 15]]]]], [], []], "type", ["subexpr", "@mut", [["get", "type", ["loc", [null, [15, 7], [15, 11]]]]], [], []], "availableFilters", ["subexpr", "@mut", [["get", "availableFilters", ["loc", [null, [16, 19], [16, 35]]]]], [], []], "isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [17, 12], [17, 21]]]]], [], []]], ["loc", [null, [11, 0], [17, 24]]]]],
      locals: [],
      templates: []
    };
  })());
});