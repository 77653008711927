define('web/routes/users', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/build-params'], function (exports, _ember, _webMixinsResetScroll, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordsSearch.RouteSearchMixin, _webMixinsResetScroll['default'], _webMixinsLoadingModel.RouteLoadingMixin, _webMixinsLoadMore.RouteLoadMore, {
    queryParams: {
      role: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel(model, transition) {
      this._super(model, transition);
    },

    model: function model(params) {
      var _this = this;

      if (params.search) {
        params['getAll'] = true;
      }

      return this.store.query('user', (0, _webMixinsBuildParams.filterParams)(params))['catch'](function (err) {
        _this.flashMessages.danger(err.message);
      });
    },

    afterModel: function afterModel(model, transition) {
      this._super(model, transition);
    },

    deactivate: function deactivate() {
      this._super();
      this.set('role', null);
    }
  });
});