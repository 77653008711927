define('web/models/permit', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    internalId: attr('string'),
    barcode: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    email: attr('string'),
    phone: attr('string'),
    type: attr('string'),
    affiliation: attr('string'),
    classification: attr('string'),
    classificationSub: attr('string'),
    active: attr('boolean'),
    validFrom: attr('string'),
    validTo: attr('string'),
    // notifications: attr('object'),

    lots: hasMany('lot', { async: true }),
    operatorId: belongsTo('user', { async: true }),

    name: computed('firstName', 'lastName', function () {
      return this.get('firstName') + ' ' + (this.get('lastName') || '');
    })
  });
});