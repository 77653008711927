define('web/mixins/image-uploader-controller', ['exports', 'ember', 'ic-ajax'], function (exports, _ember, _icAjax) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Mixin.create({
    uploadFailed: false,
    isLoading: false,
    finishedLoading: false,

    actions: {
      uploadAfterComplete: function uploadAfterComplete() {
        var model = this.get('model');
        model.reload();
      },

      uploadFailed: function uploadFailed() {
        this.set('uploadFailed', true);
        this.flashMessages.danger('The image you selected is too large.');
      },

      selectImage: function selectImage(image) {
        var _this = this;

        this.set('isLoading', true);

        var type = this.get('model.constructor.modelName');

        var newImg = this.store.createRecord('image', {
          imageData: image,
          recordId: this.get('model.id'),
          modelName: type,
          imageType: image.type.slice(6)
        });

        if (_ember['default'].isPresent(image)) {
          newImg.save().then(function (resp) {
            _this.set('isLoading', false);
            _this.set('finishedLoading', true);

            run.later(function () {
              _this.send('uploadAfterComplete');
              _this.set('finishedLoading', false);
              _ember['default'].run(_this, _this.set, 'bgImage', 'background-image: none'.htmlSafe());
            }, 1000);
          }, function (err) {
            _this.set('isLoading', false);
            _this.send('uploadFailed', err);
            newImg.unloadRecord();
          });
        }
      },

      selectIconLogo: function selectIconLogo(image) {
        var _this2 = this;

        this.set('isLoading', true);

        var model = this.get('model');
        var recData = this.get('dataKeyOnModel');

        model.set(recData, image);

        model.save().then(function (resp) {
          _this2.set('isLoading', false);
          _this2.set('finishedLoading', true);

          run.later(function () {
            _this2.send('uploadAfterComplete');
            _this2.set('finishedLoading', false);
            _ember['default'].run(_this2, _this2.set, 'bgImage', 'background-image: none'.htmlSafe());
          }, 1000);
        }, function (err) {
          _this2.set('isLoading', false);
          _this2.send('uploadFailed', err);
        });
      },

      imageDelete: function imageDelete(image) {
        var _this3 = this;

        image.deleteRecord();
        image.save().then(function () {
          return _this3.flashMessages.success('Image deleted successfully.');
        })['catch'](function () {
          return _this3.flashMessages.danger('Unable to delete image.');
        });
      },

      iconLogoDelete: function iconLogoDelete() {
        var _this4 = this;

        var key = this.get('imgKeyOnModel');
        var model = this.get('model');

        var modelName = this.get('model.constructor.modelName');
        var obj = {};
        var recordObj = {};
        var updatedRecord = undefined;

        recordObj[key] = null;
        obj[modelName] = recordObj;
        updatedRecord = _ember['default'].Object.create(obj);

        if (modelName === 'amenity') {
          modelName = 'amenitie';
        }

        (0, _icAjax['default'])({
          method: 'PUT',
          url: '/api/v2/' + modelName + 's/' + model.get('id'),
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(updatedRecord),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function () {
          _this4.flashMessages.success('Image deleted successfully.');
          run.later(function () {
            window.location.reload(true);
          }, 1500);
        })['catch'](function () {
          return _this4.flashMessages.danger('Unable to delete image.');
        });
      }
    }
  });
});