define('web/controllers/regions', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    recordName: 'region',
    regionCols: [{ colName: 'Name' }, { colName: 'Zip' }, {}],

    actions: {
      regionTransition: function regionTransition(regionId) {
        this.transitionToRoute('region', regionId);
      }
    }
  });
});