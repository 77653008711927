define("web/templates/components/value-set-input", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "web/templates/components/value-set-input.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "type-ahead__selected__item");
          dom.setAttribute(el1, "role", "menuitem");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn--sm btn--transparent");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var element4 = dom.childAt(element3, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element3, 1, 1);
          morphs[1] = dom.createElementMorph(element4);
          morphs[2] = dom.createMorphAt(element4, 1, 1);
          return morphs;
        },
        statements: [["content", "item", ["loc", [null, [6, 8], [6, 16]]]], ["element", "action", ["removeSelected", ["get", "item", ["loc", [null, [7, 79], [7, 83]]]]], [], ["loc", [null, [7, 53], [7, 85]]]], ["inline", "inline-svg", ["/assets/images/cross"], ["class", "icon-remove"], ["loc", [null, [8, 10], [8, 67]]]]],
        locals: ["item"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 4
            },
            "end": {
              "line": 25,
              "column": 4
            }
          },
          "moduleName": "web/templates/components/value-set-input.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          dom.setAttribute(el1, "class", "type-ahead__input");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("input");
          dom.setAttribute(el2, "class", "input");
          dom.setAttribute(el2, "type", "text");
          dom.setAttribute(el2, "autocomplete", "off");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "class", "btn btn--sm btn--transparent");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var morphs = new Array(5);
          morphs[0] = dom.createAttrMorph(element1, 'placeholder');
          morphs[1] = dom.createAttrMorph(element1, 'value');
          morphs[2] = dom.createAttrMorph(element1, 'oninput');
          morphs[3] = dom.createElementMorph(element2);
          morphs[4] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["attribute", "placeholder", ["get", "placeholder", ["loc", [null, [17, 24], [17, 35]]]]], ["attribute", "value", ["get", "enteredVal", ["loc", [null, [18, 18], [18, 28]]]]], ["attribute", "oninput", ["subexpr", "action", ["changeValue"], ["value", "target.value"], ["loc", [null, [19, 18], [19, 63]]]]], ["element", "action", ["saveStaged"], [], ["loc", [null, [20, 53], [20, 76]]]], ["inline", "inline-svg", ["/assets/images/admin-icons/add-create"], ["class", "icon-add"], ["loc", [null, [21, 10], [21, 81]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/value-set-input.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "type-ahead__input-wrapper");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "type-ahead__selected__list");
        dom.setAttribute(el2, "role", "menu");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element5, 1, 1);
        morphs[1] = dom.createMorphAt(element5, 2, 2);
        return morphs;
      },
      statements: [["block", "each", [["get", "displayItems", ["loc", [null, [4, 12], [4, 24]]]]], [], 0, null, ["loc", [null, [4, 4], [11, 13]]]], ["block", "if", [["get", "canAdd", ["loc", [null, [12, 10], [12, 16]]]]], [], 1, null, ["loc", [null, [12, 4], [25, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});