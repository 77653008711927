define('web/controllers/event/details', ['exports', 'ember', 'ic-ajax', 'web/mixins/record-editor', 'web/mixins/time-options', 'web/mixins/image-uploader-controller', 'web/config/environment'], function (exports, _ember, _icAjax, _webMixinsRecordEditor, _webMixinsTimeOptions, _webMixinsImageUploaderController, _webConfigEnvironment) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], _webMixinsImageUploaderController['default'], _webMixinsTimeOptions['default'], {
    application: _ember['default'].inject.controller(),
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    todaysDate: moment(),

    upcomingEventsText: _webConfigEnvironment['default'].text.upcomingEventsText,
    paymentTypeText: _webConfigEnvironment['default'].text.paymentTypeText,

    maxDate: moment().add(2, 'y'),
    deleteIsHidden: true,
    monitoredMaxDate: computed('currentUser.role', 'timeFrom', {
      get: function get() {
        if (this.get('currentUser.role') === 'admin') {
          return moment().add(2, 'y');
        }
        // timefrom plus 3 hours
        return moment(this.get('timeFrom')).add(3, 'h');
      }
    }),

    /* determine available metatags to show in front end. */
    availableMetatagLabels: computed('model.metatags', {
      get: function get() {
        /* get metatag objects from event.js route */
        var metatagObject = this.get('model.metatags');

        /* If there's no associated metatag upon viewing an event, warn the user.
         * This could be right after the record is created */
        if (this.get('model.selectedMetatag') === undefined) {
          this.flashMessages.danger('The event type failed to create for this event. Please contact Parkhub Support.');
        }

        /* ensure metatag service is running */
        if (metatagObject.children.length === 0) {
          return [];
        }
        /* get an array of labels from the objects */
        var metatagLabels = metatagObject.children.map(function (response) {
          return response.displayName;
        });
        return metatagLabels;
      }
    }),
    recordId: computed.alias('model.event.id'),
    recordName: 'event',
    recordModel: 'event',
    deleteTransitionTo: 'events',
    timeFrom: computed('model.event.from', {
      get: function get() {
        return moment(this.get('model.event.from')).toDate();
      },
      set: function set(key, newVal) {
        return newVal;
      }
    }),

    minTimeFrom: computed('model.event.from', {
      get: function get() {
        return moment(this.get('model.event.from')).isSame(moment(), 'day') ? moment() : false;
      }
    }),
    minTimeTo: computed('timeFRom', 'timeTo', {
      get: function get() {
        if (moment(this.get('timeFrom')).isSame(moment(this.get('timeTo')), 'day')) {
          return moment(this.get('timeFrom')).add(1, 'h');
        } else {
          return false;
        }
      }
    }),
    timeTo: computed('model.event.to', {
      get: function get() {
        return moment(this.get('model.event.to')).toDate();
      },
      set: function set(key, newVal) {
        return newVal;
      }
    }),

    parkingTimeFrom: computed('model.event.parkingFrom', {
      get: function get() {
        return moment(this.get('model.event.parkingFrom')).toDate();
      },
      set: function set(key, newVal) {
        return newVal;
      }
    }),

    parkingTimeTo: computed('model.event.parkingTo', {
      get: function get() {
        return moment(this.get('model.event.parkingTo')).toDate();
      },
      set: function set(key, newVal) {
        return newVal;
      }
    }),

    flexDate: computed('model.event.flexDate', {
      get: function get() {
        return this.get('model.event.flexDate');
      },
      set: function set(key, newVal) {
        this.set('model.event.flexDate', newVal);
        return newVal;
      }
    }),

    transient: computed('model.event.transient', {
      get: function get() {
        return this.get('model.event.transient');
      },
      set: function set(key, newVal) {
        this.set('model.event.transient', newVal);
        return newVal;
      }
    }),

    flexDateText: computed('model.event.flexDateText', {
      get: function get() {
        return this.get('model.event.flexDateText');
      },
      set: function set(key, newVal) {
        this.set('model.event.flexDateText', newVal);
        return newVal;
      }
    }),

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions = undefined;

      var params = { search: q, limit: l };

      if (type === 'landmark') {
        params.dBSearch = true;
      }

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      updateRecord: function updateRecord() {
        var _this = this;

        if (this.get('model.additionalChargesEnabled')) {
          this.get('application').send('showLoader');

          var payload = {
            operatorLegacyId: this.get('currentUser.id'),
            eventId: this.get('model.event.id'),
            transactionTypes: []
          };

          if (this.get("model.transactionTypes.cash")) {
            payload.transactionTypes.push('cash');
          }

          if (this.get("model.transactionTypes.credit")) {
            payload.transactionTypes.push('credit');
          }

          if (this.get("model.transactionTypes.payment")) {
            payload.transactionTypes.push('payment');
          }

          (0, _icAjax['default'])({
            method: 'PUT',
            url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/event',
            contentType: 'application/json',
            dataType: 'json',
            data: JSON.stringify(payload),
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            }
          }).then(function () {
            _this.get('application').send('hideLoader');

            return true;
          })['catch'](function () {
            _this.get('application').send('hideLoader');

            return false;
          });

          return true;
        }

        return true;
      },

      changeEventName: function changeEventName(val) {
        this.set('model.event.name', val);
      },
      changeFlexDate: function changeFlexDate() {
        this.toggleProperty('model.event.flexDate');
      },

      changeTransientEvent: function changeTransientEvent() {
        this.toggleProperty('model.event.transient');
      },

      changeTimeFrom: function changeTimeFrom(date) {
        if (_ember['default'].isPresent(date)) {
          var from = new Date(date);
          var parkingFrom = moment(new Date(date)).subtract(2, 'h');
          var timeTo = moment(new Date(date)).add(3, 'h');

          if (moment(date).isSame(moment(), 'day')) {
            this.set('minTime', moment());
          } else {
            this.set('minTime', false);
          }

          this.set('timeFrom', date);
          this.set('model.event.from', from.toJSON());
          this.set('parkingTimeFrom', parkingFrom.toDate());
          this.set('model.event.parkingFrom', parkingFrom.toJSON());

          this.set('timeTo', timeTo.toDate());
          this.set('model.event.to', timeTo.toJSON());
          this.set('parkingTimeTo', timeTo.toDate());
          this.set('model.event.parkingTo', timeTo.toJSON());
        }
      },

      changeTimeTo: function changeTimeTo(date) {
        if (_ember['default'].isPresent(date)) {
          this.set('timeTo', date);
          this.set('model.event.to', new Date(date).toJSON());
          this.set('parkingTimeTo', date);
          this.set('model.event.parkingTo', new Date(date).toJSON());
        }
      },

      changeParkingTimeFrom: function changeParkingTimeFrom(date) {
        this.set('parkingTimeFrom', date);
        this.set('model.event.parkingFrom', new Date(date).toJSON());
      },

      changeParkingTimeTo: function changeParkingTimeTo(date) {
        this.set('parkingTimeTo', date);
        this.set('model.event.parkingTo', new Date(date).toJSON());
      },

      changeMetatag: function changeMetatag(metatagLabel) {
        var metatagObject = this.get('model.metatags');

        /* Find the metatag path that corresponds with the label chosen */
        var metatag = metatagObject.children.find(function (element) {
          return element.displayName === metatagLabel;
        });

        this.set('updatedMetatag', metatag.displayName);
        this.set('model.event.eventTypePath', metatag.path);
      },

      toggleLive: function toggleLive() {
        this.toggleProperty('model.event.live');
      },

      tagRequest: function tagRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'tag');
      },

      tagFocused: function tagFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          if (this.get('currentUser.role') === 'admin') {
            return this.query('R%', null, cb, 'tag');
          }
          return this.store.findAll('tag').then(function (resp) {
            cb(resp);
          });
        }
      },

      landmarkSelected: function landmarkSelected(landmark, cb) {
        var includesLandmark = this.get('model.additionalCharges.landmarks').map(function (l) {
          return l.id;
        }).includes(landmark.get('id'));
        var additionalChargesEnabled = this.get('model.additionalChargesUserOptions.additionalChargesEnabled') && includesLandmark;

        this.set('model.additionalChargesEnabled', additionalChargesEnabled);

        cb(landmark);
      },

      landmarkRequest: function landmarkRequest(text, selectedItems, cb) {
        var _this2 = this;

        if (_ember['default'].isBlank(text)) {
          return;
        }

        if (this.get('currentUser.role') === 'admin') {
          return this.query(text, selectedItems, cb, 'landmark');
        }

        return Promise.all(this.get('currentUser.landmarkIds').map(function (landmarkId) {
          return _this2.store.findRecord('landmark', landmarkId);
        })).then(function (resp) {
          return cb(resp);
        });
      },

      landmarkFocused: function landmarkFocused(curSelectedItems, cb) {
        var _this3 = this;

        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          if (this.get('currentUser.role') === 'admin') {
            return this.query('*', null, cb, 'landmark');
          }
          return Promise.all(this.get('currentUser.landmarkIds').map(function (landmarkId) {
            return _this3.store.findRecord('landmark', landmarkId);
          })).then(function (resp) {
            cb(resp);
          });
        }
      },

      landmarkRemoved: function landmarkRemoved(landmark, cb) {
        cb(landmark);
      },

      toggleCash: function toggleCash() {
        this.get('model.transactionTypes').toggleProperty('cash');
      },

      toggleCredit: function toggleCredit() {
        this.get('model.transactionTypes').toggleProperty('credit');
      },

      togglePayments: function togglePayments() {
        this.get('model.transactionTypes').toggleProperty('payment');
      }
    }
  });
});