define('web/models/payment-processor', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    userId: belongsTo('user', { async: true }),
    typeId: belongsTo('payment-processor-type', { async: true }),
    isDefault: attr('boolean'),
    deleted: attr('boolean'),
    credentials: attr(),

    sortedCredentials: computed('credentials', {
      get: function get() {
        var sortedKeys = this.get('credentials').sort(function (a, b) {
          return Object.keys(a) < Object.keys(b);
        });

        return sortedKeys;
      }
    })
  });
});