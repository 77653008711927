define('web/models/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    presell: attr('boolean'),
    landmarkId: belongsTo('landmark', { async: true }),
    pricingIds: hasMany('pricing', { async: true })
  });
});