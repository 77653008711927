define('web/models/transaction', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    user: belongsTo('user'),
    total: attr('number'),
    barcode: attr('string'),
    qrCode: attr('string'),
    confirmationCode: attr('number'),
    type: attr('string'),
    status: attr('string'),
    transactionType: attr('string'),
    transactionTime: attr('string'),
    parkingSpots: attr(),
    enabled: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    lotName: attr('string'),
    employeeFirstName: attr('string'),
    employeeLastName: attr('string'),

    eventName: attr('string'),

    permit: attr(),

    acctId: attr('string'),
    customerName: attr('string'),
    externalOrders: attr(),
    externalTransactions: attr(),
    primeOrders: hasMany('prime-order', { async: false }),
    primeTransactions: hasMany('prime-transaction', { async: false }),
    parkhubTransactions: hasMany('parkhub-transaction', { async: false }),

    vipParkerId: attr('string'),
    vipParkerFirstName: attr('string'),
    vipParkerLastName: attr('string'),

    parkingSpot: attr(),
    pricingId: belongsTo('pricing', { async: true }),

    cardType: attr('string'),
    lastFour: attr('string'),
    expiry: attr('string'),
    cardholderName: attr('string'),

    exemption: attr('string'),

    trimId: (function () {
      var id = this.get('id');
      return id.slice(-4);
    }).property('id'),

    computedTransactionTime: computed('updatedAt', 'transactionTime', function () {
      if (this.get('transactionTime')) {
        return this.get('transactionTime');
      }
      return this.get('updatedAt');
    }),

    employeeName: (function () {
      return this.get('employeeFirstName') + ' ' + this.get('employeeLastName');
    }).property('employeeFirstName', 'employeeLastName'),

    parkingSpotName: computed('pricingId.name', 'parkingSpot', 'externalOrders.[]', 'externalTransactions.[]', {
      get: function get() {
        var possibleNames = [_get(this, 'pricingId.name'), _get(this, 'parkingSpot.name'), _get(this, 'externalOrders.firstObject.spotType'), _get(this, 'externalTransactions.firstObject.spotType')];

        return possibleNames.filter(function (name) {
          return _ember['default'].isPresent(name);
        }).pop();
      }
    })
  });
});