define("web/templates/authenticated-apps/new", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "web/templates/authenticated-apps/new.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered container--push-down");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "new");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "new-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4, "class", "section-form");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "app-name");
        var el8 = dom.createTextNode("App Name");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "app-description");
        var el8 = dom.createTextNode("Description");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100 input-col--select");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "min-role");
        var el8 = dom.createTextNode("Minimum Role");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 3, 3);
        morphs[5] = dom.createMorphAt(element0, 7, 7);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "link-to", ["← Back to list of Authenticated Apps", "authenticated-apps"], ["class", "back-to-list"], ["loc", [null, [1, 0], [1, 92]]]], ["element", "action", ["createApp"], ["on", "submit"], ["loc", [null, [6, 33], [6, 67]]]], ["inline", "input", [], ["id", "app-name", "type", "text", "placeholder", "App Name", "value", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [10, 75], [10, 85]]]]], [], []], "autofocus", "autofocus", "required", "required"], ["loc", [null, [10, 12], [10, 129]]]], ["inline", "textarea", [], ["class", "app-description", "type", "text", "placeholder", "Description", "value", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [17, 91], [17, 108]]]]], [], []], "rows", 4, "required", "required"], ["loc", [null, [17, 12], [17, 137]]]], ["inline", "select-menu", [], ["prompt", "Select a minimum role:", "content", ["subexpr", "@mut", [["get", "availRoles", ["loc", [null, [27, 22], [27, 32]]]]], [], []], "changeSelectProp", ["subexpr", "action", [["subexpr", "mut", [["get", "model.minRole", ["loc", [null, [28, 44], [28, 57]]]]], [], ["loc", [null, [28, 39], [28, 58]]]]], [], ["loc", [null, [28, 31], [28, 59]]]], "selectedValue", ["subexpr", "@mut", [["get", "model.minRole", ["loc", [null, [29, 28], [29, 41]]]]], [], []]], ["loc", [null, [25, 12], [29, 43]]]], ["inline", "async-button", [], ["action", "createApp", "default", "Create App", "pending", "Saving App...", "fulfilled", "App Saved!", "class", "btn btn--success btn__full"], ["loc", [null, [33, 8], [38, 46]]]]],
      locals: [],
      templates: []
    };
  })());
});