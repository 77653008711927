define('web/mixins/nested-activate', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    activate: function activate() {
      _ember['default'].run.later(this, function () {
        var routeName = this.routeName;

        _ember['default'].$('.nav__link[href="/' + routeName + 's"]').addClass('active');
      }, 100);
    },
    deactivate: function deactivate() {
      var routeName = this.routeName;
      _ember['default'].$('.nav__link[href="/' + routeName + 's"]').removeClass('active');
    }
  });
});