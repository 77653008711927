define('web/controllers/modals/unauthorized-vendor', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),

    actions: {
      editEvent: function editEvent() {
        this.send('closeModal');
      }
    }
  });
});