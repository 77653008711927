define("web/templates/additional-charges", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "web/templates/additional-charges.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "additional-charges", [], ["additionalChargesEnabled", ["subexpr", "@mut", [["get", "model.additionalChargesEnabled", ["loc", [null, [3, 29], [3, 59]]]]], [], []], "applyForUpcomingEvents", ["subexpr", "@mut", [["get", "model.applyForUpcomingEvents", ["loc", [null, [4, 27], [4, 55]]]]], [], []], "acrossLocations", ["subexpr", "@mut", [["get", "model.acrossLocations", ["loc", [null, [5, 20], [5, 41]]]]], [], []], "perLocation", ["subexpr", "@mut", [["get", "model.perLocation", ["loc", [null, [6, 16], [6, 33]]]]], [], []], "transactionTypes", ["subexpr", "@mut", [["get", "model.transactionTypes", ["loc", [null, [7, 21], [7, 43]]]]], [], []], "revenueShare", ["subexpr", "@mut", [["get", "model.revenueShare", ["loc", [null, [8, 17], [8, 35]]]]], [], []], "saveState", "saveState", "showConfirmationModal", "showConfirmationModal", "landmarks", ["subexpr", "@mut", [["get", "model.landmarks", ["loc", [null, [11, 14], [11, 29]]]]], [], []]], ["loc", [null, [1, 0], [12, 2]]]]],
      locals: [],
      templates: []
    };
  })());
});