define('web/routes/create/user', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['companyName', 'firstName', 'lastName', 'slug', 'email', 'username', 'role', 'status', 'password'],
    recordBelongsTo: ['operatorId'],

    model: function model() {
      return {};
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        userSearch: null,
        errorMessages: null
      });
    }
  });
});