define('web/adapters/pricing', ['exports', 'web/adapters/application', 'ember'], function (exports, _webAdaptersApplication, _ember) {
  exports['default'] = _webAdaptersApplication['default'].extend({

    updateRecord: function updateRecord(store, type, snapshot) {
      var serializer = store.serializerFor(type.modelName);
      var data = serializer.serialize(snapshot);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      delete data.multidaySelected;
      delete data.pricingId;

      return this.ajax(url, "PUT", {
        data: { pricing: data },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var serializer = store.serializerFor(type.modelName);

      var data = serializer.serialize(snapshot);

      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      delete data.multidaySelected;
      delete data.pricingId;

      return this.ajax(url, "POST", {
        data: { pricing: data },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }

  });
});