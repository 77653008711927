define('web/components/map-box', ['exports', 'web/config/environment', 'ember'], function (exports, _webConfigEnvironment, _ember) {

  var L = window.L; // TODO: would be nice to import this

  var assert = _ember['default'].assert;
  var observer = _ember['default'].observer;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var once = _ember['default'].run.once;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['mapbox'],

    // public
    fitInitialBounds: true,
    fitBoundsOnChange: true,
    fitBoundsOptions: get(_webConfigEnvironment['default'], 'mapbox.fitBoundsOptions') || null,
    mapOptions: get(_webConfigEnvironment['default'], 'mapbox.mapOptions') || null,
    zoomEnd: null,
    coords: null,

    // internal
    isMap: true,
    mapbox: null,

    _firstContentChanged: true,

    didRender: function didRender() {
      this._super.apply(this, arguments);

      var curAccessToken = get(L, 'mapbox.accessToken');
      if (!curAccessToken) {
        var configAccessToken = get(_webConfigEnvironment['default'], 'mapbox.accessToken');
        assert('must either set L.mapbox.accessToken or provide Config.mapbox.accessToken', configAccessToken);
        set(L, 'mapbox.accessToken', configAccessToken);
      }

      var url = get(_webConfigEnvironment['default'], 'mapbox.url');
      assert('Config.mapbox.url is required', url);

      var mapbox = L.mapbox.map(this.element, url, this.get('mapOptions'));
      var layer = L.mapbox.featureLayer().addTo(mapbox);

      mapbox.on('zoomend', this._onZoomEnd, this);

      this.set('mapbox', mapbox);
      this.set('layer', layer);
    },

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var mapbox = this.get('mapbox');
      if (mapbox) {
        mapbox.remove();
      }
    },

    setDefaultView: observer('coords', function () {
      var coords = this.get('coords');
      var mapbox = this.get('mapbox');

      if (coords) {
        mapbox.setView([coords.latitude, coords.longitude]);
      }
    }),

    contentChanged: function contentChanged() {
      _ember['default'].run.debounce(this, this._onContentChanged, 1);
    },

    _onContentChanged: function _onContentChanged() {
      if (this.get('fitBoundsOnChange') || this.get('fitInitialBounds') && this.get('_firstContentChanged')) {
        this.set('_firstContentChanged', false);
        var mapbox = this.get('mapbox');
        var layer = this.get('layer');

        if (mapbox && layer) {
          mapbox.fitBounds(layer.getBounds(), this.get('fitBoundsOptions'));
        }
      }
    },

    _onZoomEnd: function _onZoomEnd() {
      _ember['default'].run(this, this.sendAction, 'zoomEnd', this.get('mapbox').getZoom());
    },

    observeActiveItem: (function () {
      var activeItem = this.get('activeItem');
      if (activeItem) {
        once(this, this.onActiveChanged, activeItem);
      }
    }).observes('activeItem'),

    onActiveChanged: function onActiveChanged(activeItem) {
      this.get('layer').eachLayer(function (e) {
        var feature = e.toGeoJSON();
        var featureLoc = feature.geometry.coordinates,
            activeLoc = [activeItem.location.lon, activeItem.location.lat];

        if (featureLoc[0] === activeLoc[0] && featureLoc[1] === activeLoc[1]) {
          e.openPopup();
        }
      });
    }
  });
});