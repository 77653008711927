define("web/templates/events/list", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 8
            },
            "end": {
              "line": 6,
              "column": 42
            }
          },
          "moduleName": "web/templates/events/list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" to ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["content", "offset", ["loc", [null, [6, 28], [6, 38]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 6
            },
            "end": {
              "line": 12,
              "column": 6
            }
          },
          "moduleName": "web/templates/events/list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn--outline btn--dark btn--sm");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" 100\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["previousHundred"], [], ["loc", [null, [9, 54], [9, 82]]]], ["inline", "inline-svg", ["/assets/images/admin-icons/back-hundred"], ["class", "icon icon--back-hundred"], ["loc", [null, [10, 10], [10, 98]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 10
                },
                "end": {
                  "line": 40,
                  "column": 10
                }
              },
              "moduleName": "web/templates/events/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["/assets/images/admin-icons/hot-star"], ["class", "icon icon-hot-star"], ["loc", [null, [39, 12], [39, 91]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 10
                },
                "end": {
                  "line": 42,
                  "column": 10
                }
              },
              "moduleName": "web/templates/events/list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "inline-svg", ["/assets/images/admin-icons/hot-star"], ["class", "icon icon-cold-star"], ["loc", [null, [41, 12], [41, 92]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 4
              },
              "end": {
                "line": 48,
                "column": 4
              }
            },
            "moduleName": "web/templates/events/list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "tbody__tr tbody__tr--clickable");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[4] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
            return morphs;
          },
          statements: [["element", "action", ["eventTransition", ["get", "event.id", ["loc", [null, [36, 76], [36, 84]]]]], [], ["loc", [null, [36, 49], [36, 86]]]], ["block", "if", [["get", "event.hot", ["loc", [null, [38, 16], [38, 25]]]]], [], 0, 1, ["loc", [null, [38, 10], [42, 17]]]], ["content", "event.date", ["loc", [null, [44, 34], [44, 48]]]], ["inline", "truncate-text", [["get", "event.name", ["loc", [null, [45, 50], [45, 60]]]], 45], [], ["loc", [null, [45, 34], [45, 65]]]], ["inline", "inline-svg", ["assets/images/arrow-down2"], ["class", "icon icon-expand-view"], ["loc", [null, [46, 34], [46, 106]]]]],
          locals: ["event"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 2
            },
            "end": {
              "line": 50,
              "column": 2
            }
          },
          "moduleName": "web/templates/events/list.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "each", [["get", "model", ["loc", [null, [35, 12], [35, 17]]]]], [], 0, null, ["loc", [null, [35, 4], [48, 13]]]]],
        locals: ["model"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 0
          }
        },
        "moduleName": "web/templates/events/list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered container--push-down");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "table-info");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "table-info__stat");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "stats");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "ital");
        var el6 = dom.createTextNode(" of  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "input-row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-col--100");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("input");
        dom.setAttribute(el4, "type", "text");
        dom.setAttribute(el4, "name", "record-search");
        dom.setAttribute(el4, "maxlength", "40");
        dom.setAttribute(el4, "placeholder", "Search for an event");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container--push-down container--centered");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var element3 = dom.childAt(element2, [1, 1]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element2, [3, 1]);
        var element6 = dom.childAt(element5, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element4, 1, 1);
        morphs[1] = dom.createMorphAt(element4, 2, 2);
        morphs[2] = dom.createMorphAt(element4, 6, 6);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createAttrMorph(element6, 'value');
        morphs[5] = dom.createAttrMorph(element6, 'oninput');
        morphs[6] = dom.createMorphAt(element5, 3, 3);
        morphs[7] = dom.createMorphAt(element2, 5, 5);
        morphs[8] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "offsetExists", ["loc", [null, [6, 14], [6, 26]]]]], [], 0, null, ["loc", [null, [6, 8], [6, 49]]]], ["content", "offsetLength", ["loc", [null, [6, 49], [6, 65]]]], ["inline", "plural-count", [["get", "model.meta.pagination.total", ["loc", [null, [6, 113], [6, 140]]]], "event", "events"], [], ["loc", [null, [6, 98], [6, 159]]]], ["block", "if", [["get", "offsetExists", ["loc", [null, [8, 12], [8, 24]]]]], [], 1, null, ["loc", [null, [8, 6], [12, 13]]]], ["attribute", "value", ["get", "search", ["loc", [null, [23, 16], [23, 22]]]]], ["attribute", "oninput", ["subexpr", "action", ["changeSearch"], ["value", "target.value"], ["loc", [null, [24, 16], [24, 62]]]]], ["inline", "inline-svg", ["/assets/images/search"], ["class", "icon icon--search"], ["loc", [null, [25, 6], [25, 70]]]], ["block", "ph-table", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [30, 10], [30, 15]]]]], [], []], "hasBlock", true, "cols", ["subexpr", "@mut", [["get", "eventCols", ["loc", [null, [32, 9], [32, 18]]]]], [], []], "isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [33, 14], [33, 23]]]]], [], []]], 2, null, ["loc", [null, [29, 2], [50, 15]]]], ["inline", "load-more", [], ["backToTop", ["subexpr", "action", ["backToTop"], [], ["loc", [null, [54, 16], [54, 36]]]], "loadMore", ["subexpr", "action", ["loadMore"], [], ["loc", [null, [55, 15], [55, 34]]]], "canLoadMore", ["subexpr", "@mut", [["get", "canLoadMore", ["loc", [null, [56, 18], [56, 29]]]]], [], []], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [57, 12], [57, 17]]]]], [], []], "offsetExists", ["subexpr", "@mut", [["get", "offsetExists", ["loc", [null, [58, 19], [58, 31]]]]], [], []]], ["loc", [null, [53, 4], [58, 34]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});