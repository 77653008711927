define('web/routes/ownerships', ['exports', 'ember', 'web/mixins/records-search'], function (exports, _ember, _webMixinsRecordsSearch) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isPresent = _ember['default'].isPresent;

  var makeParams = function makeParams() {
    var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var keys = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    return keys.reduce(function (hash, k) {
      var val = get(params, k);
      if (isPresent(val)) {
        set(hash, k, val);
      }

      return hash;
    }, {});
  };

  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordsSearch.RouteSearchMixin, {
    queryParams: {
      offset: { refreshModel: true },
      limit: { refreshModel: true },
      search: { refreshModel: true }
    },

    model: function model(params) {
      var _this = this;

      var lotMeta = undefined;

      return _ember['default'].RSVP.hash({
        lots: this.store.query('lot', makeParams(params, ['limit', 'offset', 'getUnclaimed', 'getAll', 'search'])),
        ownerships: this.store.query('ownership', makeParams(params, ['limit', 'offset', 'getPending', 'getApproved']))
      }).then(function (resp) {
        lotMeta = resp.lots.meta;

        return _ember['default'].RSVP.all(resp.lots.map(function (lot) {
          if (lot.get('operator.content')) {
            if (lot.get('operator.id') === get(_this, 'currentUser.id')) {
              set(lot, 'alreadyClaimed', true);
            }
          }
          return lot;
        })).then(function (filteredLots) {
          var proxyLots = _ember['default'].ArrayProxy.create();
          set(proxyLots, 'content', filteredLots);
          set(proxyLots, 'meta', lotMeta);

          return _ember['default'].Object.create({
            lots: proxyLots,
            ownerships: resp.ownerships
          });
        });
      })['catch'](function (err) {
        _this.flashMessages.danger(err.message);
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      },

      loading: function loading() {
        return false;
      }
    }

  });
});