define('web/controllers/modals/modify-tag', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Controller.extend({
    recordName: 'tag',
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),
    deleteTagTriggered: false,

    curModel: computed('model', {
      get: function get() {
        return this.get('model') || {};
      },
      set: function set(k, newVal) {
        return newVal;
      }
    }),

    selectedLandmark: computed('curModel.landmarkId', {
      get: function get() {
        return this.get('curModel.landmarkId');
      },
      set: function set(key, newVal) {
        this.set('curModel.landmarkId', newVal);
        return this.get('curModel.landmarkId');
      }
    }),

    name: computed('curModel', 'curModel.name', {
      get: function get() {
        return this.get('curModel.name');
      },
      set: function set(key, newVal) {
        this.set('curModel.name', newVal);
        return newVal;
      }
    }),

    presell: computed('curModel', 'curModel.presell', {
      get: function get() {
        return this.get('curModel.presell');
      },
      set: function set(key, newVal) {
        this.set('curModel.presell', newVal);
        return newVal;
      }
    }),

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      if (type === 'landmark') {
        params.dBSearch = true;
      }

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {

      toggleSelected: function toggleSelected() {
        this.toggleProperty('presell');
      },

      typeaheadRequest: function typeaheadRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'landmark');
      },

      landmarkFocused: function landmarkFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'landmark');
        }
      },

      closeModifyTag: function closeModifyTag() {
        this.send('closeModal');
      },

      saveTag: function saveTag(callback) {
        var _this = this;

        var curModel = this.get('curModel');
        var tag = this.store.createRecord('tag', curModel);
        var promise = tag.save();
        callback(promise);

        promise.then(function (resp) {

          later(function () {
            _this.setProperties({
              name: null,
              selectedLandmark: null
            });

            _this.store.unloadRecord(tag);
            _this.send('closeModal');
            _this.send('invalidateModel');
          }, 1000);
        })['catch'](function (err) {
          var error = err.message || err.error;
          _this.flashMessages.danger(error);
        });
      },

      editTag: function editTag(callback) {
        var _this2 = this;

        var curModel = this.get('curModel');

        var promise = curModel.save();
        callback(promise);

        promise.then(function () {
          later(function () {
            _this2.send('closeModal');
          }, 1000);
        })['catch'](function (err) {
          return _this2.flashMessages.danger(err.message);
        });
      },

      deleteTag: function deleteTag(callback) {
        var _this3 = this;

        var curModel = this.get('curModel');
        curModel.deleteRecord();

        var promise = curModel.save();
        callback(promise);

        promise.then(function (resp) {
          later(function () {
            _this3.send('closeModal');
            _this3.set('deleteTagTriggered', false);
          }, 1000);
        })['catch'](function (err) {
          return _this3.flashMessages.danger(err.message);
        });
      },

      toggleDeleteTag: function toggleDeleteTag() {
        this.toggleProperty('deleteTagTriggered');
      }
    }
  });
});