define("web/templates/components/sales-summary-chart", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/sales-summary-chart.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-header page-header--sales-space");
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "flex-container flex-container--end page-header--sales-space--title");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "page-header__title");
        var el4 = dom.createTextNode("Here's a breakdown of the week's sales...");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("a");
        dom.setAttribute(el3, "href", "#");
        dom.setAttribute(el3, "class", "page-header__see-more");
        var el4 = dom.createTextNode("Get Reports →");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" <div class=\"page-header__info\">\n    <div class=\"btn-group\">\n      <button class=\"btn-group__btn {{if isWeek 'active' ''}}\" {{action 'setViewBy' 'week' selected}}>Week</button><button class=\"btn-group__btn {{if isMonth 'active' ''}}\" {{action 'setViewBy' 'month'  selected}}>Month</button>\n    </div>\n    <a class=\"circle-btn__btn\" {{action 'goToToday'}}>\n      {{inline-svg '/assets/images/today' class='icon icon--chart-label'}}\n    </a>\n  </div> ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "window-data");
        dom.setAttribute(el1, "style", "max-height: 270px;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn--transparent btn--back");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn--transparent btn--forward");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1, 3]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element1, [5]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element1, 1, 1);
        morphs[2] = dom.createElementMorph(element2);
        morphs[3] = dom.createMorphAt(element2, 0, 0);
        morphs[4] = dom.createElementMorph(element3);
        morphs[5] = dom.createMorphAt(element3, 0, 0);
        return morphs;
      },
      statements: [["element", "action", ["transitionToReports"], [], ["loc", [null, [5, 46], [5, 78]]]], ["inline", "c3-chart", [], ["config", ["subexpr", "@mut", [["get", "barConfig", ["loc", [null, [21, 11], [21, 20]]]]], [], []], "data", ["subexpr", "@mut", [["get", "barData", ["loc", [null, [22, 9], [22, 16]]]]], [], []]], ["loc", [null, [20, 2], [22, 18]]]], ["element", "action", ["toggle", "subtract", ["get", "data", ["loc", [null, [24, 74], [24, 78]]]]], [], ["loc", [null, [24, 45], [24, 80]]]], ["inline", "inline-svg", ["/assets/images/tall-arrow"], [], ["loc", [null, [24, 81], [24, 123]]]], ["element", "action", ["toggle", "add", ["get", "data", ["loc", [null, [25, 72], [25, 76]]]]], [], ["loc", [null, [25, 48], [25, 78]]]], ["inline", "inline-svg", ["/assets/images/tall-arrow"], [], ["loc", [null, [25, 79], [25, 121]]]]],
      locals: [],
      templates: []
    };
  })());
});