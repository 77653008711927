define('web/controllers/authenticated-apps/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    availRoles: ['guest', 'registered', 'attendant', 'operator', 'admin'],

    actions: {
      createApp: function createApp(callback) {
        var _this = this;

        var model = this.get('model');

        var authenticatedApp = this.store.createRecord('authenticated-app', {
          name: this.get('model.name'),
          description: this.get('model.description'),
          minRole: this.get('model.minRole')
        });

        var promise = authenticatedApp.save();
        callback(promise);

        promise.then(function () {
          _ember['default'].run.later(function () {
            _this.transitionToRoute('authenticated-apps');
          }, 2000);
        })['catch'](function (err) {
          _this.flashMessages.danger(err.message);
          _this.store.unloadRecord(model);
        });
      }
    }
  });
});