define('web/routes/lot/details', ['exports', 'ember', 'ic-ajax', 'web/mixins/reset-scroll', 'web/mixins/record-editor', 'web/config/environment'], function (exports, _ember, _icAjax, _webMixinsResetScroll, _webMixinsRecordEditor, _webConfigEnvironment) {

  /* Retrieve the metatags available from the meta-tagging-service */
  var metatags = (0, _icAjax['default'])({
    method: 'GET',
    url: _webConfigEnvironment['default'].metatagUrl + '/tags/tag.inventory_type',
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  })['catch'](function (e) {
    return { children: [] };
  }); // or set it to blank array if service is down

  var makeParams = function makeParams() {
    var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var keys = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    return keys.reduce(function (hash, k) {
      var val = get(params, k);
      if (isPresent(val)) {
        set(hash, k, val);
      }

      return hash;
    }, {});
  };

  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], _webMixinsRecordEditor.RouteRecordEditor, {
    model: function model() {
      var externalId = this.modelFor('lot').get('id');
      return _ember['default'].RSVP.hash({
        availableAmenities: this.store.findAll('amenity'),
        paymentProcessors: this.store.findAll('payment-processor'),
        images: this.modelFor('lot').get('images').then(function (imgs) {
          return imgs;
        }),
        metatags: metatags,
        selectedMetatag: (0, _icAjax['default'])({
          method: 'POST',
          url: _webConfigEnvironment['default'].metatagUrl + '/associations/tags',
          contentType: 'application/json',
          data: JSON.stringify({
            externalKeys: [externalId],
            associationType: 'PORTAL_V2_LOT'
          }),
          dataType: 'json',
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        })['catch'](function (e) {
          return { tags: [] };
        }), // or set it to blank array if service is down
        ownership: this.store.query('ownership', { lot_id: externalId })
      }).then(function (resps) {
        /* by doing this we ensure that all the promises are resolved prior
         * to populating the frontend */
        var selectedMetatag = undefined;
        if (resps['selectedMetatag'].tags.length > 0) {
          selectedMetatag = resps['selectedMetatag'].tags[0];
        } else {
          selectedMetatag = ''; // this can't be undefined
        }

        var ownerships = resps["ownership"].toArray();

        return {
          availableAmenities: resps["availableAmenities"],
          paymentProcessors: resps["paymentProcessors"],
          images: resps["images"],
          metatags: resps["metatags"],
          selectedMetatag: selectedMetatag,
          ownerships: ownerships,
          assignAllNewAttendants: ownerships[0].get('assignAllNewAttendants')
        };
      });
    },

    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      this.modelFor('lot').reload();
      this.set('confirming', false);
    },

    setupController: function setupController(controller, model) {
      this._super(controller, this.modelFor('lot'));

      var processors = [];

      model.paymentProcessors.content.forEach(function (processor) {
        if (processor.getRecord().get('deleted') === false) {
          processors.push(processor);
        }
      });

      model.paymentProcessors.set('content', processors);

      controller.set('availableAmenities', model.availableAmenities);
      controller.set('paymentProcessors', model.paymentProcessors);
      controller.set('metatags', model.metatags);
      controller.set('selectedMetatag', model.selectedMetatag.displayName);
      controller.set('assignAllNewAttendants', model.assignAllNewAttendants);
    },

    actions: {
      invalidateModel: function invalidateModel(model) {
        var _this = this;

        this.modelFor('lot').reload();

        /* refresh the page to ensure the updated external attributes (like metatag)
         * reflect the truth, and not what is stored in the Ember model*/
        this.refresh();

        /* If there's a problem updating the metatag, tell the user.
         * NOTE: This can be a PUT or POST operation depending on if there
         * is already an associated tag.*/
        var updatedMetatag = this.controllerFor('lot.details').get('updatedMetatag');
        this.model('lot').then(function (lotModel) {
          if (lotModel.selectedMetatag.tags.length > 0 && lotModel.selectedMetatag.tags[0].displayName !== updatedMetatag) {
            _this.flashMessages.danger('There was a problem updating the inventory type.');
          }
        });
      },

      loading: function loading() {
        return false;
      }
    }
  });
});