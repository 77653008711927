define('web/components/ph-calendar/ph-header', ['exports', 'web/components/as-calendar/header'], function (exports, _webComponentsAsCalendarHeader) {
  exports['default'] = _webComponentsAsCalendarHeader['default'].extend({
    actions: {
      goToCurrentWeek: function goToCurrentWeek() {
        this._super();

        if (this.attrs['onNavigateWeek']) {
          this.attrs['onNavigateWeek']('current');
        }
      }
    }
  });
});