define('web/serializers/image', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    attrs: {
      hero: {
        serialize: false
      },
      sidekick: {
        serialize: false
      },
      thumb: {
        serialize: false
      }
    },
    serialize: function serialize(user, options) {
      var t = this._super(user, options);

      Object.keys(t).forEach(function (k) {
        if (t[k] === null) {
          t[k] = undefined;
        }
      });

      return t;
    }

  });
});