define('web/controllers/landmark/details', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/state-options', 'web/mixins/set-lat-lon-controller', 'web/mixins/image-uploader-controller', 'web/helpers/slugify'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsStateOptions, _webMixinsSetLatLonController, _webMixinsImageUploaderController, _webHelpersSlugify) {
  var computed = _ember['default'].computed;
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend(_ember['default'].Copyable, _webMixinsRecordEditor['default'], _webMixinsStateOptions['default'], _webMixinsSetLatLonController['default'], _webMixinsImageUploaderController['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: computed.alias('model.id'),
    recordName: 'landmark',
    recordTransitionTo: 'landmarks',
    recordModel: 'landmark',
    isDeleteDisabled: true,

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      toggleLive: function toggleLive() {
        this.toggleProperty('model.live');
      },

      changeName: function changeName(name) {
        var slugged = (0, _webHelpersSlugify.slugify)(name);
        this.set('model.slug', slugged);
        this.set('model.name', name);
      },

      changeSlug: function changeSlug(slug) {
        this.set('model.slug', (0, _webHelpersSlugify.slugify)(slug));
      },

      changeZip: function changeZip(zip) {
        this.set('model.zip', zip);
        this._setLatLong(zip);
      },

      changeURL: function changeURL(val) {
        var curValidation = _ember['default'].copy(this.get('model.externalValidations.firstObject'));

        set(curValidation, 'uri', val);
        set(get(this, 'model'), 'externalValidations', [curValidation]);
      },

      regionRequest: function regionRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'region');
      },

      regionFocused: function regionFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          this.query('*', null, cb, 'region');
        }
      }
    }

  });
});