define('web/models/external-order', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    orderId: belongsTo('order', { async: true }),
    eventId: belongsTo('event', { async: true }),
    source: attr('string'),
    acctId: attr('string'),
    customerName: attr('string'),
    spotType: attr('string')
  });
});