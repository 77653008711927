define('web/components/ph-date-picker', ['exports', 'ember', 'web/components/date-picker'], function (exports, _ember, _webComponentsDatePicker) {
  exports['default'] = _webComponentsDatePicker['default'].extend({
    minDate: new Date(),
    maxDate: new Date(),
    newDate: new Date(),
    datePickerSetup: false,

    date: new Date(),

    didReceiveAttrs: function didReceiveAttrs(attrs) {
      var _this = this;

      if (!this.get('datepickerSetup')) {
        _ember['default'].run.next(function () {
          _this.setupDatepicker(attrs.newAttrs.date.value);
          _this.set('datepickerSetup', true);
        });
        return;
      }
    },

    setupDatepicker: function setupDatepicker(date) {
      var _this2 = this;

      var d = null;
      if (!_ember['default'].isBlank(date)) {
        // serialize moment.js date either from plain date object or string
        if (this.get('valueFormat') === 'date') {
          d = window.moment(date);
        } else {
          d = window.moment(date, this.get('valueFormat'));
        }
      } else {
        // no date was found in data source. Either respect that or set it to now
        if (this.get('allowBlank')) {
          // creates an "Invalid Date" object, which will clear the input field
          d = window.moment(null);
        } else {
          d = window.moment();
          // also set the controllers date here. If the controller passes in a
          // null date, it is assumed that todays date should be used
          this._setControllerDate(d);
        }
      }

      this.get('_picker').setDate(d.format());

      // Added by Dialexa
      var minDate = this.get('minDate');
      var newDate = this.get('newDate');
      var maxDate = this.get('maxDate');

      if (this.get('autofocus')) {
        _ember['default'].run.later(function () {
          _this2.get('_picker').show();
        }, 200);
      }

      this.get('_picker').setMinDate(minDate);
      this.get('_picker').setMaxDate(moment(newDate).add(maxDate, 'd'));
    }

  });
});