define('web/components/user-block', ['exports', 'ember'], function (exports, _ember) {
  var equal = _ember['default'].computed.equal;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sales-block__container'],
    isLot: equal('type', 'lot'),

    isEmployee: (function () {
      if (!this.get('isLot')) {
        if (equal('type', 'attendant')) {
          return true;
        } else if (equal('type', 'subOperator')) {
          return true;
        }
      }
    }).property('type'),

    userBg: (function () {
      var user = this.get('record.images.firstObject.hero');
      var company = this.get('record.companyLogo');

      if (company) {
        return ('background-image: url(' + company + ')').htmlSafe();
      } else if (user) {
        return ('background-image: url(' + user + ')').htmlSafe();
      } else {
        return 'background-image: url(assets/images/placeholder-md.jpg)'.htmlSafe();
      }
    }).property('record.images', 'record.companyLogo')
  });
});