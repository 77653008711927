define("web/routes/external-transactions/index", ["exports", "ember", "web/mixins/build-params"], function (exports, _ember, _webMixinsBuildParams) {
  exports["default"] = _ember["default"].Route.extend({
    queryParams: {
      getOrphaned: { refreshModel: true },
      getAdopted: { refreshModel: true }
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.controller) {
        this.controller.set("isLoading", true);
      }
    },

    model: function model(params) {
      params.limit = 500;

      return this.store.query("external-event", (0, _webMixinsBuildParams.buildParams)(params, ["getOrphaned", "getAdopted", "limit", "offset"]));
    },

    afterModel: function afterModel() {
      this._super.apply(this, arguments);
      if (this.controller) {
        this.controller.set("isLoading", false);
      }
    },

    actions: {
      loading: function loading() {
        return false;
      },

      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});