define('web/components/pricing-modal-button', ['exports', 'ember', 'web/helpers/modifier-price'], function (exports, _ember, _webHelpersModifierPrice) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['tag-cluster__item'],
    modifyPricingCluster: 'modifyPricingCluster',
    sortOptions: ['createdAt:asc'],

    actions: {
      modifyPricingCluster: function modifyPricingCluster(tag, lot, pricings, type, title, userOptions, formattedPricings) {
        this.sendAction('modifyPricingCluster', tag, lot, pricings, type, title, userOptions, formattedPricings);
      }
    }
  });
});