define('web/models/promotion', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    title: _emberData['default'].attr('string'),
    subtitle: _emberData['default'].attr('string'),
    link: _emberData['default'].attr('string'),
    icon: _emberData['default'].attr('string'),
    iconData: _emberData['default'].attr('file'),
    iconType: _emberData['default'].attr('string'),
    createdAt: _emberData['default'].attr('string'),
    updatedAt: _emberData['default'].attr('string'),
    buttonText: _emberData['default'].attr('string'),

    images: _emberData['default'].hasMany('image', { async: true })
  });
});