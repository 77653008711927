define('web/controllers/create/landmark', ['exports', 'ember', 'web/mixins/state-options', 'web/mixins/record-create', 'web/mixins/set-lat-lon-controller', 'web/helpers/slugify'], function (exports, _ember, _webMixinsStateOptions, _webMixinsRecordCreate, _webMixinsSetLatLonController, _webHelpersSlugify) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsStateOptions['default'], _webMixinsRecordCreate.ControllerRecordCreate, _webMixinsSetLatLonController['default'], {
    searches: ['region'],

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      changeName: function changeName(name) {
        var slugged = (0, _webHelpersSlugify.slugify)(name);
        this.set('model.slug', slugged);
        this.set('model.name', name);
      },

      changeSlug: function changeSlug(slug) {
        this.set('model.slug', (0, _webHelpersSlugify.slugify)(slug));
      },

      changeZip: function changeZip(zip) {
        this.set('model.zip', zip);
        this._setLatLong(zip);
      },

      regionRequest: function regionRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'region');
      },

      regionFocused: function regionFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'region');
        }
      }
    }
  });
});