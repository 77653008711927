define('web/helpers/build-tax-object', ['exports', 'ember'], function (exports, _ember) {
  exports.buildTaxObject = buildTaxObject;

  function buildTaxObject(taxAssociation) {
    var taxWidgetOptions = _ember['default'].Object.create({
      tax: false,
      transactionTypes: _ember['default'].Object.create({
        cash: false,
        credit: false,
        payment: false
      })
    });

    var transactionTypes = taxAssociation.get('transactionTypes');

    if (transactionTypes && transactionTypes.length > 0) {
      transactionTypes.forEach(function (transactionType) {
        if (transactionType === 'cash') {
          taxWidgetOptions.transactionTypes.cash = true;
        }

        if (transactionType === 'credit') {
          taxWidgetOptions.transactionTypes.credit = true;
        }

        if (transactionType === 'payment') {
          taxWidgetOptions.transactionTypes.payment = true;
        }
      });

      if (Object.values(taxWidgetOptions.transactionTypes).includes(true)) {
        taxWidgetOptions.tax = true;
      }
    }

    return taxWidgetOptions;
  }

  exports['default'] = _ember['default'].Helper.helper(buildTaxObject);
});