define('web/routes/my-account', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var user = this.get('currentUser');
      return this.store.findRecord('user', user.id);
    },

    deactivate: function deactivate() {
      this.currentModel.rollbackAttributes();
    }
  });
});