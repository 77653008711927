define('web/routes/sales', ['exports', 'ember'], function (exports, _ember) {

  // const { get, set } = Ember;

  exports['default'] = _ember['default'].Route.extend({

    model: function model() {
      return _ember['default'].RSVP.hash({
        reports: this.store.query('report', { start: moment().toJSON(), range: 'week' }),
        events: this.store.query('event', {
          limit: 6
        }),
        // .then(events => {
        //   return Ember.RSVP.all(events.map(ev => {
        //     this.store.query('report', {
        //       eventIds: [get(ev, 'id')]
        //     }).then(function(resp) {
        //       const totalRevenue = resp.reduce((sum, order) => {
        //         return parseInt(sum) + parseInt(get(order, 'total'));
        //       }, 0);
        //       set(ev, 'totalRevenue', totalRevenue);
        //     });
        //   })).then(() => events);
        // }),
        operators: this.store.query('user', {
          role: 'operator',
          limit: 6
        }),
        lots: this.store.query('lot', {
          limit: 6
        }),
        landmarks: this.store.query('landmark', {
          limit: 6
        }),
        regions: this.store.query('region', {
          limit: 6
        }),
        subOperators: this.store.query('user', {
          role: 'sub-operator',
          limit: 6
        }),
        attendants: this.store.query('user', {
          role: 'attendant',
          limit: 6
        })
      }).then(_ember['default'].Object.create.bind(_ember['default'].Object));
    }
  });
});