define('web/components/search-bar', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    searchChanged: 'searchChanged',
    classNames: ['search-bar'],
    classNameBindings: ['type'],

    actions: {
      searchChanged: function searchChanged(e) {
        run.debounce(this, this.attrs.searchChanged, e, 750);
      }
    }
  });
});