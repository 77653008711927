define('web/models/external-event', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    eventId: _emberData['default'].belongsTo('event', { async: true }),
    eventIdentifier: _emberData['default'].attr('string'),
    eventNameLong: _emberData['default'].attr('string'),
    eventTimestamp: _emberData['default'].attr('date'),
    externalTransactionsCount: _emberData['default'].attr('number'),
    state: _emberData['default'].attr('string'),

    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date')
  });
});