define('web/controllers/modals/duplicate-parkers', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    vipParkerController: _ember['default'].inject.controller('create.vip-parker'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),
    selectedDuplicateParkers: _ember['default'].A(),

    showSummary: _ember['default'].computed('model', function () {
      return this.get('model').length > 5;
    }),

    parkerMessage: _ember['default'].computed('model', function () {
      var count = this.get('model.length') - 1;

      return this.get('model.firstObject.firstName') + ' ' + this.get('model.firstObject.lastName') + ' and ' + count + ' others';
    }),

    resetState: function resetState() {
      this.get('selectedDuplicateParkers').clear();
      this.set('showSummary', null);
    },

    canClear: function canClear() {
      return this.get('selectedDuplicateParkers.length') === 0;
    },

    actions: {
      pushDuplicateParker: function pushDuplicateParker(val, checked) {
        if (checked) {
          this.get('selectedDuplicateParkers').pushObject(val);
        } else {
          this.get('selectedDuplicateParkers').removeObject(val);
        }
      },

      toggleParkers: function toggleParkers() {
        this.set('showSummary', false);
      },

      selectAllParkers: function selectAllParkers() {
        _ember['default'].$('.modal-body input[type="checkbox"]').click();
      },

      createDuplicateParkers: function createDuplicateParkers() {
        if (this.get('selectedDuplicateParkers').length === 0) {
          this.get('flashMessages').danger('You must select at least one VIP Parker.');
        } else {
          this.get('vipParkerController').send('postDuplicateParkers');
        }
      },

      clearAndClose: function clearAndClose() {
        this.set('vipParkerController.cancelledDuplicateCreation', true);

        this.get('vipParkerController').send('resetState');
      }
    }
  });
});