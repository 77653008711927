define("web/templates/components/ph-table/attendant-table-row", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/ph-table/attendant-table-row.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--sm");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--sm");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--sm");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--sm");
        var el2 = dom.createElement("span");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--sm");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--sm price");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [6, 0]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[3] = dom.createAttrMorph(element0, 'class');
        morphs[4] = dom.createMorphAt(element0, 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [8]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(fragment, [10]), 0, 0);
        return morphs;
      },
      statements: [["content", "order.employeeName", ["loc", [null, [1, 26], [1, 48]]]], ["content", "order.type", ["loc", [null, [2, 26], [2, 40]]]], ["content", "order.status", ["loc", [null, [3, 26], [3, 42]]]], ["attribute", "class", ["concat", ["pill-types ", ["get", "order.transactionType", ["loc", [null, [4, 52], [4, 73]]]]]]], ["content", "order.transactionType", ["loc", [null, [4, 77], [4, 102]]]], ["inline", "or-dash", [["subexpr", "truncate-text", [["get", "order.lotName", ["loc", [null, [5, 51], [5, 64]]]], 20], [], ["loc", [null, [5, 36], [5, 68]]]], 2], [], ["loc", [null, [5, 26], [5, 72]]]], ["content", "order.price", ["loc", [null, [6, 32], [6, 47]]]]],
      locals: [],
      templates: []
    };
  })());
});