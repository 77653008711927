define('web/components/merchant-account-form', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  var computed = _ember['default'].computed;
  var alias = _ember['default'].computed.alias;
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['edit-body', 'merchant-form__container'],

    recordModel: 'payment-processor',
    recordId: alias('currentPaymentProcessor.id'),
    cancelModify: 'cancelModify',
    saveProcessor: 'saveProcessor',

    confirmDelete: false,
    createCompleted: false,
    processorDeleted: false,

    credentialObjects: [],
    credentials: [],

    resetCredentials: (function () {
      this.setProperties({
        credentialObjects: [],
        credentials: []
      });
    }).on('init'),

    uniqueKeys: computed('credentialObjects.[]', function () {
      var credentials = this.get('credentialObjects');
      var uniqueKeys = [];

      var getUniqueKeys = credentials.reduce(function (collector, obj) {
        Object.keys(obj).forEach(function (key) {
          collector[key] = true;
        });

        return collector;
      }, {});

      for (var k in getUniqueKeys) {
        uniqueKeys.push(k);
      }

      return uniqueKeys;
    }),

    actions: {
      saveProcessor: function saveProcessor() {
        var _this = this;

        var credentials = this.get('credentialObjects');
        var uniqueKeys = this.get('uniqueKeys');
        var arraysObject = {};

        this.set('credentials', []);

        uniqueKeys.forEach(function (key, index) {
          arraysObject[index] = credentials.filter(function (cred) {
            if (cred[key]) {
              return true;
            } else {
              return false;
            }
          });

          var len = arraysObject[index].length;

          _this.get('credentials').push(arraysObject[index][len - 1]);
        });

        var creds = this.get('currentPaymentProcessor.credentials');
        var newCreds = this.get('credentials');

        newCreds.forEach(function (newCred) {
          creds = creds.filter(function (oldCred) {
            var oldKey = Object.keys(oldCred).pop();
            var newKey = Object.keys(newCred).pop();

            if (oldKey !== newKey) {
              return true;
            } else {
              return false;
            }
          });
        });

        creds = creds.concat(newCreds);

        var paymentProcessor = _ember['default'].Object.create({
          paymentProcessor: {
            name: this.get('currentPaymentProcessor.name'),
            credentials: creds,
            isDefault: this.get('currentPaymentProcessor.isDefault'),
            deleted: this.get('currentPaymentProcessor.deleted')
          }
        });

        (0, _icAjax['default'])({
          method: 'PUT',
          url: _webConfigEnvironment['default'].apiUrl + '/api/v2/paymentProcessors/' + this.get('currentPaymentProcessor.id'),
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(paymentProcessor),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function (resp) {
          _this.set('createCompleted', true);
          _this.set('credentialObjects', []);
          _this.set('credentials', []);

          later(function () {
            _this.set('createCompleted', false);
            _this.sendAction('cancelModify');
          }, 1000);
        }, function (err) {
          _ember['default'].Logger.error('Server Error: ', err);
          _this.set('credentialObjects', []);
          _this.set('credentials', []);
        });
      },

      confirmDelete: function confirmDelete(currentPaymentProcessor) {
        this.set('confirmDelete', true);
        currentPaymentProcessor.set('deleted', true);
      },

      deleteProcessor: function deleteProcessor(currentPaymentProcessor) {
        var _this2 = this;

        this.set('confirmDelete', false);
        this.set('processorDeleted', false);

        currentPaymentProcessor.deleteRecord();
        currentPaymentProcessor.save().then(function () {
          _this2.set('processorDeleted', true);
          _this2.sendAction('cancelModify');
        })['catch'](function (err) {
          _this2.flashMessages.danger(err.message);
          currentPaymentProcessor.rollbackAttributes();
        });
      },

      cancelModify: function cancelModify() {
        this.sendAction('cancelModify');
      },

      toggleDefault: function toggleDefault() {
        this.toggleProperty('currentPaymentProcessor.isDefault');
      },

      returnNewKeyVal: function returnNewKeyVal(returnObj) {
        this.get('credentialObjects').pushObject(returnObj);
      }
    }
  });
});