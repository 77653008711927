define('web/routes/lot/pricing', ['exports', 'ember', 'web/mixins/reset-scroll'], function (exports, _ember, _webMixinsResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], {
    model: function model() {
      return _ember['default'].RSVP.hash({
        tags: this.store.query('tag', {
          lotId: this.modelFor('lot').get('id')
        })
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, this.modelFor('lot'));
      controller.set('tags', model.tags);
    },

    invalidateModel: function invalidateModel() {
      this.refresh();
    }
  });
});