define('web/components/dlx-range-slider', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['range-slider'],

    didInsertElement: function didInsertElement() {
      this._super();
      this._setupRangeSlider();
    },

    _setupRangeSlider: function _setupRangeSlider() {
      var _this = this;

      var config = this.get('config');
      var min = this.get('min');
      var max = this.get('max');

      var nonLinear = this.get('nonLinear') || null;

      var localConfig = _ember['default'].$.extend(true, {}, nonLinear, {
        min: min,
        max: max,
        start: [min, max]
      }, config);

      this.$().noUiSlider(localConfig).on('slide', function (e, vals) {
        var min = Array.isArray(vals) ? vals[0] : vals;
        var max = Array.isArray(vals) ? vals[1] : vals;

        _this.attrs.changeMinMax(min, max);
      });
    }
  });
});