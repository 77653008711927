define('web/controllers/permit/details', ['exports', 'ember', 'web/mixins/record-editor'], function (exports, _ember, _webMixinsRecordEditor) {
  var alias = _ember['default'].computed.alias;
  var next = _ember['default'].run.next;
  var $ = _ember['default'].$;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: alias('model.id'),
    recordName: 'permit',
    recordModel: 'permit',
    recordTransitionTo: 'permits',

    showDialog: false,
    dialogEvent: null,

    validTimeFrom: computed("model.validFrom", {
      get: function get() {
        return moment(this.get("model.validFrom")).toDate();
      },
      set: function set(key, newVal) {
        return newVal;
      }
    }),
    validTimeTo: computed("model.validTo", {
      get: function get() {
        return this.get("model.validTo") ? moment(this.get("model.validTo")).toDate() : null;
      },
      set: function set(key, newVal) {
        return newVal;
      }
    }),

    minDate: moment().subtract(1, "month"),
    maxDate: moment().add(2, "y"),

    dialogId: computed('dialogEvent', function () {
      return this.get('dialogEvent.currentTarget.id');
    }),

    affiliations: ['Mavericks', 'Stars', 'Dallas Morning News', 'COC', 'Levy', 'etc.'],
    types: ['Employee', 'Team Personnel', 'Media', 'Daily Media', 'OBTW'],
    classifications: ['Full Time', 'Part Time', 'N/A'],
    subClassifications: ['M (Mavericks)', 'S (Stars)', '3 (3rd party events)'],

    phoneNumber: computed('model.phone', {
      get: function get() {
        return this.get('model.phone') || null;
      },
      set: function set(key, newVal) {
        var regex = newVal.replace(/[^0-9]/, '');
        this.set('model.phone', regex);
        return regex;
      }
    }),

    query: function query(text, selectedItems, cb, type, _ref) {
      var role = _ref.role;

      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      if (_ember['default'].isPresent(role)) {
        params.role = role;
      }

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      showDialog: function showDialog(ev) {
        var self = this;

        this.toggleProperty('showDialog');
        this.set('dialogEvent', ev);

        if (this.get('showDialog')) {
          next(function () {
            $(document).one('click', function () {
              self.set('showDialog', false);
            });
          });
        }
      },

      lotRequest: function lotRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'lot', { role: null });
      },

      lotFocused: function lotFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'lot', { role: null });
        }
      },

      operatorRequest: function operatorRequest(text, selectedItems, cb) {
        var s = selectedItems.mapBy('id');
        var r = 'operator';

        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'user', { exclude: s, role: r });
      },

      operatorFocused: function operatorFocused(curSelectedItems, cb) {
        var r = 'operator';

        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          this.query('*', null, cb, 'user', { exclude: null, role: r });
        }
      },

      toggleSelected: function toggleSelected() {
        this.toggleProperty('model.active');
      },

      changeTimeFrom: function changeTimeFrom(date) {
        if (_ember['default'].isPresent(date)) {
          var from = new Date(date);
          this.set("validTimeFrom", date);
          this.set("model.validFrom", from.toJSON());
        }
      },

      changeTimeTo: function changeTimeTo(date) {
        if (_ember['default'].isPresent(date)) {
          var to = new Date(date);
          this.set("validTimeTo", date);
          this.set("model.validTo", to.toJSON());
        }
      }
    }
  });
});