define('web/models/event', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  var _get = _ember['default'].get;
  exports['default'] = _emberData['default'].Model.extend({
    name: attr('string'),
    from: attr('utc'),
    to: attr('utc'),
    parkingFrom: attr('utc'),
    parkingTo: attr('utc'),
    hot: attr('boolean'),
    slug: attr('string'),
    live: attr('boolean'),
    flexDate: attr('boolean'),
    transient: attr('boolean'),
    flexDateText: attr('string'),
    tagId: belongsTo('tag', { async: true }),
    landmarkId: belongsTo('landmark', { async: true }),
    reservations: hasMany('reservation', { async: true }),
    eventOptions: belongsTo('event-option', { async: true }),
    externalEventId: attr('string'),
    externalOrders: attr(),
    externalTransactions: attr(),
    eventTypePath: attr('string'),

    presellVendor: attr('string'),

    lots: hasMany('lot'),
    images: hasMany('image', { async: false }),

    location: computed('landmarkId.location', function () {
      return this.get('landmarkId.location');
    }),

    date: (function () {
      var d = this.get('from');
      return moment(d).format('ddd MMM DD, YYYY @ h:mm A');
    }).property('from'),

    urlDate: (function () {
      var d = this.get('from');
      return moment(d).format('YYMMDD');
    }).property('from'),

    formatDate: (function () {
      var d = this.get('from');
      return moment(d).format('MM/DD/YY');
    }).property('from'),

    dateFrom: (function () {
      var d = this.get('from');
      return moment(d).format('MM-DD-YYYY');
    }).property('from'),

    timeFrom: (function () {
      var d = this.get('from');
      return moment(d).format('HH:mm');
    }).property('from'),

    dateTo: (function () {
      var d = this.get('to');
      return moment(d).format('MM-DD-YYYY');
    }).property('to'),

    timeTo: (function () {
      var d = this.get('to');
      return moment(d).format('HH:mm');
    }).property('to'),

    spotsSold: computed('reservations.[].spotsSold', {
      get: function get() {
        return this.get('reservations').reduce(function (sum, res) {
          return parseInt(sum) + parseInt(_get(res, 'spotsSold'));
        }, 0);
      }
    })
  });
});