define('web/transforms/utc', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Transform.extend({
    serialize: function serialize(deserialized) {
      return deserialized;
    },

    deserialize: function deserialize(serialized) {
      if (_ember['default'].isPresent(serialized)) {
        return moment(serialized).toDate();
      }
      return null;
    }
  });
});