define('web/controllers/modals/duplicate-events', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    eventController: _ember['default'].inject.controller('create.event'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),

    actions: {
      editEvent: function editEvent() {
        this.send('closeModal');
      },

      cancelCreation: function cancelCreation() {
        this.get('eventController').send('resetModel');
        this.send('closeModal');
      }
    }
  });
});