define('web/models/failed-external-transaction', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    sourceId: _emberData['default'].attr('string'),
    exteralEventId: _emberData['default'].attr('string'),
    lotId: _emberData['default'].belongsTo('lot', { async: true }),
    orderId: _emberData['default'].belongsTo('order', { async: true }),
    transactionId: _emberData['default'].belongsTo('transaction', { async: true }),
    barcode: _emberData['default'].attr('string'),
    redeemed: _emberData['default'].attr('boolean'),
    retried: _emberData['default'].attr('boolean'),
    externalData: _emberData['default'].attr(),
    externalSource: _emberData['default'].attr(),
    createdAt: _emberData['default'].attr('date'),
    updatedAt: _emberData['default'].attr('date'),
    errorMessage: _emberData['default'].attr('string'),

    tooltipId: _ember['default'].computed('id', function () {
      return 'tooltip-' + this.get('id');
    })
  });
});