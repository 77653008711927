define('web/controllers/modals/switch-portal-accounts', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var options = {
    findAllMatches: true,
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['firstName', 'lastName', 'company']
  };

  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    cookies: _ember['default'].inject.service('cookies'),
    initialOperators: [],
    operators: [],
    fuse: null,

    init: function init() {
      this.set('operators', [].concat(_toConsumableArray(this.get('currentUser.ghostingOperators'))));
      this.set('initialOperators', [].concat(_toConsumableArray(this.get('currentUser.ghostingOperators'))));
      this.set('fuse', new Fuse([].concat(_toConsumableArray(this.get('currentUser.ghostingOperators'))), options));
    },

    actions: {
      signOut: function signOut() {
        this.get('currentUser').reset();

        this.store.init();

        return this.get('session').close('parkhub').then(function (resp) {
          window.location.reload(true);

          _ember['default'].Logger.log(resp);
        }, function (err) {
          _ember['default'].Logger.log(err);
        });
      },

      changeUser: function changeUser(operator) {
        var _this = this;

        (0, _icAjax['default'])({
          method: 'PUT',
          url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/users/cookies/operators/',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify({ operatorLegacyKey: operator.id }),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function () {
          _this.send('signOut');
          _this.send('closeModal');
        });
      },

      changeSearch: function changeSearch(search) {
        if (search === "") {
          this.set('operators', this.get('initialOperators'));
          return;
        }

        var results = this.get('fuse').search(search);

        this.set('operators', results.map(function (r) {
          return r.item;
        }));
      }
    }
  });
});