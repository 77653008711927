define('web/components/user-avatar', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['user-avatar'],
    menuState: alias('target.object.isMenuOpen'),

    signOut: 'signOut',
    closeModal: 'closeModal',
    openModal: 'openModal',

    bgImage: (function () {
      var p = this.get('user.images.firstObject.hero');
      if (p) {
        return ('background-image: url(' + p + ')').htmlSafe();
      } else {
        return 'background-image: url("assets/images/default-avatar.jpg")'.htmlSafe();
      }
    }).property('user.images.firstObject.hero'),

    actions: {
      signOut: function signOut() {
        this.sendAction('signOut');
      },

      openModal: function openModal() {
        this.sendAction('openModal', 'switch-portal-accounts');
      },

      redirectToSuite: function redirectToSuite() {
        // Send through true to redirect to suite and not remove suite cookie.
        this.sendAction('signOut', true);
      }
    }
  });
});