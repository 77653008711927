define('web/adapters/payment-processor', ['exports', 'web/adapters/application'], function (exports, _webAdaptersApplication) {
  exports['default'] = _webAdaptersApplication['default'].extend({

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      delete data.paymentProcessor.deleted;

      return this.ajax(url, "POST", {
        data: data,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});