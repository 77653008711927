define('web/routes/user', ['exports', 'ember', 'web/mixins/nested-activate'], function (exports, _ember, _webMixinsNestedActivate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsNestedActivate['default'], {
    actions: {
      error: function error(_error) {
        if (_error && _error.statusCode === 403) {
          this.flashMessages.danger('You are not authorized to view this user.');
          return this.transitionTo('users');
        }
      }
    }
  });
});