define('web/controllers/create', ['exports', 'ember', 'web/helpers/proper-case'], function (exports, _ember, _webHelpersProperCase) {
  var alias = _ember['default'].computed.alias;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    currentRouteName: alias('application.currentRouteName'),

    hideButtons: computed('currentRouteName', function () {
      if (this.get('currentRouteName') === 'create.promotion' || this.get('currentRouteName') === 'create.exemption') {
        return true;
      }
    }),

    pathName: computed('currentRouteName', function () {
      var p = this.get('currentRouteName');
      var w = p.slice(p.indexOf('.') + 1).replace('-', ' ');
      return (0, _webHelpersProperCase.properCase)([w]).htmlSafe();
    })
  });
});