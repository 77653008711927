define('web/serializers/search-result', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      lots: { embedded: 'always' },
      landmarks: { embedded: 'always' },
      events: { embedded: 'always' },
      users: { embedded: 'always' }
    }
  });
});