define('web/controllers/sales', ['exports', 'ember', 'web/mixins/sales-block-mixin'], function (exports, _ember, _webMixinsSalesBlockMixin) {
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsSalesBlockMixin['default'], {
    actions: {
      transitionToReports: function transitionToReports(from, to) {
        this.transitionToRoute('reports', { queryParams: {
            dateFrom: from,
            dateTo: to,
            timezone: get(this, 'currentUser.timezone'),
            pastEvents: true
          } });
      }
    }
  });
});