define('web/mixins/type-ahead-controller', ['exports', 'ember'], function (exports, _ember) {
  exports.mixinFor = mixinFor;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var computed = _ember['default'].computed;

  var BaseMixin = _ember['default'].Mixin.create({

    _updateResults: function _updateResults(model, q, selectedItems, results, updateIdKey, userRole) {
      var _this = this;

      this.set('errorMessages', null);
      if (_ember['default'].isBlank(q)) {
        return;
      }

      var l = this.getWithDefault('limit', 5);
      var s;
      var r = userRole;
      var items = this.get(selectedItems);

      if (items.content === null) {
        items = [];
      }
      if (_ember['default'].isPresent(items)) {
        s = items.mapBy('id');
      }
      var updateId = this.incrementProperty(updateIdKey);

      this.store.query(model, { search: q, limit: l, exclude: s, role: r }).then(function (resp) {
        if (updateId <= _this.get(updateIdKey)) {
          _this.set(results, resp);
        } else {
          _ember['default'].Logger.log('request for', model, q, 'came in after future request');
        }
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', model, q, reason);
      });
    }
  });

  function mixinFor(model, selectedItems, duplicate, role) {
    var _actions, _Ember$Mixin$create;

    var updateId;
    var results;
    var search;
    var selected;
    var userRole = role;
    if (duplicate) {
      updateId = duplicate + 'UpdateId';
      results = duplicate + 'Results';
      search = duplicate + 'Search';
      selected = duplicate + 'Selecteds';
    } else {
      updateId = model + 'UpdateId';
      results = model + 'Results';
      search = model + 'Search';
      selected = model + 'Selecteds';
    }

    return _ember['default'].Mixin.create(BaseMixin, (_Ember$Mixin$create = {}, _defineProperty(_Ember$Mixin$create, selected, computed(selectedItems, {
      get: function get() {
        var items = this.get(selectedItems);
        if (items.content !== null) {
          if (_ember['default'].isArray(items)) {
            return items;
          } else if (_ember['default'].isNone(items)) {
            return [];
          }
          return [items];
        } else {
          return [];
        }
      },
      set: function set(key, newValue) {
        return this.set(selectedItems, newValue);
      }
    })), _defineProperty(_Ember$Mixin$create, results, null), _defineProperty(_Ember$Mixin$create, search, null), _defineProperty(_Ember$Mixin$create, updateId, 0), _defineProperty(_Ember$Mixin$create, model + 'UpdateResults', (function () {
      var s = this.get(search);
      if (_ember['default'].isBlank(s)) {
        return this.set(results, null);
      }

      _ember['default'].run.debounce(this, this._updateResults, model, s, selectedItems, results, updateId, userRole, 250);
    }).observes(search)), _defineProperty(_Ember$Mixin$create, duplicate + 'UpdateResults', (function () {
      var s = this.get(search);
      if (_ember['default'].isBlank(s)) {
        return this.set(results, null);
      }

      _ember['default'].run.debounce(this, this._updateResults, model, s, selectedItems, results, updateId, userRole, 250);
    }).observes(search)), _defineProperty(_Ember$Mixin$create, 'actions', (_actions = {}, _defineProperty(_actions, model + 'AddSelected', function (newItem) {
      _ember['default'].Logger.log(model + 'AddSelected');
      _ember['default'].Logger.log(newItem);
      var items = this.get(selectedItems);
      this.set(results, null);

      if (_ember['default'].isArray(items)) {
        this.get(selectedItems).pushObject(newItem);
      } else {
        this.set(selectedItems, newItem);
      }

      this.set(search, null);
    }), _defineProperty(_actions, model + 'RemoveSelected', function (removeItem) {
      _ember['default'].Logger.log(model + 'RemoveSelected');
      var items = this.get(selectedItems);
      if (_ember['default'].isArray(items)) {
        items.removeObject(removeItem);
      } else {
        this.set(selectedItems, null);
      }
    }), _defineProperty(_actions, duplicate + 'AddSelected', function (newItem) {
      _ember['default'].Logger.log(duplicate + 'AddSelected');
      _ember['default'].Logger.log(newItem);
      var items = this.get(selectedItems);
      this.set(results, null);

      if (_ember['default'].isArray(items)) {
        this.get(selectedItems).pushObject(newItem);
      } else {
        this.set(selectedItems, newItem);
      }

      this.set(search, null);
    }), _defineProperty(_actions, duplicate + 'RemoveSelected', function (removeItem) {
      _ember['default'].Logger.log(duplicate + 'RemoveSelected');
      var items = this.get(selectedItems);
      if (_ember['default'].isArray(items)) {
        items.removeObject(removeItem);
      } else {
        this.set(selectedItems, null);
      }
    }), _actions)), _Ember$Mixin$create));
  }

  exports['default'] = _ember['default'].Mixin.create({
    _updateResults: function _updateResults() {
      var _this2 = this;

      this.set('errorMessages', null);
      var q = this.get('search');
      if (_ember['default'].isBlank(q)) {
        return;
      }

      var l = this.getWithDefault('limit', 5);
      var s = this.get('selectedItems').mapBy('id');
      var r = this.get('role');
      var updateId = this.incrementProperty('curUpdate');

      this.store.query(this.get('typeAhead.for'), { search: q, limit: l, exclude: s, role: r }).then(function (resp) {
        if (updateId <= _this2.get('curUpdate')) {
          _this2.set('results', resp);
        } else {
          _ember['default'].Logger.log('request for', q, 'came in after future request');
        }
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    updateResults: (function () {
      _ember['default'].run.debounce(this, this._updateResults, 250);

      if (_ember['default'].isBlank(this.get('search'))) {
        this.set('results', null);
      }
    }).observes('search'),

    actions: {
      addResult: function addResult(result) {
        this.set('results', null);
        this.get('selectedItems').pushObject(result);
        this.set('search', null);
      },
      removeSelected: function removeSelected(result) {
        this.get('selectedItems').removeObject(result);
      }
    }
  });
});