define('web/routes/permits', ['exports', 'ember', 'moment', 'web/mixins/reset-scroll', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/build-params'], function (exports, _ember, _moment, _webMixinsResetScroll, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordsSearch.RouteSearchMixin, _webMixinsResetScroll['default'], _webMixinsLoadingModel.RouteLoadingMixin, _webMixinsLoadMore.RouteLoadMore, {
    queryParams: {
      limit: { refreshModel: true },
      offset: { refreshModel: true },
      search: { refreshModel: true }
    },

    model: function model(params) {
      delete params.getActive;

      return this.store.query('permit', (0, _webMixinsBuildParams.filterParams)(params));
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});