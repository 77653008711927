define('web/mixins/set-lat-lon-controller', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {

  var m = _webConfigEnvironment['default'].mapbox;
  var mapboxPlaces = 'https://api.tiles.mapbox.com/geocoding/v5/mapbox.places/';
  var types = 'address,place,postcode';
  var tokenString = '.json?types=' + types + '&access_token=' + m.accessToken;

  exports['default'] = _ember['default'].Mixin.create({

    _setLatLong: function _setLatLong(zip) {
      var _this = this;

      var address = (this.get('model.address1') || '') + ' ' + this.get('model.city') + ' ' + zip;
      if (zip.length === 5) {
        if (address) {
          (0, _icAjax['default'])(mapboxPlaces + address + tokenString).then(function (resp) {
            if (resp.features[0]) {
              _this.set('model.location', _ember['default'].Object.create({
                lon: resp.features[0].center[0],
                lat: resp.features[0].center[1]
              }));

              var state = undefined;
              resp.features[0].context.forEach(function (context) {
                if (context.id.slice(0, 6) === 'region') {
                  state = context.text;
                }
              });
              _this.set('model.state', _this.get('states')[state]);
            }
          });
        }
      }
    }

  });
});