define('web/mixins/load-more', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var ControllerLoadMore = _ember['default'].Mixin.create({
    limit: 20,
    offset: 0,
    recordModel: computed('modelName', 'model', function () {
      return this.get('modelName') ? this.get(this.get('modelName')) : this.get('model');
    }),

    offsetExists: computed('offset', function () {
      return parseInt(this.get('offset')) >= 100 ? true : false;
    }),

    offsetLength: computed('offset', 'recordModel.length', function () {
      return parseInt(this.get('offset') || 0) + parseInt(this.get('recordModel.length')) || 0;
    }),

    canLoadMore: computed('recordModel.length', 'offset', 'limit', function () {
      return this.get('recordModel.length') + this.get('offset') >= this.get('offset') + this.get('limit');
    }),

    actions: {
      loadMore: function loadMore() {
        var currentLimit = this.get('limit');
        var newLimit = parseInt(currentLimit) + 20;
        var currentOffset = this.get('offset');
        var canLoadMore = this.get('canLoadMore');

        if (canLoadMore) {
          if (currentLimit) {
            if (parseInt(currentLimit) === 100) {
              this.send('backToTopHard');
              this.set('limit', 20);
              if (currentOffset) {
                this.set('offset', parseInt(currentOffset) + 100);
              } else {
                this.set('offset', 100);
              }
            } else {
              this.set('limit', newLimit);
            }
          } else {
            this.set('limit', 20);
          }
        }
      },

      backToTop: function backToTop() {
        _ember['default'].$('html, body').animate({
          scrollTop: 0
        }, 500);
      },

      backToTopHard: function backToTopHard() {
        _ember['default'].$('html, body').scrollTop(0);
      },

      previousHundred: function previousHundred() {
        var currentOffset = this.get('offset');
        this.set('offset', parseInt(currentOffset) - 100);
      }
    }
  });

  exports.ControllerLoadMore = ControllerLoadMore;
  var RouteLoadMore = _ember['default'].Mixin.create({
    queryParams: {
      offset: {
        refreshModel: true
      },
      limit: {
        refreshModel: true
      }
    },

    _resetProps: (function () {
      this.controller.setProperties({
        limit: 20,
        offset: 0
      });
    }).on('deactivate')
  });
  exports.RouteLoadMore = RouteLoadMore;
});