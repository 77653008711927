define("web/models/user", ["exports", "ember-data", "ember"], function (exports, _emberData, _ember) {
  var attr = _emberData["default"].attr;
  var belongsTo = _emberData["default"].belongsTo;
  var hasMany = _emberData["default"].hasMany;
  var equal = _ember["default"].computed.equal;
  exports["default"] = _emberData["default"].Model.extend({
    facebookId: attr("string"),
    email: attr("string"),
    username: attr("string"),
    role: attr("string"),
    firstName: attr("string"),
    lastName: attr("string"),
    companyName: attr("string"),
    password: attr("string"),
    lastLogin: attr("utc"),
    operatorId: belongsTo("user", { async: true }),
    status: attr("string"),
    token: attr("string"),
    customerId: attr("number"),
    companyLogo: attr("string"),
    logoData: attr("file"),
    logoType: attr("string"),
    taxOptionAvailable: attr("boolean"),
    landmarkIds: hasMany("landmark", { async: true }),
    userOptions: belongsTo("user-option", { async: true }),
    images: hasMany("image", { async: true }),
    isPending: equal("status", "pending"),

    name: (function () {
      return this.get("firstName") + " " + this.get("lastName");
    }).property("firstName", "lastName"),

    ignoreDeepDirty: ["operatorId"]
  });
});