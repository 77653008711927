define('web/routes/home', ['exports', 'ember', 'web/config/environment'], function (exports, _ember, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Route.extend({

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('showLoginForm', _webConfigEnvironment['default'].localLogin);

      var username = localStorage.getItem('username');
      if (_ember['default'].isPresent(username)) {
        controller.setProperties({
          email: username,
          rememberMe: true
        });
      }
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
        return true;
      }
    }
  });
});