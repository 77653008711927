define('web/models/parkhub-transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    transactionId: attr('string'),
    cartId: attr('string'),
    refund: attr('boolean'),
    ticket: attr(),
    createdAt: attr('date'),
    updatedAt: attr('date')
  });
});