define('web/initializers/ember-cli-uuid', ['exports', 'ember', 'ember-data', 'ember-cli-uuid', 'web/config/environment', 'ember-cli-uuid/configuration'], function (exports, _ember, _emberData, _emberCliUuid, _webConfigEnvironment, _emberCliUuidConfiguration) {
  exports['default'] = {

    name: 'ember-cli-uuid',

    initialize: function initialize() {

      var config = _webConfigEnvironment['default']['ember-cli-uuid'] || {};
      _emberCliUuidConfiguration['default'].load(config);

      _emberData['default'].Adapter.reopen({

        generateIdForRecord: function generateIdForRecord() {
          return _emberCliUuidConfiguration['default'].defaultUUID ? (0, _emberCliUuid.uuid)() : null;
        }

      });
    }

  };
});