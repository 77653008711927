define('web/routes/tag-pricings', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/build-params'], function (exports, _ember, _webMixinsResetScroll, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordsSearch.RouteSearchMixin, _webMixinsResetScroll['default'], _webMixinsLoadingModel.RouteLoadingMixin, _webMixinsLoadMore.RouteLoadMore, {
    queryParams: {
      limit: { refreshModel: true },
      offset: { refreshModel: true },
      search: { refreshModel: true },
      getActive: { refreshModel: true }
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        tags: this.store.query('tag', (0, _webMixinsBuildParams.filterParams)(params)),
        pricings: this.store.query('pricing', { userDefault: true })
      });
    }
  });
});