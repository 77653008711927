define('web/components/select-menu', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    content: null,
    selectedValue: null,
    required: null,
    selectId: null,
    disabled: null,
    classNames: ['select-menu'],
    attributeBindings: ['style:style'],

    style: computed('width', function () {
      return get(this, 'width') ? ('width: ' + get(this, 'width') + ';').htmlSafe() : 'width: 100%;'.htmlSafe();
    }),

    actions: {
      changed: function changed(event) {
        var index = event.target.selectedIndex;
        var options = this.get('content');
        var indexPrompt = this.get('prompt') ? index - 1 : index;
        var item = options ? options[indexPrompt] : null;

        if (item === undefined) {
          item = options.objectAt(indexPrompt);
        }

        // checks if item selected is the prompt, if it is, it will set selectedValue to null
        if (item !== undefined) {
          this.set('selectedValue', item);
        } else {
          item = null;
        }

        this.attrs.changeSelectProp(item);
      }
    }
  });
});