define('web/components/image-input', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['bgImage:style'],
    classNames: ['image-uploader'],
    classNameBindings: ['backstretchImg:has-image:no-image', 'roundImage:round'],

    newImageData: null,
    isLoading: false,
    roundImage: computed.equal('round', 'round'),

    bgImage: computed('backstretchImg', {
      get: function get() {
        if (_ember['default'].isPresent(this.get('backstretchImg'))) {
          return ('background-image: url(' + this.get('backstretchImg') + ')').htmlSafe();
        }
        return ''.htmlSafe();
      },
      set: function set(k, newBg) {
        return newBg;
      }
    }),

    input: (function () {
      return this.$('input')[0];
    }).property(),

    readFile: function readFile() {
      var i = this.get('input');
      var self = this;

      if (i.files && i.files[0]) {
        var r = new FileReader();

        r.onloadstart = function () {
          self.set('isLoading', true);
        };

        r.onload = function (e) {
          _ember['default'].run(self, self.set, 'bgImage', ('background-image: url(' + e.target.result + ')').htmlSafe());
        };

        r.readAsDataURL(i.files[0]);
      }
    },

    actions: {
      newImageData: function newImageData(data) {
        var _this = this;

        var fileToUpload = document.getElementById('image-input-' + this.get('elementId')).files[0];

        this.setProperties({
          newImageData: data,
          isLoading: true
        });

        this.readFile();

        this.attrs.selectImage(fileToUpload, function (asyncResp) {
          run.later(function () {
            _this.setProperties({
              isLoading: false,
              imageData: null,
              newImageData: null
            });
          }, 1000);
        });
      }
    }
  });
});