define('web/controllers/home-content/details', ['exports', 'ember', 'web/mixins/record-editor', 'web/mixins/image-uploader-controller'], function (exports, _ember, _webMixinsRecordEditor, _webMixinsImageUploaderController) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], _webMixinsImageUploaderController['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: _ember['default'].computed.alias('model.id'),
    recordName: 'promotion',
    recordTransitionTo: 'home-content',
    recordModel: 'promotion',
    recordIsUpdated: false,

    // ------------------- For Image Uploader Controller mixin
    imgKeyOnModel: 'icon',
    dataKeyOnModel: 'iconData',
    // -------------------

    link: computed('model.link', {
      get: function get() {
        return this.get('model.link');
      },
      set: function set(key, newVal) {
        var n = newVal;
        if (n.search(/^http[s]?\:\/\//) === -1) {
          n = 'http://' + n;
        }
        this.set('model.link', n);
        return n;
      }
    })

  });
});