define('web/components/ph-testimonial', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['testimonial'],
    openModal: 'openModal',

    bgImage: (function () {
      var p = this.get('testimonial.images.firstObject.hero');
      if (p) {
        return 'background-image: url(' + p + ')';
      } else {
        return 'background-image: url(assets/images/placeholder-md.jpg)';
      }
    }).property('testimonial.images'),

    actions: {
      openModal: function openModal() {
        this.sendAction('openModal', 'testimonial', this.get('testimonial'));
      }
    }
  });
});