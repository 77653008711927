define('web/components/ph-table', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'table',
    classNames: ['table'],
    classNameBindings: ['class'],

    actions: {
      sortCol: function sortCol(col) {
        if (this.attrs.sortCol) {
          this.attrs.sortCol(col);
        }
      }
    }
  });
});