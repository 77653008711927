define('web/models/order', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var hasMany = _emberData['default'].hasMany;
  exports['default'] = _emberData['default'].Model.extend({
    user: belongsTo('user'),
    total: attr('number'),
    qrCode: attr('string'),
    type: attr('string'),
    status: attr('string'),
    parkingSpots: attr('object'),
    enabled: attr('boolean'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    lotName: attr('string'),
    employeeFirstName: attr('string'),
    employeeLastName: attr('string'),

    acctId: attr('string'),
    customerName: attr('string'),
    externalOrders: attr(),
    externalTransactions: attr(),
    primeOrders: hasMany('prime-order'),
    permit: attr(),

    cardType: attr('string'),
    lastFour: attr('string'),
    expiry: attr('string'),
    cardholderName: attr('string'),

    exemption: attr('string'),

    trimId: (function () {
      var id = this.get('id');
      return id.slice(-4);
    }).property('id'),

    transactionType: (function () {
      if (this.get('primeOrders')) {
        return this.get('primeOrders').mapBy('transactionType').pop();
      }
    }).property('primeOrders'),

    transactionTime: (function () {
      if (this.get('primeOrders')) {
        return moment(this.get('primeOrders').mapBy('transactionTime').pop());
      }
    }).property('primeOrders'),

    employeeName: (function () {
      return this.get('employeeFirstName') + ' ' + this.get('employeeLastName');
    }).property('employeeFirstName', 'employeeLastName'),

    parkingSpotName: (function () {
      return this.get('parkingSpots').mapBy('name').pop();
    }).property('parkingSpots')
  });
});