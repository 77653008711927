define("web/components/es-pagination", ["exports", "ember"], function (exports, _ember) {
  var computed = _ember["default"].computed;

  var totalResults = undefined;

  exports["default"] = _ember["default"].Component.extend({
    totalPages: ("totalResults", "limit", "currentPage", function () {
      if (!totalResults || this.get("currentPage") === 1) {
        totalResults = this.get("totalResults");
      }

      this.set("totalResults", totalResults);

      return Math.ceil(this.get("totalResults") / this.get("limit"));
    }),

    pages: computed("totalPages", function () {
      var collection = _ember["default"].A();

      for (var i = 1; i < this.get("totalPages") + 1; i++) {
        var obj = _ember["default"].Object.create({
          number: i,
          offset: this.get("limit") * (i - 1),
          currentPage: i
        });

        collection.pushObject(obj);
      }

      return collection;
    }),

    actions: {
      previousPage: function previousPage() {
        var previousPage = this.get("currentPage") - 1;
        var params = undefined;

        if (previousPage <= 0) {
          params = { offset: 0, currentPage: 1 };
        } else {
          params = this.get("pages").find(function (item) {
            if (item.number === previousPage) {
              return item;
            }
          });
        }

        this.send("setQueryParams", params);
      },

      nextPage: function nextPage() {
        if (this.get("currentPage") === 0) {
          this.set("currentPage", 1);
        }

        var nextPage = this.get("currentPage") + 1;

        if (nextPage > this.get("totalPages")) {
          return false;
        }

        var params = this.get("pages").find(function (item) {
          if (item.number === nextPage) {
            return item;
          }
        });

        this.send("setQueryParams", params);
      },

      setQueryParams: function setQueryParams(params) {
        this.attrs.setQueryParams(params);
      }
    }
  });
});