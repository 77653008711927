define('web/mixins/sales-block-mixin', ['exports', 'ember'], function (exports, _ember) {
  var run = _ember['default'].run;

  var dir = function dir(direction, duration, range, date) {
    if (direction === 'add') {
      return moment(date).add(duration, range);
    } else if (direction === 'subtract') {
      return moment(date).subtract(duration, range);
    }
  };

  exports['default'] = _ember['default'].Mixin.create({
    currentDate: null,

    _queryReports: function _queryReports(start, range, type, direction) {
      var _this = this;

      this.store.query('report', { start: start, range: range }).then(function (resp) {
        _this.set('model.reports', resp);

        switch (type) {
          case 'toggle':
            if (direction) {
              (function () {
                var whichDir = direction === 'add' ? 'isNewRight' : 'isNewLeft';
                _this.get('model.reports').forEach(function (rep) {
                  return rep.set(whichDir, true);
                });
              })();
            }

            _this.set('currentDate', start);
            break;
          case 'changeView':
            if (range === 'month') {
              var roundDownDate = moment(resp.get('lastObject.localDate')).add(12, 'h').startOf('month').toJSON();
              _this.set('currentDate', roundDownDate);
            }
            break;
          case 'today':
            _this.set('currentDate', start);
            break;
        }
      });
    },

    actions: {
      toggle: function toggle(direction, data) {
        var newDate,
            dateToRound,
            currentRange = data.get('firstObject.range');

        if (!this.get('currentDate')) {
          switch (currentRange) {
            case 'week':
              newDate = dir(direction, 1, 'd');
              break;
            case 'month':
              dateToRound = dir(direction, 1, 'M');
              newDate = dateToRound.startOf('month');
              break;
          }
        } else {
          switch (currentRange) {
            case 'week':
              newDate = dir(direction, 1, 'd', this.get('currentDate'));
              break;
            case 'month':
              dateToRound = dir(direction, 1, 'M', this.get('currentDate'));
              newDate = dateToRound.startOf('month');
              break;
          }
        }

        run(this, this._queryReports, newDate.toJSON(), currentRange, 'toggle', direction);
      },

      changeView: function changeView(e) {
        var lastWindowReport = this.get('model.reports').get('lastObject');
        var lastDate = lastWindowReport.get('localDate');

        run(this, this._queryReports, lastDate, e, 'changeView');
      },

      goToToday: function goToToday() {
        var today = moment().toJSON();
        run(this, this._queryReports, today, 'week', 'today');
      }
    }
  });
});