define('web/routes/create/promotion', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['title', 'subtitle', 'link', 'buttonText'],

    model: function model() {
      return {};
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        errorMessages: null
      });
    }
  });
});