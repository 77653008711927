define('web/app', ['exports', 'ember', 'web/resolver', 'ember-load-initializers', 'web/config/environment'], function (exports, _ember, _webResolver, _emberLoadInitializers, _webConfigEnvironment) {

  var App = undefined;

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  _ember['default'].warn = function () {};
  _ember['default'].deprecate = function () {};

  App = _ember['default'].Application.extend({
    modulePrefix: _webConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _webConfigEnvironment['default'].podModulePrefix,
    Resolver: _webResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _webConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});