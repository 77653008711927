define('web/controllers/events/list', ['exports', 'ember', 'web/mixins/loading-model', 'web/mixins/load-more', 'web/mixins/records-search'], function (exports, _ember, _webMixinsLoadingModel, _webMixinsLoadMore, _webMixinsRecordsSearch) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadMore.ControllerLoadMore, {
    recordName: 'event',

    queryParams: ['limit', 'offset', 'search'],

    eventCols: [{ colName: 'Hot' }, { colName: 'Date / Time' }, { colName: 'Event' }, {}],

    actions: {
      eventTransition: function eventTransition(eventId) {
        this.transitionToRoute('event', eventId);
      }
    }
  });
});