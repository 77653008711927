define('web/serializers/authenticated-app', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    attrs: {
      secret: {
        serialize: false
      }
    },
    serialize: function serialize(authenticatedApp, options) {
      var t = this._super(authenticatedApp, options);

      return Object.keys(t).reduce(function (obj, k) {
        if (obj[k] === null) {
          obj[k] = undefined;
        }
        return obj;
      }, t);
    }

  });
});