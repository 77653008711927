define('web/serializers/event', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    shouldReloadAll: function shouldReloadAll() {
      return true;
    },

    attrs: {
      lots: { serialize: false },
      images: { serialize: false, deserialize: 'records' },
      reservations: { deserialize: 'records', serialize: false },
      eventOptions: { deserialize: 'records', serialize: false }
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.changedAttributes()[key] || snapshot.record.get('isNew')) {
        //TODO: is new && occurs in payload (booleans pass)

        var t = this._super(snapshot, json, key, attributes);

        Object.keys(json).forEach(function (k) {
          if (json[k] === null) {
            json[k] = undefined;
          }
        });

        return t;
      } else {
        return;
      }
    },

    serialize: function serialize(snapshot, options) {
      var allowNull = ['landmarkId'];
      var t = this._super(snapshot, options);

      Object.keys(t).forEach(function (k) {
        if (t[k] === null && !allowNull.contains(k)) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});