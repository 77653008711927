define('web/components/dlx-img', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'img',
    attributeBindings: ['useSrc:src', 'alt'],

    src: _ember['default'].computed.oneWay('fallback'),

    _reset: (function () {
      this.set('useFallback', false);
    }).observes('src'),

    didRender: function didRender() {
      var _this = this;

      this._super();
      this.$(this.element).on('error', function () {
        _this.set('useFallback', true);
      });
    },

    useSrc: (function () {
      return this.get('useFallback') ? this.get('fallback') : this.get('src');
    }).property('useFallback', 'src', 'fallback'),

    click: function click() {
      this.sendAction();
    }
  });
});