define('web/controllers/create/event', ['exports', 'ember', 'ember-data', 'web/mixins/time-options', 'web/helpers/slugify'], function (exports, _ember, _emberData, _webMixinsTimeOptions, _webHelpersSlugify) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend( /* ControllerRecordCreate, */_webMixinsTimeOptions['default'], {
    searches: ['landmark'],
    duplicateEventsModal: _ember['default'].inject.controller('modals.duplicate-events'),
    unauthorizedVendorModal: _ember['default'].inject.controller('modals.unauthorized-vendor'),
    selectedMetatag: 'default',

    /* determine available metatags to show in front end. */
    availableMetatagLabels: computed('metatags', {
      get: function get() {
        /* get metatag objects from event.js route */
        var metatagObject = this.get('metatags');

        /* ensure metatag service is running */
        if (metatagObject.children.length === 0) {
          /* show a pop up if not */
          this.flashMessages.danger('The event type failed to populate. Please contact Parkhub Support.');
          return [];
        }
        /* get an array of labels from the objects */
        var metatagLabels = metatagObject.children.map(function (response) {
          return response.displayName;
        });
        return metatagLabels;
      },
      set: function set(k, newMetatag) {
        this.set('model.selectedMetatag', newMetatag);
        return newMetatag;
      }
    }),

    todaysDate: moment(),
    maxDate: moment().add(2, 'y'),
    minTimeFrom: moment(),
    timeFrom: null,
    timeTo: null,
    parkingTimeFrom: null,
    parkingTimeTo: null,
    existingEvent: {},
    monitoredMaxDate: computed('currentUser.role', {
      get: function get() {
        if (this.get('currentUser.role') === 'admin') {
          return moment().add(2, 'y');
        }
        return moment();
      }
    }),

    flexDate: computed('model.flexDate', {
      get: function get() {
        return this.get('model.flexDate');
      },
      set: function set(key, newVal) {
        this.set('model.flexDate', newVal);
        return newVal;
      }
    }),

    flexDateText: computed('model.flexDateText', {
      get: function get() {
        return this.get('model.flexDateText');
      },
      set: function set(key, newVal) {
        this.set('model.flexDateText', newVal);
        return newVal;
      }
    }),

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions = undefined;

      var params = { search: q, limit: l };

      if (type === 'landmark') {
        params.dBSearch = true;
      }

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;

        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      create: function create() {
        var _this = this;

        /* Get the selected label and available metatags */
        var metatagObject = this.get('model.metatags');

        if (metatagObject.children.length != 0) {
          var _ret = (function () {
            // make sure metatag service isn't down
            var metatags = metatagObject.children;
            var selectedMetatag = _this.get('model.selectedMetatag');

            /* If the metatag service is up, selectedMetatag is required. */
            if (!_this.get('model.selectedMetatag')) {
              _this.flashMessages.danger('Event type is required.');
              return {
                v: false
              };
            }

            if (!_this.get('model.landmarkId')) {
              _this.flashMessages.danger('You must select a landmark for your event.');
              return {
                v: false
              };
            }

            /* Find the metatag path that matches the displayName chosen */
            var metatagPath = metatags.find(function (element) {
              return element.displayName === selectedMetatag;
            });

            /* Make sure the metatag path is defined, otherwise it will throw an error */
            if (metatagPath) {
              metatagPath = metatagPath.path;
              _this.set('model.eventTypePath', metatagPath);
            } else {
              /* Set type to null if metatag doesn't populate */
              _this.set('model.eventTypePath', 'none');
            }
          })();

          if (typeof _ret === 'object') return _ret.v;
        }

        if (!this.get('model.tagId')) {
          this.flashMessages.danger('You must select a tag for your event.');
          return false;
        }

        // Set the event to live before creating it.
        this.set('model.live', true);

        this.store.createRecord('event', this.get('model')).save().then(function (event) {
          _this.transitionToRoute('event', event.get('id'));
        }, function (err) {
          _this.flashMessages.danger(err.message);
        });
      },

      checkDateForEvent: function checkDateForEvent() {
        var _this2 = this;

        return this.store.query('event', {
          fromLandmark: this.get('model.landmarkId.id'),
          dateFrom: this.get('model.dateFrom'),
          search: this.get('model.name')
        }).then(function (resp) {
          // Go ahead and create the event because there arents any conflicting events.
          if (resp.get('length') === 0) {
            _this2.send('create');
          } else {
            // Configure messaging for conflicting events.
            _this2.set('existingEvent', resp.get('firstObject'));

            _this2.send('openDuplicateEventModal');
          }
        });
      },

      openDuplicateEventModal: function openDuplicateEventModal() {
        this.get('duplicateEventsModal').setProperties({
          model: this.get('model'),
          existingEvent: this.get('existingEvent')
        });

        this.send('openModal', 'duplicate-events');
      },

      resetModel: function resetModel() {
        this.set('model', {});
        this.set('existingEvent', {});
      },

      changeFlexDate: function changeFlexDate() {
        this.toggleProperty('flexDate');
      },

      changeTransientEvent: function changeTransientEvent() {
        this.toggleProperty('model.transient');
      },

      changeName: function changeName(name) {
        var slugged = (0, _webHelpersSlugify.slugify)(name);
        this.set('model.slug', slugged);
        this.set('model.name', name);
      },

      changeSlug: function changeSlug(slug) {
        this.set('model.slug', (0, _webHelpersSlugify.slugify)(slug));
      },

      changeTimeFrom: function changeTimeFrom(date) {
        if (_ember['default'].isPresent(date)) {
          if (moment(date).isSame(moment(), 'day')) {
            this.set('minTimeFrom', moment());
          } else {
            this.set('minTimeFrom', false);
          }

          var from = new Date(date);
          var parkingFrom = moment(new Date(date)).subtract(2, 'h');
          var timeTo = moment(new Date(date)).add(3, 'h');

          this.set('timeFrom', date);
          this.set('model.from', from.toJSON());
          this.set('parkingTimeFrom', parkingFrom.toDate());
          this.set('model.parkingFrom', parkingFrom.toJSON());

          this.set('timeTo', timeTo.toDate());
          this.set('model.to', timeTo.toJSON());
          this.set('parkingTimeTo', timeTo.toDate());
          this.set('model.parkingTo', timeTo.toJSON());
        }
      },

      changeTimeTo: function changeTimeTo(date) {
        if (_ember['default'].isPresent(date)) {
          this.set('timeTo', date);
          this.set('model.to', new Date(date).toJSON());
          this.set('parkingTimeTo', date);
          this.set('model.parkingTo', new Date(date).toJSON());
        }
      },

      changeParkingTimeFrom: function changeParkingTimeFrom(date) {
        if (_ember['default'].isPresent(date)) {
          this.set('parkingTimeFrom', date);
          this.set('model.parkingFrom', new Date(date).toJSON());
        }
      },

      changeParkingTimeTo: function changeParkingTimeTo(date) {
        if (_ember['default'].isPresent(date)) {
          this.set('parkingTimeTo', date);
          this.set('model.parkingTo', new Date(date).toJSON());
        }
      },

      selectImage: function selectImage(data) {
        this.set('image', data);
      },

      tagRequest: function tagRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }

        if (this.get('currentUser.role') === 'admin') {
          return this.query(text, selectedItems, cb, 'tag');
        }
        return this.store.query('tag', { search: text, limit: 100 }).then(function (resp) {
          cb(resp);
        });
      },

      tagSelected: function tagSelected(item, cb) {
        var _this3 = this;

        if (_ember['default'].isPresent(get(item, 'landmarkId.content'))) {
          return _emberData['default'].PromiseObject.create({
            promise: this.store.findRecord('landmark', get(item, 'landmarkId.id'))
          }).then(function (lm) {
            set(_this3, 'model.landmarkId', lm);
            cb(item);
          });
        }
        cb(item);
      },

      tagFocused: function tagFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          if (this.get('currentUser.role') === 'admin') {
            return this.query('*', null, cb, 'tag');
          }
          return this.store.query('tag', { limit: 100 }).then(function (resp) {
            cb(resp);
          });
        }
      },

      landmarkRequest: function landmarkRequest(text, selectedItems, cb) {
        var _this4 = this;

        if (_ember['default'].isBlank(text)) {
          return;
        }

        if (this.get('currentUser.role') === 'admin') {
          return this.query(text, selectedItems, cb, 'landmark');
        }
        return Promise.all(this.get('currentUser.landmarkIds').map(function (landmarkId) {
          return _this4.store.findRecord('landmark', landmarkId);
        })).then(function (resp) {
          cb(resp);
        });
      },

      landmarkFocused: function landmarkFocused(curSelectedItems, cb) {
        var _this5 = this;

        if (_ember['default'].isBlank(curSelectedItems)) {
          if (this.get('currentUser.role') === 'admin') {
            return this.query('*', null, cb, 'landmark');
          }
          return Promise.all(this.get('currentUser.landmarkIds').map(function (landmarkId) {
            return _this5.store.findRecord('landmark', landmarkId);
          })).then(function (resp) {
            cb(resp);
          });
        }
      },

      landmarkSelected: function landmarkSelected(landmark, cb) {
        cb(landmark);
      },

      landmarkRemoved: function landmarkRemoved(landmark, cb) {
        cb(landmark);
      },

      changeChecked: function changeChecked(newVal, checked) {
        if (checked) {
          return this.set('model.presellVendor', 'stubhub');
        }
        this.set('model.presellVendor', null);
      },

      openUnathorizedVendorModal: function openUnathorizedVendorModal(err) {
        var stubErr = 'StubHub is not an approved presell vendor for this location. Please revise your event criteria in order to create this event.';
        if (err.message === stubErr) {
          this.send('openModal', 'unauthorized-vendor');
        }
      }
    }
  });
});

// import { ControllerRecordCreate } from "../../mixins/record-create";