define('web/controllers/modals/manual-transfer', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller('application'),
    isModalOpen: alias('application.isModalOpen'),

    actions: {
      transfer: function transfer(model) {
        var deposit = _ember['default'].Object.create({
          deposit: {
            amount: model.get('balance')
          }
        });

        (0, _icAjax['default'])({
          method: 'POST',
          url: _webConfigEnvironment['default'].apiUrl + '/api/v2/deposits',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(deposit),
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }).then(function (resp) {});
      }
    }
  });
});