define('web/mixins/generate-occurrences', ['exports', 'ember'], function (exports, _ember) {
  exports.generateOccurrences = generateOccurrences;
  var get = _ember['default'].get;
  var set = _ember['default'].set;

  function generateOccurrences(model, fromProp, toProp) {
    var multipleDayEvents = [];

    var singleDayEvents = model.map(function (ev) {
      var starts = get(ev, fromProp);
      var ends = get(ev, toProp);
      var currentMultiples = [];

      var howManyDays = moment(ends).diff(moment(starts), 'days') + 1;

      if (howManyDays > 1) {
        var i = 0;

        while (i < howManyDays) {
          i++;
          currentMultiples.pushObject(_ember['default'].Object.create({
            dupId: get(ev, 'id'),
            title: get(ev, 'name'),
            content: ev,
            startsAt: moment(starts).add(i - 1, 'd'),
            endsAt: moment(starts).add(i - 1, 'd')
          }));
        }

        currentMultiples.forEach(function (multiple) {
          set(multiple, 'startsAt', get(multiple, 'startsAt').startOf('day').add(6, 'h').toDate());
          set(multiple, 'endsAt', get(multiple, 'endsAt').endOf('day').toDate());
        });

        set(currentMultiples.get('firstObject'), 'startsAt', get(ev, fromProp));
        set(currentMultiples.get('lastObject'), 'endsAt', get(ev, toProp));

        currentMultiples.forEach(function (m, i) {
          set(m, 'dupId', get(m, 'dupId') + '-' + i);
          multipleDayEvents.pushObject(m);
        });

        return;
      } else {
        return _ember['default'].Object.create({
          dupId: get(ev, 'id'),
          title: get(ev, 'name'),
          content: ev,
          startsAt: starts,
          endsAt: ends
        });
      }
    });

    var allOccurrences = singleDayEvents.concat(multipleDayEvents);
    return allOccurrences.filter(function (o) {
      return _ember['default'].isPresent(o);
    });
  }
});