define('web/components/processor-key-object', ['exports', 'ember'], function (exports, _ember) {
  var equal = _ember['default'].computed.equal;
  var debounce = _ember['default'].run.debounce;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['processor-input'],
    attributeBindings: ['marginBottom:style'],

    isCluster: equal('type', 'cluster'),
    isForm: equal('type', 'form'),
    returnNewKeyVal: 'returnNewKeyVal',

    passwordInput: (function () {
      if (this.get('key')[0] === 'password') {
        return true;
      }
    }).property('key', 'keyObj'),

    marginBottom: (function () {
      if (this.get('isForm')) {
        return 'margin-bottom: -0.5em'.htmlSafe();
      }
    }).property('keyObj'),

    key: (function () {
      return _ember['default'].$.map(this.get('keyObj'), function (value, key) {
        return key;
      });
    }).property('keyObj'),

    value: (function () {
      return _ember['default'].$.map(this.get('keyObj'), function (value) {
        return value;
      });
    }).property('keyObj'),

    actions: {
      returnKeyVal: function returnKeyVal() {
        var self = this;

        var key = this.get('key');
        var val = this.get('value');
        var returnObj = {};

        returnObj[key] = val;

        debounce(function () {
          self.sendAction('returnNewKeyVal', returnObj);
        }, 500);
      }
    }
  });
});