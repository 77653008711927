define('web/controllers/modals/modify-amenity', ['exports', 'ember', 'web/mixins/image-uploader-controller'], function (exports, _ember, _webMixinsImageUploaderController) {
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var later = _ember['default'].run.later;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsImageUploaderController['default'], {
    recordName: 'tag',
    application: _ember['default'].inject.controller('application'),
    isModalOpen: _ember['default'].computed.alias('application.isModalOpen'),
    deleteAmenityTriggered: false,

    image: null,

    curModel: computed('model', {
      get: function get() {
        return this.get('model') || {};
      },

      set: function set(k, newVal) {
        return newVal;
      }
    }),

    name: computed('curModel', 'curModel.name', {
      get: function get() {
        return this.get('curModel.name');
      },
      set: function set(key, newVal) {
        this.set('curModel.name', newVal);
        return newVal;
      }
    }),

    _uploadImages: function _uploadImages(image, newRec, asyncCallback, cb) {
      var img = this.store.createRecord('image', {
        imageData: image,
        recordId: newRec.get('id'),
        modelName: newRec.get('constructor.modelName')
      });

      var promise = img.save();

      asyncCallback(promise);

      promise.then(function (resp) {
        return cb(resp);
      })['catch'](function (err) {
        return cb(err);
      });
    },

    _saveValidate: function _saveValidate(amenity) {
      var _this = this;

      later(function () {
        _this.store.unloadRecord(amenity);
        _this.send('closeModifyAmenity');
        _this.send('invalidateModel');
      }, 1000);
    },

    actions: {
      closeModifyAmenity: function closeModifyAmenity() {
        if (this.get('curModel.hasDirtyAttributes')) {
          this.get('curModel').rollbackAttributes();
        } else {
          this.set('curModel', {});
        }

        this.send('closeModal');
      },

      selectCreateImage: function selectCreateImage(data) {
        set(this, 'image', data);
      },

      saveAmenity: function saveAmenity(callback) {
        var _this2 = this;

        var curModel = get(this, 'curModel');
        var amenity = this.store.createRecord('amenity', curModel);

        var promise = amenity.save();

        if (!_ember['default'].isPresent(get(this, 'image'))) {
          callback(promise);
        }

        promise.then(function (resp) {

          if (_ember['default'].isPresent(get(_this2, 'image'))) {
            _this2._uploadImages(get(_this2, 'image'), resp, callback, function (imgPayload) {
              if (_ember['default'].isPresent(imgPayload.statusCode) && imgPayload.statusCode > 300) {
                _this2.flashMessages.danger(imgPayload.message);
                amenity.destroyRecord();
              } else {
                _this2._saveValidate(resp);
              }
            });
          } else {
            _this2._saveValidate(resp);
          }
        })['catch'](function (err) {
          var error = err.message || err.error;
          _this2.flashMessages.danger(error);
        });
      },

      editAmenity: function editAmenity(callback) {
        var _this3 = this;

        var curModel = get(this, 'curModel');

        var promise = curModel.save();
        callback(promise);

        promise.then(function () {
          later(function () {
            _this3.send('closeModifyAmenity');
          }, 1000);
        })['catch'](function (err) {
          return _this3.flashMessages.danger(err.message);
        });
      },

      deleteAmenity: function deleteAmenity(callback) {
        var _this4 = this;

        var curModel = this.get('curModel');
        curModel.deleteRecord();

        var promise = curModel.save();
        callback(promise);

        promise.then(function (resp) {
          later(function () {
            _this4.send('closeModal');
            _this4.set('deleteAmenityTriggered', false);
          }, 1000);
        })['catch'](function (err) {
          return _this4.flashMessages.danger(err.message);
        });
      },

      toggleDeleteAmenity: function toggleDeleteAmenity() {
        this.toggleProperty('deleteAmenityTriggered');
      }
    }
  });
});