define('web/controllers/tag-pricing/details', ['exports', 'ember'], function (exports, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var computed = _ember['default'].computed;

  var options = {
    findAllMatches: true,
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name']
  };

  var initialized = false;
  var initializedFuse = null;

  var fuse = function fuse(data) {
    if (initialized) {
      return initializedFuse;
    } else {
      initialized = true;
      initializedFuse = new Fuse([].concat(_toConsumableArray(data)), options);
      return initializedFuse;
    }
  };

  exports['default'] = _ember['default'].Controller.extend({
    modifyPricing: _ember['default'].inject.controller('modals.pricing'),
    sortingBy: ['default:asc'],
    sortedLots: _ember['default'].computed.sort('lots', 'sortingBy'),

    actions: {
      changeSearch: function changeSearch(search) {
        if (search === "") {
          this.set('lots', this.get('initialLots'));
          return;
        }

        var results = fuse(this.get('lots')).search(search);

        this.set('lots', results.map(function (r) {
          return r.item;
        }));
      },

      modifyPricingCluster: function modifyPricingCluster(tag, lot, pricings, type, title, userOptions, formattedPricings) {
        var itemModel = { tag: tag, lot: lot, pricings: pricings, type: type, title: title, userOptions: userOptions, formattedPricings: formattedPricings };

        this.get('modifyPricing').setProperties({
          model: itemModel,
          type: type
        });
        this.send('openModal', 'pricing');
      }
    }
  });
});