define('web/controllers/testimonials', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    recordName: 'testimonial',
    testimonial: _ember['default'].inject.controller('modals.testimonial'),

    testimonialCols: [{}, {}, { colName: 'Name' }, { colName: 'City' }, { colName: 'Date' }, {}],

    actions: {
      testimonialModal: function testimonialModal(model) {
        this.get('testimonial').setProperties({
          model: model
        });

        this.send('openModal', 'testimonial');
      }
    }
  });
});