define('web/helpers/slugify', ['exports', 'ember'], function (exports, _ember) {
  exports.slugify = slugify;

  function slugify(val) {
    if (_ember['default'].isPresent(val)) {
      var slug = val.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '').replace(/([-])\1+/ig, "$1");

      slug = encodeURIComponent(slug).replace(/\-$/, '');

      return slug;
    }
    return val;
  }

  exports['default'] = _ember['default'].Helper.helper(slugify);
});