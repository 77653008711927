define('web/controllers/home-content/index', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Controller.extend({

    promoCols: [{}, { colName: 'Title' }, { colName: 'Subtitle' }, { colName: 'URL' }, {}],

    howItWorks: (function () {
      return this.get('model.customizations').findBy('name', 'howItWorks');
    }).property('model.customizations'),

    recordSelected: alias('visible'),
    recordModel: 'customization',

    recordId: (function () {
      return this.get('howItWorks').get('id');
    }).property('howItWorks'),

    actions: {
      toggleSelected: function toggleSelected() {
        var _this = this;

        this.toggleProperty('howItWorks.value.visible');
        this.get('howItWorks').save().then(function () {
          _this.flashMessages.success('Promo space toggled successfully!');
        });
      },

      promoTransition: function promoTransition(promoId) {
        this.transitionToRoute('home-content.details', promoId);
      }
    }

  });
});