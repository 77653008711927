define('web/mixins/date-picker', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({

    // ATTN: You will need to RSVP.hash in 'customizations' for this to work!

    // searchSettings: function() {
    //   return this.get('customizations').findBy('name', 'searchSettings');
    // }.property('customizations'),

    // dateRange: function() {
    //   return this.get('searchSettings').get('value.dateRange');
    // }.property('searchSettings'),

    newDate: new Date(),
    rawDate: new Date(),
    rawToday: moment(new Date()).subtract(1, 'd'),
    focusField: false,
    months: '2',

    dateFrom: moment(new Date()).format('YYYY-MM-DD'),
    dateTo: (function () {
      return moment(this.get('dateFrom')).add(1, 'd');
    }).property('dateFrom'),

    actions: {
      sendFocus: function sendFocus() {
        var updatedDate = moment(this.get('dateFrom')).add(1, 'd');
        this.setProperties({
          dateTo: updatedDate,
          rawDate: updatedDate.toDate(),
          focusField: true
        });
      },

      clearFocus: function clearFocus() {
        this.set('focusField', false);
      }
    }
  });
});