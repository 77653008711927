define('web/components/toggle-secret', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    isResetting: false,
    isHidden: true,
    resetComplete: false,
    resetSecret: 'resetSecret',

    _resetComplete: function _resetComplete() {
      this.set('isHidden', true);
      this.set('resetComplete', false);
    },

    actions: {
      showSecret: function showSecret() {
        this.set('isHidden', false);
      },

      resetSecret: function resetSecret() {
        var _this = this;

        this.set('isResetting', true);

        this.sendAction('resetSecret', function (p) {
          p.then(function (resp) {
            _ember['default'].Logger.info(resp);
            _this.setProperties({
              resetComplete: true,
              isResetting: false
            });
          }, function (reason) {
            _ember['default'].Logger.error(reason);
          })['finally'](function () {
            _ember['default'].run.later(_this, _this._resetComplete, 3000);
          });
        });
      },

      cancelReset: function cancelReset() {
        this.set('isHidden', true);
      }
    }
  });
});