define('web/controllers/additional-charges', ['exports', 'ember', 'ic-ajax', 'web/config/environment', 'web/helpers/tax-math'], function (exports, _ember, _icAjax, _webConfigEnvironment, _webHelpersTaxMath) {
  var run = _ember['default'].run;

  var math = (0, _webHelpersTaxMath.additionalChargesMath)();

  var invalidNamesList = ['card', 'convenience', 'credit', 'surcharge'];

  var updateCharges = function updateCharges(data) {
    return (0, _icAjax['default'])({
      method: 'PUT',
      url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges',
      contentType: 'application/json',
      dataType: 'json',
      data: data,
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    });
  };

  var updateAdditionalChargesUserOptions = function updateAdditionalChargesUserOptions(data) {
    return (0, _icAjax['default'])({
      method: 'PUT',
      url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/user-options',
      contentType: 'application/json',
      dataType: 'json',
      data: data,
      crossDomain: true,
      xhrFields: {
        withCredentials: true
      }
    });
  };

  exports['default'] = _ember['default'].Controller.extend({
    confirmation: _ember['default'].inject.controller('modals.confirmation'),
    application: _ember['default'].inject.controller(),

    actions: {
      showConfirmationModal: function showConfirmationModal() {
        var _this = this;

        var currentUser = this.get('currentUser');

        if (this.get('model.additionalChargesEnabled')) {
          this.get('confirmation').setProperties({
            title: 'Disable Additional Charges',
            confirmationMessage: 'Disabling will deactivate additional charges for all your ongoing and upcoming events.',
            supportingMessage: 'Are you sure you want to proceed?',
            submitRequest: function submitRequest() {
              _this.toggleProperty('model.additionalChargesEnabled');
              _this.get('application').send('showLoader');

              updateAdditionalChargesUserOptions(JSON.stringify({
                operatorLegacyId: currentUser.get('id'),
                additionalChargesEnabled: _this.get('model.additionalChargesEnabled')
              })).then(function () {
                _this.get('application').send('hideLoader');
                _this.flashMessages.success('Additional charges have been turned ' + (_this.get("model.additionalChargesEnabled") ? "on" : "off") + ' successfully.');
              })['catch'](function () {
                _this.get('application').send('hideLoader');
                _this.flashMessages.danger('There was an error turning additional charges ' + (_this.get("model.additionalChargesEnabled") ? "on" : "off") + '.');
              });
            },
            cancelRequest: function cancelRequest() {
              _ember['default'].$('#salesTax').click();
              _this.send('closeModal');
            }
          });

          this.send('openModal', 'confirmation');
        } else {
          this.toggleProperty('model.additionalChargesEnabled');

          this.get('application').send('showLoader');

          updateAdditionalChargesUserOptions(JSON.stringify({
            operatorLegacyID: currentUser.get('id'),
            additionalChargesEnabled: this.get('model.additionalChargesEnabled')
          })).then(function () {
            _this.get('application').send('hideLoader');
            _this.flashMessages.success('Additional charges have been turned ' + (_this.get("model.additionalChargesEnabled") ? "on" : "off") + ' successfully.');
          })['catch'](function () {
            _this.get('application').send('hideLoader');
            _this.flashMessages.danger('There was an error turning additional charges ' + (_this.get("model.additionalChargesEnabled") ? "on" : "off") + '.');
          });
        }
      },

      saveState: function saveState(acrossLocations, perLocation, transactionTypes) {
        var _this2 = this;

        var currentUser = this.get('currentUser');
        var payload = {
          additionalCharges: [],
          transactionTypes: [],
          operatorLegacyId: currentUser.get('id'),
          partyExternalKey: currentUser.get('partyKey')
        };

        var hasErrors = false;

        var isAcrossLocations = acrossLocations.get('enabled');

        this.get('application').send('showLoader');

        // If we are updating across multiple locations.
        if (isAcrossLocations) {
          payload.allLandmarks = true;

          if (acrossLocations.get('tax.value')) {
            if (acrossLocations.get('tax.value') > 99.99) {
              acrossLocations.get('tax').set('hasValueError', true);

              this.flashMessages.danger('You must provide a value lower than 99.99');
              this.get('application').send('hideLoader');

              hasErrors = true;

              return;
            }

            // If taxes are enabled.
            payload.additionalCharges.push({
              value: math.convertOut(acrossLocations.get('tax.type.id'), acrossLocations.get('tax.value')),
              classification: 'TAX',
              type: math.setType(acrossLocations.get('tax.type.id')).id,
              name: "Tax"
            });
          }

          // Loop over fee records.
          acrossLocations.get('fees.records').forEach(function (fee) {
            if (fee.get('value') && fee.get('name')) {
              if (fee.get('value') > 99.99) {
                fee.set('hasValueError', true);

                _this2.flashMessages.danger('You must provide a value lower than 99.99');
                _this2.get('application').send('hideLoader');

                hasErrors = true;

                return;
              }

              if (invalidNamesList.some(function (word) {
                return fee.get('name').toLowerCase().replace(/ /g, '').includes(word);
              })) {
                fee.set('isInvalidNAme', true);

                _this2.flashMessages.danger('Please provide a valid name.');
                _this2.get('application').send('hideLoader');

                hasErrors = true;

                return;
              }

              payload.additionalCharges.push({
                value: math.convertOut(fee.get('type.id'), fee.get('value')),
                classification: 'FEE',
                type: math.setType(fee.get('type.id')).id,
                name: fee.get('name')
              });
            } else {
              _this2.get('application').send('hideLoader');
              if (!fee.get('value')) {
                fee.set('hasValueError', true);
              }

              if (!fee.get('name')) {
                fee.set('isInvalidName', true);
              }

              hasErrors = true;

              _this2.flashMessages.danger('You must provide a value for the ' + fee.get('name') + ' fee record(s).');
            }
          });
        } else {
          delete payload.additionalCharges;

          payload.landmarks = [];

          perLocation.records.forEach(function (record) {
            var additionalCharges = [];

            if (record.get('tax.value')) {
              if (record.get('tax.value') > 99.99) {
                record.set('tax.hasValueError', true);

                _this2.flashMessages.danger('You must provide a value lower than 99.99');
                _this2.get('application').send('hideLoader');

                hasErrors = true;

                return;
              }

              additionalCharges.push({
                value: math.convertOut(record.get('tax.type.id'), record.get('tax.value')),
                classification: 'TAX',
                type: math.setType(record.get('tax.type.id')).id,
                name: "Tax"
              });
            }

            var l = {
              id: record.get('landmark.id'),
              additionalCharges: additionalCharges
            };

            record.get('fees.records').forEach(function (fee) {
              if (fee.get('value') && fee.get('name')) {
                if (fee.get('value') > 99.99) {
                  fee.set('hasValueError', true);

                  _this2.flashMessages.danger('You must provide a value lower than 99.99');
                  _this2.get('application').send('hideLoader');

                  hasErrors = true;

                  return;
                }

                if (invalidNamesList.some(function (word) {
                  return fee.get('name').toLowerCase().replace(/ /g, '').includes(word);
                })) {
                  fee.set('isInvalidNAme', true);

                  _this2.flashMessages.danger('Please provide a valid name.');
                  _this2.get('application').send('hideLoader');

                  hasErrors = true;

                  return;
                }

                l.additionalCharges.push({
                  value: math.convertOut(fee.get('type.id'), fee.get('value')),
                  classification: 'FEE',
                  type: math.setType(fee.get('type.id')).id,
                  name: fee.get('name')
                });
              } else {
                _this2.get('application').send('hideLoader');

                if (!fee.get('value')) {
                  fee.set('hasValueError', true);
                }

                if (!fee.get('name')) {
                  fee.set('isInvalidName', true);
                }

                hasErrors = true;

                _this2.flashMessages.danger('You must provide a value for ' + record.get("landmark.name") + '\'s ' + fee.get('name') + ' fee record(s).');
              }
            });

            // If taxes are enabled.
            payload.landmarks.push(l);
          });
        }

        if (transactionTypes.get('cash')) {
          payload.transactionTypes.push('cash');
        }

        if (transactionTypes.get('credit')) {
          payload.transactionTypes.push('credit');
        }

        if (transactionTypes.get('payment')) {
          payload.transactionTypes.push('payment');
        }

        if (hasErrors) {
          return;
        }

        updateCharges(JSON.stringify(payload)).then(function () {
          _this2.get('application').send('hideLoader');
          _this2.flashMessages.success('Additional Charges have been applied successfully.');
        })['catch'](function () {
          _this2.get('application').send('hideLoader');
          _this2.flashMessages.danger('There was an error applying additional charges.');
        });
      }
    }
  });
});