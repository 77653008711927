define('web/controllers/exemption/details', ['exports', 'ember', 'web/mixins/record-editor'], function (exports, _ember, _webMixinsRecordEditor) {
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordEditor['default'], {
    unsavedChanges: _ember['default'].inject.controller('modals.unsaved-changes'),
    recordId: alias('model.id'),
    recordName: 'exemption',
    recordModel: 'exemption',
    recordTransitionTo: 'exemptions',

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      lotRequest: function lotRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'lot');
      },

      lotFocused: function lotFocused(curSelectedItems, cb) {
        if (_ember['default'].isBlank(curSelectedItems)) {
          this.query('*', null, cb, 'lot', {});
        }
      }
    }
  });
});