define("web/controllers/event", ["exports", "ember", "fetch", "web/config/environment"], function (exports, _ember, _fetch, _webConfigEnvironment) {
  exports["default"] = _ember["default"].Controller.extend({
    previousRoute: _ember["default"].computed("transitionedFrom", function () {
      if (_ember["default"].isPresent(this.get("transitionedFrom"))) {
        return this.getWithDefault("transitionedFrom", "event-parkings");
      }
      return "event-parkings";
    }),

    actions: {
      toggleHot: function toggleHot(event) {
        var _this = this;

        var newHotVal = !_ember["default"].get(event, "hot");

        var obj = _ember["default"].Object.create({
          event: {
            hot: newHotVal
          }
        });

        return (0, _fetch["default"])(_webConfigEnvironment["default"].apiUrl + "/api/v2/events/" + event.get("id"), {
          method: "PUT",
          headers: {
            "Content-Type": "application/json"
          },
          credentials: "include",
          body: JSON.stringify(obj)
        }).then(function (status) {
          if (status.status > 300) {
            throw status;
          } else {
            return status;
          }
        }).then(function () {
          var txt = newHotVal ? 'Updated event to a "hot" event.' : 'This event is no longer "hot".';
          _this.get("model").reload();
          _this.flashMessages.success(txt);
        });
      }
    }
  });
});