define('web/components/hot-event-block', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sales-block__container'],
    isLandmark: computed.equal('type', 'landmark'),
    isEvent: computed.equal('type', 'event'),

    eventRevenue: computed.alias('record.totalRevenue'),

    eventBg: (function () {
      var p = this.get('record.images.firstObject.thumb');
      if (p) {
        return ('background-image: url(' + p + ')').htmlSafe();
      } else {
        return 'background-image: url(assets/images/placeholder-md.jpg)'.htmlSafe();
      }
    }).property('record.images')

  });
});