define('web/models/reservation', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    lotId: belongsTo('lot', { async: true }),
    eventId: belongsTo('event', { async: true }),
    availableSpots: attr('number'),
    spotsSold: attr('number'),

    priceModifier: computed('eventId.eventOptions.priceModifier', {
      get: function get() {
        return this.get('eventId.eventOptions.priceModifier');
      }
    })
  });
});