define('web/mixins/ph-chart-setup', ['exports', 'ember', 'web/helpers/comma-separated'], function (exports, _ember, _webHelpersCommaSeparated) {
  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;

  var BASE_CHART_CONFIG = {
    legend: {
      show: true
    },
    padding: {
      top: 0,
      right: 250,
      bottom: 0,
      left: 75
    },
    point: {
      show: false
    },
    zoom: {
      enabled: true
    }
  };

  var PORTABLE_PADDING = {
    top: 0,
    right: 250,
    bottom: 0,
    left: 10
  };

  exports['default'] = _ember['default'].Mixin.create({
    config: computed('model.reports.[]', 'toggleTotals', 'toggleCounts', 'togglePie', function () {
      var _this = this;

      var currentConfig = copy(BASE_CHART_CONFIG);

      if (this.get('toggleTotals')) {
        currentConfig.axis = {
          x: {
            tick: {
              format: function format(x) {
                return moment(x).format('MMM D h:mm A');
              },
              count: 20
            },
            show: true
          },
          y: {
            tick: {
              format: function format(y) {
                return '$' + (y / 100).toFixed(2);
              }
            },
            show: true
          }
        };
      } else if (this.get('toggleCounts')) {
        currentConfig.axis = {
          x: {
            tick: {
              format: function format(x) {
                return moment(x).format('MMM D h:mm A');
              },
              count: 20
            },
            show: true
          },
          y: {
            tick: {
              format: function format(y) {
                return Math.round(y);
              }
            },
            show: true
          }
        };
      } else if (this.get('togglePie')) {
        currentConfig.pie = {
          label: {
            format: function format(value) {
              return (0, _webHelpersCommaSeparated.commaSeparated)(value);
            }
          }
        };
        currentConfig.padding = {
          top: 0,
          right: 0,
          bottom: 0,
          left: 150
        };

        return currentConfig;
      }

      currentConfig.zoom.onzoom = function () {
        _this.set('togglePoll', false);
      };

      if (this.get('media.isPortable')) {
        currentConfig.axis.x.show = false;
        currentConfig.axis.y.show = false;
        currentConfig.padding = PORTABLE_PADDING;
      }

      return currentConfig;
    }),

    chartData: computed('model.reports.[]', 'dateTo', 'toggleTotals', 'toggleCounts', 'togglePie', function () {
      var _this2 = this;

      var cashSum = 0;
      var creditSum = 0;
      var prepaidSum = 0;
      var paymentSum = 0;
      var exemptSum = 0;
      var cashCountsTotal = 0;
      var creditCountsTotal = 0;
      var prepaidCountsTotal = 0;
      var paymentCountsTotal = 0;
      var exemptCountsTotal = 0;

      if (this.get('model.reports')) {
        var json = this.get('model.reports').map(function (resp) {
          var cash = resp.get('cashTotals');
          var credit = resp.get('creditTotals');
          var exempt = resp.get('exemptTotals');
          var prepaid = resp.get('prepaidTotals');
          var payment = resp.get('paymentTotals');
          var cashCounts = resp.get('cashCounts');
          var creditCounts = resp.get('creditCounts');
          var prepaidCounts = resp.get('prepaidCounts');
          var paymentCounts = resp.get('paymentCounts');
          var exemptCounts = resp.get('exemptCounts');

          cashSum += cash || 0;
          creditSum += credit || 0;
          prepaidSum += prepaid || 0;
          paymentSum += payment || 0;
          exemptSum += exempt || 0;
          cashCountsTotal += cashCounts || 0;
          creditCountsTotal += creditCounts || 0;
          prepaidCountsTotal += prepaidCounts || 0;
          paymentCountsTotal += paymentCounts || 0;
          exemptCountsTotal += exemptCounts || 0;

          var jsonObj = {
            'timeStamp': resp.get('timeStamp'),
            'Cash': _this2.get('toggleTotals') ? cash : cashCounts,
            'Credit': _this2.get('toggleTotals') ? credit : creditCounts,
            'Prepaid': _this2.get('toggleTotals') ? prepaid : prepaidCounts,
            'Payment': _this2.get('toggleTotals') ? payment : paymentCounts,
            'Exempt': _this2.get('toggleTotals') ? exempt : exemptCounts,
            'Total': _this2.get('toggleTotals') ? resp.get('totalRevenue') : resp.get('totalCounts')
          };

          if (_this2.get('togglePie')) {
            delete jsonObj['timeStamp'];
            delete jsonObj['Total'];
          }

          return jsonObj;
        });

        var flag = undefined;
        if (this.get('toggleTotals')) {
          flag = 'totals';
        } else if (this.get('togglePie')) {
          flag = 'pie';
        } else if (this.get('toggleCounts')) {
          flag = 'counts';
        }

        this.set('currentCashTotals', cashSum);
        this.set('currentCreditTotals', creditSum);
        this.set('currentPrepaidTotals', prepaidSum);
        this.set('currentPaymentTotals', paymentSum);
        this.set('currentExemptTotals', exemptSum);
        this.set('currentCashCounts', cashCountsTotal);
        this.set('currentCreditCounts', creditCountsTotal);
        this.set('currentPrepaidCounts', prepaidCountsTotal);
        this.set('currentPaymentCounts', paymentCountsTotal);
        this.set('currentExemptCounts', exemptCountsTotal);

        var data = {
          json: json,
          colors: {
            'Credit': '#084f7d',
            'Cash': '#95D352',
            'Prepaid': '#8EC5E0',
            'Payment': '#c155ff',
            'Exempt': '#d73d1d'
          },
          keys: {
            value: ['Cash', 'Credit', 'Prepaid', 'Payment', 'Exempt']
          },
          flag: flag
        };

        if (!this.get('togglePie')) {
          data.keys['x'] = 'timeStamp';
          data.colors['Total'] = '#c0c2c1';
          data['type'] = 'area';
          data.keys.value.unshift('Total');
        } else {
          data['type'] = 'pie';
        }

        return data;
      }
    })
  });
});