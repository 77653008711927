define("web/templates/components/toggle-switch", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 6,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "toggle-switch__label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleLabelLeft", ["loc", [null, [5, 36], [5, 55]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 2
              },
              "end": {
                "line": 9,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "toggle-switch__label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleLabel", ["loc", [null, [8, 36], [8, 51]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 15,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element9 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element9, 'disabled');
            morphs[1] = dom.createAttrMorph(element9, 'class');
            morphs[2] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [12, 23], [12, 31]]]]], ["attribute", "class", ["concat", ["toggle-switch__btn btn--transparent ", ["subexpr", "if", [["get", "selection", ["loc", [null, [12, 82], [12, 91]]]], "on", "off"], [], ["loc", [null, [12, 77], [12, 104]]]]]]], ["content", "firstPosition", ["loc", [null, [13, 10], [13, 27]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 4
              },
              "end": {
                "line": 21,
                "column": 4
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element7, 'class');
            morphs[1] = dom.createMorphAt(element7, 0, 0);
            morphs[2] = dom.createAttrMorph(element8, 'class');
            morphs[3] = dom.createMorphAt(element8, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["toggle-switch__status toggle-switch__status--first ", ["subexpr", "if", [["get", "selection", ["loc", [null, [19, 75], [19, 84]]]], "on"], [], ["loc", [null, [19, 70], [19, 91]]]]]]], ["content", "firstPosition", ["loc", [null, [19, 93], [19, 110]]]], ["attribute", "class", ["concat", ["toggle-switch__status toggle-switch__status--second ", ["subexpr", "if", [["get", "selection", ["loc", [null, [20, 76], [20, 85]]]], "off", "on"], [], ["loc", [null, [20, 71], [20, 98]]]]]]], ["content", "secondPosition", ["loc", [null, [20, 100], [20, 118]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 25,
                "column": 2
              },
              "end": {
                "line": 29,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element6 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element6, 'disabled');
            morphs[1] = dom.createAttrMorph(element6, 'class');
            morphs[2] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "disabled", ["get", "disabled", ["loc", [null, [26, 23], [26, 31]]]]], ["attribute", "class", ["concat", ["toggle-switch__btn btn--transparent ", ["subexpr", "if", [["get", "selection", ["loc", [null, [26, 96], [26, 105]]]], "on", "off"], [], ["loc", [null, [26, 91], [26, 118]]]]]]], ["content", "secondPosition", ["loc", [null, [27, 10], [27, 28]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 31,
                "column": 2
              },
              "end": {
                "line": 33,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "toggle-switch__label right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleLabelRight", ["loc", [null, [32, 42], [32, 62]]]]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 2
              },
              "end": {
                "line": 37,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "required-field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleInfo", ["loc", [null, [36, 33], [36, 47]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "web/templates/components/toggle-switch.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element10 = dom.childAt(fragment, [6]);
          var element11 = dom.childAt(element10, [3]);
          var morphs = new Array(11);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createAttrMorph(element10, 'class');
          morphs[4] = dom.createAttrMorph(element10, 'style');
          morphs[5] = dom.createMorphAt(element10, 1, 1);
          morphs[6] = dom.createAttrMorph(element11, 'disabled');
          morphs[7] = dom.createAttrMorph(element11, 'class');
          morphs[8] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[9] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          morphs[10] = dom.createMorphAt(fragment, 12, 12, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "toggleLabelLeft", ["loc", [null, [4, 8], [4, 23]]]]], [], 0, null, ["loc", [null, [4, 2], [6, 9]]]], ["block", "if", [["get", "toggleLabel", ["loc", [null, [7, 8], [7, 19]]]]], [], 1, null, ["loc", [null, [7, 2], [9, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "labelPosition", ["loc", [null, [11, 12], [11, 25]]]], "outside"], [], ["loc", [null, [11, 8], [11, 36]]]]], [], 2, null, ["loc", [null, [11, 2], [15, 9]]]], ["attribute", "class", ["concat", ["toggle-switch__container ", ["subexpr", "if", [["get", "selection", ["loc", [null, [17, 45], [17, 54]]]], "on", "off"], [], ["loc", [null, [17, 40], [17, 67]]]]]]], ["attribute", "style", ["subexpr", "if", [["get", "disabled", ["loc", [null, [17, 80], [17, 88]]]], "background: #dfdfdf", ["get", "toggleStyle", ["loc", [null, [17, 111], [17, 122]]]]], [], ["loc", [null, [17, 75], [17, 124]]]]], ["block", "if", [["subexpr", "eq", [["get", "labelPosition", ["loc", [null, [18, 14], [18, 27]]]], "inside"], [], ["loc", [null, [18, 10], [18, 37]]]]], [], 3, null, ["loc", [null, [18, 4], [21, 11]]]], ["attribute", "disabled", ["get", "disabled", ["loc", [null, [22, 23], [22, 31]]]]], ["attribute", "class", ["concat", ["btn--default ", ["subexpr", "if", [["get", "selection", ["loc", [null, [22, 73], [22, 82]]]], "show"], [], ["loc", [null, [22, 68], [22, 91]]]], " ", ["subexpr", "if", [["get", "disabled", ["loc", [null, [22, 97], [22, 105]]]], "disabled"], [], ["loc", [null, [22, 92], [22, 118]]]]]]], ["block", "if", [["subexpr", "eq", [["get", "labelPosition", ["loc", [null, [25, 12], [25, 25]]]], "outside"], [], ["loc", [null, [25, 8], [25, 36]]]]], [], 4, null, ["loc", [null, [25, 2], [29, 9]]]], ["block", "if", [["get", "toggleLabelRight", ["loc", [null, [31, 8], [31, 24]]]]], [], 5, null, ["loc", [null, [31, 2], [33, 9]]]], ["block", "if", [["get", "toggleInfo", ["loc", [null, [35, 8], [35, 18]]]]], [], 6, null, ["loc", [null, [35, 2], [37, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 41,
                "column": 2
              },
              "end": {
                "line": 43,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "toggle-switch__label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleLabelLeft", ["loc", [null, [42, 36], [42, 55]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 2
              },
              "end": {
                "line": 46,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "toggle-switch__label");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleLabel", ["loc", [null, [45, 36], [45, 51]]]]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 48,
                "column": 2
              },
              "end": {
                "line": 52,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element3, 'class');
            morphs[1] = dom.createElementMorph(element3);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["toggle-switch__btn btn--transparent ", ["subexpr", "if", [["get", "selection", ["loc", [null, [49, 60], [49, 69]]]], "on", "off"], [], ["loc", [null, [49, 55], [49, 82]]]]]]], ["element", "action", ["deselect"], [], ["loc", [null, [49, 84], [49, 105]]]], ["content", "firstPosition", ["loc", [null, [50, 10], [50, 27]]]]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 4
              },
              "end": {
                "line": 58,
                "column": 4
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'class');
            morphs[1] = dom.createMorphAt(element1, 0, 0);
            morphs[2] = dom.createAttrMorph(element2, 'class');
            morphs[3] = dom.createMorphAt(element2, 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["toggle-switch__status toggle-switch__status--first ", ["subexpr", "if", [["get", "selection", ["loc", [null, [56, 75], [56, 84]]]], "on"], [], ["loc", [null, [56, 70], [56, 91]]]]]]], ["content", "firstPosition", ["loc", [null, [56, 93], [56, 110]]]], ["attribute", "class", ["concat", ["toggle-switch__status toggle-switch__status--second ", ["subexpr", "if", [["get", "selection", ["loc", [null, [57, 76], [57, 85]]]], "off", "on"], [], ["loc", [null, [57, 71], [57, 98]]]]]]], ["content", "secondPosition", ["loc", [null, [57, 100], [57, 118]]]]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 2
              },
              "end": {
                "line": 66,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "type", "button");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h1");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            morphs[1] = dom.createElementMorph(element0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["toggle-switch__btn btn--transparent ", ["subexpr", "if", [["get", "selection", ["loc", [null, [63, 74], [63, 83]]]], "on", "off"], [], ["loc", [null, [63, 69], [63, 96]]]]]]], ["element", "action", ["select"], [], ["loc", [null, [63, 98], [63, 117]]]], ["content", "secondPosition", ["loc", [null, [64, 10], [64, 28]]]]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 68,
                "column": 2
              },
              "end": {
                "line": 70,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            dom.setAttribute(el1, "class", "toggle-switch__label right");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleLabelRight", ["loc", [null, [69, 42], [69, 62]]]]],
          locals: [],
          templates: []
        };
      })();
      var child6 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 72,
                "column": 2
              },
              "end": {
                "line": 74,
                "column": 2
              }
            },
            "moduleName": "web/templates/components/toggle-switch.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "required-field");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["content", "toggleInfo", ["loc", [null, [73, 33], [73, 47]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 0
            },
            "end": {
              "line": 76,
              "column": 0
            }
          },
          "moduleName": "web/templates/components/toggle-switch.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [6]);
          var element5 = dom.childAt(element4, [3]);
          var morphs = new Array(11);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createAttrMorph(element4, 'class');
          morphs[4] = dom.createAttrMorph(element4, 'style');
          morphs[5] = dom.createElementMorph(element4);
          morphs[6] = dom.createMorphAt(element4, 1, 1);
          morphs[7] = dom.createAttrMorph(element5, 'class');
          morphs[8] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[9] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          morphs[10] = dom.createMorphAt(fragment, 12, 12, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "toggleLabelLeft", ["loc", [null, [41, 8], [41, 23]]]]], [], 0, null, ["loc", [null, [41, 2], [43, 9]]]], ["block", "if", [["get", "toggleLabel", ["loc", [null, [44, 8], [44, 19]]]]], [], 1, null, ["loc", [null, [44, 2], [46, 9]]]], ["block", "if", [["subexpr", "eq", [["get", "labelPosition", ["loc", [null, [48, 12], [48, 25]]]], "outside"], [], ["loc", [null, [48, 8], [48, 36]]]]], [], 2, null, ["loc", [null, [48, 2], [52, 9]]]], ["attribute", "class", ["concat", ["toggle-switch__container ", ["subexpr", "if", [["get", "selection", ["loc", [null, [54, 45], [54, 54]]]], "on", "off"], [], ["loc", [null, [54, 40], [54, 67]]]]]]], ["attribute", "style", ["get", "toggleStyle", ["loc", [null, [54, 105], [54, 116]]]]], ["element", "action", ["toggleSelected"], [], ["loc", [null, [54, 69], [54, 96]]]], ["block", "if", [["subexpr", "eq", [["get", "labelPosition", ["loc", [null, [55, 14], [55, 27]]]], "inside"], [], ["loc", [null, [55, 10], [55, 37]]]]], [], 3, null, ["loc", [null, [55, 4], [58, 11]]]], ["attribute", "class", ["concat", ["btn--default ", ["subexpr", "if", [["get", "selection", ["loc", [null, [59, 51], [59, 60]]]], "show"], [], ["loc", [null, [59, 46], [59, 69]]]]]]], ["block", "if", [["subexpr", "eq", [["get", "labelPosition", ["loc", [null, [62, 12], [62, 25]]]], "outside"], [], ["loc", [null, [62, 8], [62, 36]]]]], [], 4, null, ["loc", [null, [62, 2], [66, 9]]]], ["block", "if", [["get", "toggleLabelRight", ["loc", [null, [68, 8], [68, 24]]]]], [], 5, null, ["loc", [null, [68, 2], [70, 9]]]], ["block", "if", [["get", "toggleInfo", ["loc", [null, [72, 8], [72, 18]]]]], [], 6, null, ["loc", [null, [72, 2], [74, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/toggle-switch.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "disabled", ["loc", [null, [2, 6], [2, 14]]]]], [], 0, 1, ["loc", [null, [2, 0], [76, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});