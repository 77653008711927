define('web/models/testimonial', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    pictureData: _emberData['default'].attr('file'),
    name: _emberData['default'].attr('string'),
    city: _emberData['default'].attr('string'),
    body: _emberData['default'].attr('string'),
    list: _emberData['default'].attr('string'),
    lot: _emberData['default'].belongsTo('lot'),
    rating: _emberData['default'].attr('number'),
    createdAt: _emberData['default'].attr('string'),
    updatedAt: _emberData['default'].attr('string'),

    images: _emberData['default'].hasMany('image', { async: true }),

    createDate: (function () {
      var d = this.get('createdAt');
      return moment(d).format('MMMM D, YYYY');
    }).property('createdAt')
  });
});