define('web/routes/payment-accounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {},

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});