define('web/components/ph-dialog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    closeAction: 'closeAction',

    actions: {
      close: function close() {
        this.sendAction('closeAction');
      }
    }
  });
});