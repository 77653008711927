define('web/controllers/approve-ownerships', ['exports', 'ember', 'web/mixins/loading-model', 'web/mixins/load-more'], function (exports, _ember, _webMixinsLoadingModel, _webMixinsLoadMore) {
  var error = _ember['default'].Logger.error;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    authenticated: _ember['default'].inject.controller(),
    modelName: 'model.ownerships',
    queryParams: ['offset', 'getUnclaimed', 'getPending', 'getApproved', 'limit'],

    limit: 20,
    offset: 0,
    getUnclaimed: true,
    getPending: true,
    getApproved: false,

    ownershipCols: [{ colName: 'Lot Name' }, { colName: 'Operator Name' }, { colName: 'Lot Address' }, { colName: 'Lot Region' }, {}],

    actions: {
      ownershipApprove: function ownershipApprove(ownership) {
        var _this = this;

        ownership.set('approved', true);
        ownership.save().then(function () {
          _this.flashMessages.success('Ownership Approved.');
          _this.get('authenticated').notifyPropertyChange('ownershipsPending');
          _this.send('invalidateModel');
        }, function (err) {
          _this.flashMessages.danger('Unable to approve');
          error('Ownership not created :( ', err);
        });
      },

      ownershipDeny: function ownershipDeny(ownership) {
        var _this2 = this;

        ownership.deleteRecord();
        ownership.save().then(function () {
          _this2.flashMessages.danger('Ownership Revoked successfully.');
          _this2.send('invalidateModel');
        }, function (err) {
          _this2.flashMessages.danger('Unable to revoke.');
          error('Ownership delete failed: ', err);
        });
      }
    }
  });
});