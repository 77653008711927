define("web/templates/partials/-header", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 1
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "web/templates/partials/-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createMorphAt(element1, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["environment-tag ", ["get", "environment", ["loc", [null, [2, 32], [2, 43]]]]]]], ["content", "environment", ["loc", [null, [2, 47], [2, 62]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 4
            }
          },
          "moduleName": "web/templates/partials/-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          dom.setAttribute(el1, "class", "site-logo");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("ParkHub");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["assets/images/parkhub-logo-white"], ["class", "icon icon-parkhub_logo"], ["loc", [null, [7, 28], [7, 108]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 0
            },
            "end": {
              "line": 28,
              "column": 0
            }
          },
          "moduleName": "web/templates/partials/-header.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ghosting");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Viewing as ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "inline-svg", ["assets/images/user"], ["class", "icon icon-user"], ["loc", [null, [26, 4], [26, 62]]]], ["content", "currentUser.ghostingName", ["loc", [null, [26, 74], [26, 104]]]]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 7
          }
        },
        "moduleName": "web/templates/partials/-header.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("header");
        dom.setAttribute(el1, "class", "header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "logo-wrapper");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("span");
        dom.setAttribute(el2, "class", "profile-wrapper");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "mobile-nav-button");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [1]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element2, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[2] = dom.createElementMorph(element3);
        morphs[3] = dom.createMorphAt(element3, 1, 1);
        morphs[4] = dom.createElementMorph(element4);
        morphs[5] = dom.createMorphAt(element4, 1, 1);
        morphs[6] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "or", [["subexpr", "eq", [["get", "environment", ["loc", [null, [1, 15], [1, 26]]]], "development"], [], ["loc", [null, [1, 11], [1, 41]]]], ["subexpr", "eq", [["get", "environment", ["loc", [null, [1, 46], [1, 57]]]], "qa"], [], ["loc", [null, [1, 42], [1, 63]]]], ["subexpr", "eq", [["get", "environment", ["loc", [null, [1, 69], [1, 80]]]], "staging"], [], ["loc", [null, [1, 65], [1, 91]]]], ["subexpr", "eq", [["get", "environment", ["loc", [null, [1, 96], [1, 107]]]], "sandbox"], [], ["loc", [null, [1, 92], [1, 118]]]], ["subexpr", "eq", [["get", "environment", ["loc", [null, [1, 123], [1, 134]]]], "demo"], [], ["loc", [null, [1, 119], [1, 142]]]]], [], ["loc", [null, [1, 7], [1, 143]]]]], [], 0, null, ["loc", [null, [1, 1], [3, 7]]]], ["block", "link-to", ["event-parkings"], ["class", "null-linkto"], 1, null, ["loc", [null, [6, 4], [8, 16]]]], ["element", "action", ["toggleAvatarMenu"], [], ["loc", [null, [10, 32], [10, 61]]]], ["inline", "user-avatar", [], ["signOut", "signOut", "closeModal", "closeModal", "currentPath", ["subexpr", "@mut", [["get", "currentPath", ["loc", [null, [14, 22], [14, 33]]]]], [], []], "user", ["subexpr", "@mut", [["get", "currentUser", ["loc", [null, [15, 15], [15, 26]]]]], [], []], "isAvatarMenuOpen", ["subexpr", "@mut", [["get", "isAvatarMenuOpen", ["loc", [null, [16, 27], [16, 43]]]]], [], []], "tooltipVisibility", ["subexpr", "@mut", [["get", "isAvatarMenuOpen", ["loc", [null, [17, 28], [17, 44]]]]], [], []], "tooltipEvent", "manual"], ["loc", [null, [11, 4], [18, 33]]]], ["element", "action", ["showMobileNav"], [], ["loc", [null, [20, 10], [20, 36]]]], ["inline", "inline-svg", ["assets/images/list"], ["class", "icon icon-list"], ["loc", [null, [21, 4], [21, 62]]]], ["block", "if", [["get", "currentUser.isGhosting", ["loc", [null, [24, 6], [24, 28]]]]], [], 2, null, ["loc", [null, [24, 0], [28, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});