define('web/controllers/modals/associate-external-event', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    isModalOpen: computed.alias('application.isModalOpen'),

    associated: false,

    selectedEventImg: computed('model.eventId.images', function () {
      var p = this.get('model.eventId.images.firstObject.hero');

      return p ? ('background-image: url(' + p + ')').htmlSafe() : 'background-image: url(\'assets/images/placeholder-md.jpg\')'.htmlSafe();
    }),

    selectedEvent: null,

    query: function query(text, selectedItems, cb, type) {
      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    _clear: function _clear() {
      this.set('associated', false);
      this.set('model', null);
      this.set('selectedEvent', null);
    },

    actions: {
      eventRequest: function eventRequest(text, selectedItems, cb) {
        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'event');
      },

      eventSelected: function eventSelected(item, cb) {
        this.set('selectedEvent', item);

        cb(item);
      },

      associateExternalEvent: function associateExternalEvent(cb) {
        var _this = this;

        var self = this;
        var externalEvents = this.get('model.events');

        var promise = _ember['default'].RSVP.all(externalEvents.map(function (externalEvent) {
          externalEvent.set('eventId', self.get('selectedEvent'));
          externalEvent.set('state', 'association suggested');

          return externalEvent.save().then(function () {
            externalEvent.set('state', 'association confirmed');
            _this.set('associated', true);
            return externalEvent.save();
          })['catch'](function (err) {
            return _this.flashMessages.danger(err.message);
          });
        }));

        cb(promise);
      },

      closeAssignModal: function closeAssignModal() {
        this._clear();
        this.send('closeModal');
        this.send('invalidateModel');
      }
    }
  });
});