define("web/templates/application", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 6
                },
                "end": {
                  "line": 42,
                  "column": 6
                }
              },
              "moduleName": "web/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "render", ["alert-template", ["get", "flash.context", ["loc", [null, [41, 34], [41, 47]]]]], [], ["loc", [null, [41, 8], [41, 49]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 42,
                  "column": 6
                },
                "end": {
                  "line": 44,
                  "column": 6
                }
              },
              "moduleName": "web/templates/application.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["content", "flash.message", ["loc", [null, [43, 8], [43, 25]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 4
              },
              "end": {
                "line": 45,
                "column": 4
              }
            },
            "moduleName": "web/templates/application.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "flash.template", ["loc", [null, [40, 12], [40, 26]]]]], [], 0, 1, ["loc", [null, [40, 6], [44, 13]]]]],
          locals: ["component", "flash"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 38,
              "column": 2
            },
            "end": {
              "line": 46,
              "column": 2
            }
          },
          "moduleName": "web/templates/application.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "flash-message", [], ["flash", ["subexpr", "@mut", [["get", "flash", ["loc", [null, [39, 27], [39, 32]]]]], [], []]], 0, null, ["loc", [null, [39, 4], [45, 22]]]]],
        locals: ["flash"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "web/templates/application.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "loader-container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "loader loader--style6");
        dom.setAttribute(el2, "title", "5");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el3 = dom.createElement("svg");
        dom.setAttribute(el3, "version", "1.1");
        dom.setAttribute(el3, "id", "Layer_1");
        dom.setAttribute(el3, "xmlns", "http://www.w3.org/2000/svg");
        dom.setAttribute(el3, "xmlns:xlink", "http://www.w3.org/1999/xlink");
        dom.setAttribute(el3, "x", "0px");
        dom.setAttribute(el3, "y", "0px");
        dom.setAttribute(el3, "width", "24px");
        dom.setAttribute(el3, "height", "30px");
        dom.setAttribute(el3, "viewBox", "0 0 24 30");
        dom.setAttribute(el3, "style", "enable-background:new 0 0 50 50;");
        dom.setAttributeNS(el3, "http://www.w3.org/XML/1998/namespace", "xml:space", "preserve");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4, "x", "0");
        dom.setAttribute(el4, "y", "13");
        dom.setAttribute(el4, "width", "4");
        dom.setAttribute(el4, "height", "5");
        dom.setAttribute(el4, "fill", "#333");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animate");
        dom.setAttribute(el5, "attributeName", "height");
        dom.setAttribute(el5, "attributeType", "XML");
        dom.setAttribute(el5, "values", "5;21;5");
        dom.setAttribute(el5, "begin", "0s");
        dom.setAttribute(el5, "dur", "0.6s");
        dom.setAttribute(el5, "repeatCount", "indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animate");
        dom.setAttribute(el5, "attributeName", "y");
        dom.setAttribute(el5, "attributeType", "XML");
        dom.setAttribute(el5, "values", "13; 5; 13");
        dom.setAttribute(el5, "begin", "0s");
        dom.setAttribute(el5, "dur", "0.6s");
        dom.setAttribute(el5, "repeatCount", "indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4, "x", "10");
        dom.setAttribute(el4, "y", "13");
        dom.setAttribute(el4, "width", "4");
        dom.setAttribute(el4, "height", "5");
        dom.setAttribute(el4, "fill", "#333");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animate");
        dom.setAttribute(el5, "attributeName", "height");
        dom.setAttribute(el5, "attributeType", "XML");
        dom.setAttribute(el5, "values", "5;21;5");
        dom.setAttribute(el5, "begin", "0.15s");
        dom.setAttribute(el5, "dur", "0.6s");
        dom.setAttribute(el5, "repeatCount", "indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animate");
        dom.setAttribute(el5, "attributeName", "y");
        dom.setAttribute(el5, "attributeType", "XML");
        dom.setAttribute(el5, "values", "13; 5; 13");
        dom.setAttribute(el5, "begin", "0.15s");
        dom.setAttribute(el5, "dur", "0.6s");
        dom.setAttribute(el5, "repeatCount", "indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("rect");
        dom.setAttribute(el4, "x", "20");
        dom.setAttribute(el4, "y", "13");
        dom.setAttribute(el4, "width", "4");
        dom.setAttribute(el4, "height", "5");
        dom.setAttribute(el4, "fill", "#333");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animate");
        dom.setAttribute(el5, "attributeName", "height");
        dom.setAttribute(el5, "attributeType", "XML");
        dom.setAttribute(el5, "values", "5;21;5");
        dom.setAttribute(el5, "begin", "0.3s");
        dom.setAttribute(el5, "dur", "0.6s");
        dom.setAttribute(el5, "repeatCount", "indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("animate");
        dom.setAttribute(el5, "attributeName", "y");
        dom.setAttribute(el5, "attributeType", "XML");
        dom.setAttribute(el5, "values", "13; 5; 13");
        dom.setAttribute(el5, "begin", "0.3s");
        dom.setAttribute(el5, "dur", "0.6s");
        dom.setAttribute(el5, "repeatCount", "indefinite");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        dom.setNamespace(null);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "flash-messages");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "wormhole-tooltip");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "wormhole-action-btn");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "wormhole-back-to-top");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [6]), 1, 1);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "outlet", ["loc", [null, [1, 0], [1, 10]]]], ["inline", "outlet", ["modal"], [], ["loc", [null, [3, 0], [3, 18]]]], ["block", "each", [["get", "flashMessages.queue", ["loc", [null, [38, 10], [38, 29]]]]], [], 0, null, ["loc", [null, [38, 2], [46, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});