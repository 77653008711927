define('web/serializers/permit', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,
    attrs: {
      operatorId: { serialize: false }
    },

    serialize: function serialize(permit) {
      var t = this._super(permit);

      Object.keys(t).forEach(function (k) {
        if (t[k] === '') {
          t[k] = null;
          return;
        }
      });

      return t;
    }
  });
});