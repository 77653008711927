define('web/routes/create', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      if (this.get('currentUser.role') !== 'admin') {
        return _ember['default'].RSVP.hash({
          userOptions: this.store.findAll('user-option')
        }).then(function (resp) {
          _this.set('foundUserOptions', resp.userOptions.get('firstObject'));

          return _this.get('foundUserOptions');
        });
      }

      return;
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('userOptions', this.get('foundUserOptions'));
    }
  });
});