define('web/helpers/truncate-text', ['exports', 'ember'], function (exports, _ember) {
     var _slicedToArray = (function () { function sliceIterator(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i['return']) _i['return'](); } finally { if (_d) throw _e; } } return _arr; } return function (arr, i) { if (Array.isArray(arr)) { return arr; } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i); } else { throw new TypeError('Invalid attempt to destructure non-iterable instance'); } }; })();

     exports.truncateText = truncateText;

     function truncateText(_ref) {
          var _ref2 = _slicedToArray(_ref, 2);

          var str = _ref2[0];
          var len = _ref2[1];

          if (_ember['default'].isPresent(str) && str.length > len && str.length > 0) {
               var new_str = str + ' ';
               new_str = str.substr(0, len);
               new_str = str.substr(0, new_str.lastIndexOf(' '));
               new_str = new_str.length > 0 ? new_str : str.substr(0, len);

               return new_str + '...';
          }
          return str;
     }

     exports['default'] = _ember['default'].Helper.helper(truncateText);
});