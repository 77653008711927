define('web/components/lot-selling-cube', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;

  var sum = function sum(props) {
    return computed(props + '.[]', function () {
      var _this = this;

      return props.reduce(function (h, p) {
        return h + _this.get(p);
      }, 0);
    });
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['event-parking__container'],
    classNameBindings: ['lotOversold:event-parking__container--oversell'],

    lotOversold: false,
    showNotification: false,
    exiting: false,

    bgImage: computed('lot.images.[]', function () {
      var p = this.get('lot.images.firstObject.hero');
      if (p) {
        return ('background-image: url(\'' + p + '\')').htmlSafe();
      } else {
        return 'background-image: url("/assets/images/placeholder-md.jpg")'.htmlSafe();
      }
    }),

    allCounts: sum(['lot.report.cashCounts', 'lot.report.creditCounts', 'lot.report.exemptCounts', 'lot.report.prepaidCounts']),

    actions: {
      transitionToEvent: function transitionToEvent(eventId) {
        this.attrs.transitionToEvent(eventId);
      },

      saveEventOptions: function saveEventOptions(id, eOId, lotsSellable) {
        this.attrs.saveEventOptions(id, eOId, lotsSellable);
      },

      setOversell: function setOversell(val) {
        var _this2 = this;

        if (val) {
          this.set('showNotification', true);
          run.later(function () {
            _this2.set('exiting', true);
            run.later(function () {
              _this2.set('showNotification', false);
              _this2.set('exiting', false);
            }, 500);
          }, 4000);
          // this.flashMessages.danger(`${this.get('lot.name')} has transitioned to 'overselling'.`);
        }
        this.set('lotOversold', val);
      }
    }
  });
});