define('web/routes/external-transactions/failed-barcodes', ['exports', 'ember', 'web/mixins/load-more'], function (exports, _ember, _webMixinsLoadMore) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadMore.RouteLoadMore, {
    model: function model(params) {
      return this.store.query('failed-external-transaction', params);
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});