define('web/adapters/user', ['exports', 'web/adapters/application', 'ember-cli-form-data/mixins/form-data-adapter'], function (exports, _webAdaptersApplication, _emberCliFormDataMixinsFormDataAdapter) {
  exports['default'] = _webAdaptersApplication['default'].extend(_emberCliFormDataMixinsFormDataAdapter['default'], {
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ['PUT', 'PATCH'],

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');
      delete data.user.landmarkIds;
      return this.ajax(url, "PUT", {
        data: data,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      delete data.user.landmarkIds;
      return this.ajax(url, "POST", {
        data: data,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    ajaxOptions: function ajaxOptions(url, type, options) {
      var data;

      if (options && 'data' in options) {
        data = options.data;
      }

      var hash = this._super.apply(this, arguments);

      if (typeof FormData === 'function' && data && this.formDataTypes.contains(type)) {
        var formData = new FormData();
        var root = Object.keys(data)[0];

        Object.keys(data[root]).forEach(function (key) {
          if (data[root][key] !== undefined) {
            formData.append(root + '[' + key + ']', data[root][key]);
          }
        });

        hash.processData = false;
        hash.contentType = false;
        hash.data = formData;
      }

      return hash;
    },

    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 400 || status === 401 || status === 403) {
        return payload;
      }
      return this._super.apply(this, arguments);
    }
  });
});