define('web/components/ph-table/testimonial-row', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['tbody__tr', 'tbody__tr--clickable'],

    openModal: 'openModal',

    blacklist: computed.equal('testimonial.list', 'blacklist'),
    whitelist: computed.equal('testimonial.list', 'whitelist'),

    click: function click() {
      this.attrs.testimonialModal(this.get('testimonial'));
    },

    iconPath: computed('testimonial.list', {
      get: function get() {
        var assetString = 'assets/images/admin-icons/';
        return this.get('testimonial.list') === 'whitelist' ? assetString + '/whitelist-check' : assetString + '/blacklist-x';
      }
    }),

    bgImage: (function () {
      var p = this.get('testimonial.images.firstObject.hero');
      if (p) {
        return ('background-image: url(' + p + ')').htmlSafe();
      } else {
        return 'background-image: url("assets/images/default-avatar.jpg")'.htmlSafe();
      }
    }).property('testimonial.images')
  });
});