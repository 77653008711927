define("web/templates/components/ph-calendar", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "missing-wrapper",
            "problems": ["wrong-type"]
          },
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "web/templates/components/ph-calendar.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "ph-calendar/ph-header", [], ["title", ["subexpr", "@mut", [["get", "title", ["loc", [null, [3, 10], [3, 15]]]]], [], []], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 10], [4, 15]]]]], [], []], "onNavigateWeek", ["subexpr", "action", ["onNavigateWeek"], [], ["loc", [null, [5, 19], [5, 44]]]]], ["loc", [null, [2, 2], [5, 46]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 8
                },
                "end": {
                  "line": 22,
                  "column": 8
                }
              },
              "moduleName": "web/templates/components/ph-calendar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "yield", [["get", "occurrence", ["loc", [null, [21, 18], [21, 28]]]], ["get", "timetable", ["loc", [null, [21, 29], [21, 38]]]], ["get", "this", ["loc", [null, [21, 39], [21, 43]]]]], [], ["loc", [null, [21, 10], [21, 45]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 22,
                  "column": 8
                },
                "end": {
                  "line": 30,
                  "column": 8
                }
              },
              "moduleName": "web/templates/components/ph-calendar.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "as-calendar/timetable/occurrence", [], ["model", ["subexpr", "@mut", [["get", "occurrence", ["loc", [null, [24, 18], [24, 28]]]]], [], []], "timeSlotHeight", ["subexpr", "@mut", [["get", "timeSlotHeight", ["loc", [null, [25, 27], [25, 41]]]]], [], []], "timetable", ["subexpr", "@mut", [["get", "timetable", ["loc", [null, [26, 22], [26, 31]]]]], [], []], "timeSlotDuration", ["subexpr", "@mut", [["get", "timeSlotDuration", ["loc", [null, [27, 29], [27, 45]]]]], [], []], "onUpdate", ["subexpr", "action", [["get", "this.attrs.onUpdateOccurrence", ["loc", [null, [28, 29], [28, 58]]]]], [], ["loc", [null, [28, 21], [28, 59]]]], "onRemove", ["subexpr", "action", [["get", "this.attrs.onRemoveOccurrence", ["loc", [null, [29, 29], [29, 58]]]]], [], ["loc", [null, [29, 21], [29, 59]]]]], ["loc", [null, [23, 10], [29, 61]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 4
              },
              "end": {
                "line": 32,
                "column": 4
              }
            },
            "moduleName": "web/templates/components/ph-calendar.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasBlock", ["loc", [null, [20, 14], [20, 22]]]]], [], 0, 1, ["loc", [null, [20, 8], [30, 15]]]]],
          locals: ["occurrence"],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 4
              },
              "end": {
                "line": 42,
                "column": 4
              }
            },
            "moduleName": "web/templates/components/ph-calendar.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("li");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "as-calendar/occurrence", [], ["class", "as-calendar-occurrence--preview", "model", ["subexpr", "@mut", [["get", "day.occurrencePreview", ["loc", [null, [38, 16], [38, 37]]]]], [], []], "timeSlotHeight", ["subexpr", "@mut", [["get", "timeSlotHeight", ["loc", [null, [39, 25], [39, 39]]]]], [], []], "timeSlotDuration", ["subexpr", "@mut", [["get", "timeSlotDuration", ["loc", [null, [40, 27], [40, 43]]]]], [], []]], ["loc", [null, [36, 8], [40, 45]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 44,
              "column": 0
            }
          },
          "moduleName": "web/templates/components/ph-calendar.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "occurrences");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(element0, 1, 1);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["block", "each", [["get", "day.occurrences", ["loc", [null, [18, 12], [18, 27]]]]], [], 0, null, ["loc", [null, [18, 4], [32, 13]]]], ["block", "if", [["get", "day.occurrencePreview", ["loc", [null, [34, 10], [34, 31]]]]], [], 1, null, ["loc", [null, [34, 4], [42, 11]]]]],
        locals: ["day", "timetable"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/ph-calendar.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showHeader", ["loc", [null, [1, 6], [1, 16]]]]], [], 0, null, ["loc", [null, [1, 0], [6, 7]]]], ["block", "as-calendar/timetable", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [9, 8], [9, 13]]]]], [], []], "timeZone", ["subexpr", "@mut", [["get", "timeZone", ["loc", [null, [10, 11], [10, 19]]]]], [], []], "timeZoneOptions", ["subexpr", "@mut", [["get", "timeZoneOptions", ["loc", [null, [11, 18], [11, 33]]]]], [], []], "showTimeZoneSearch", ["subexpr", "@mut", [["get", "showTimeZoneSearch", ["loc", [null, [12, 21], [12, 39]]]]], [], []], "timeSlotHeight", ["subexpr", "@mut", [["get", "timeSlotHeight", ["loc", [null, [13, 17], [13, 31]]]]], [], []], "defaultTimeZoneQuery", ["subexpr", "@mut", [["get", "defaultTimeZoneQuery", ["loc", [null, [14, 23], [14, 43]]]]], [], []], "onSelectTime", ["subexpr", "action", ["addOccurrence"], [], ["loc", [null, [15, 15], [15, 39]]]], "onChangeTimeZone", ["subexpr", "action", ["changeTimeZone"], [], ["loc", [null, [16, 19], [16, 44]]]]], 1, null, ["loc", [null, [8, 0], [44, 26]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});