define('web/helpers/thousands-separator', ['exports', 'ember'], function (exports, _ember) {
  exports.thousandsSeparator = thousandsSeparator;

  function thousandsSeparator(val) {
    if (_ember['default'].isPresent(val)) {
      return val.toLocaleString().split('.')[0];
    }
  }

  exports['default'] = _ember['default'].Helper.helper(thousandsSeparator);
});