define('web/components/lot-selling-cube/event-row', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['event-parking__event-row'],

    lotsSellable: computed.alias('reservation.eventId.eventOptions.lotsSellable'),

    existsInLotsSellable: computed('reservation.eventId.eventOptions.lotsSellable.[]', 'lot', {
      get: function get() {
        var lotsSellable = this.get('lotsSellable');

        if (_ember['default'].isPresent(lotsSellable)) {
          return lotsSellable.contains(this.get('lot'));
        }
        return false;
      },

      set: function set(k, newVal) {
        var lotsSellable = _ember['default'].ArrayProxy.create();
        _ember['default'].set(lotsSellable, 'content', this.get('lotsSellable'));

        if (_ember['default'].isPresent(lotsSellable)) {
          if (newVal) {
            lotsSellable.pushObject(this.get('lot'));
          } else {
            lotsSellable.removeObject(this.get('lot'));
          }

          this.attrs.saveEventOptions(this.get('reservation.eventId.id'), this.get('reservation.eventId.eventOptions.id'), lotsSellable);
          return newVal;
        }
      }
    }),

    actions: {
      toggleExistsInLotsSellable: function toggleExistsInLotsSellable() {
        this.set('existsInLotsSellable', !this.get('existsInLotsSellable'));
      },

      transitionToEvent: function transitionToEvent(eventId) {
        this.attrs.transitionToEvent(eventId);
      }
    }
  });
});