define('web/controllers/payment-accounts/merchant-accounts', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    selectedProcessorType: null,

    actions: {
      modifyPaymentProcessor: function modifyPaymentProcessor(processor) {
        if (this.get('createPaymentProcessor') === true) {
          this.set('createPaymentProcessor', false);
        }
        this.set('modifyPaymentProcessor', true);
        this.set('currentPaymentProcessor', processor);
        _ember['default'].$('html, body').animate({
          scrollTop: _ember['default'].$('#scroll-to-modify').offset().top
        }, 450);
      },

      createPaymentProcessor: function createPaymentProcessor() {
        var _this = this;

        var newCredentials = [];

        this.get('selectedProcessorType.credentialKeys').forEach(function (key) {
          var newObj = {};
          newObj[key] = null;

          newCredentials.push(newObj);
        });

        var paymentProcessor = this.store.createRecord('payment-processor', {
          name: 'New Payment Processor',
          typeId: this.get('selectedProcessorType'),
          isDefault: false,
          testMode: false,
          credentials: newCredentials
        });

        this.set('selectedProcessorType', null);

        paymentProcessor.save().then(function (resp) {

          if (_this.get('modifyPaymentProcessor') === true) {
            _this.set('modifyPaymentProcessor', false);
          }
          _this.set('createPaymentProcessor', true);
          _this.set('currentPaymentProcessor', resp);

          _ember['default'].$('html, body').animate({
            scrollTop: _ember['default'].$('#scroll-to-modify').offset().top
          }, 450);
        }, function (err) {
          _ember['default'].Logger.error('Server Error: ', err);
        });
      },

      cancelModify: function cancelModify() {
        if (this.get('modifyPaymentProcessor') === true) {
          this.set('modifyPaymentProcessor', false);
        } else if (this.get('createPaymentProcessor') === true) {
          this.set('createPaymentProcessor', false);
        }
        this.send('invalidateModel');
      }
    }
  });
});