define('web/models/prime-order', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    employeeId: belongsTo('user', { async: true }),
    exemptionId: attr('string'),
    failureReason: attr('string'),
    failureMessage: attr('string'),
    externalTransactionId: attr('string'),
    primeOrderId: attr('string'),
    refund: attr('boolean'),
    transactionType: attr('string'),
    transactionTime: attr('utc')
  });
});