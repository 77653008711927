define('web/routes/home-content/details', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/mixins/record-editor'], function (exports, _ember, _webMixinsResetScroll, _webMixinsRecordEditor) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], _webMixinsRecordEditor.RouteRecordEditor, {
    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      model.reload();
    },

    deactivate: function deactivate() {
      if (this.currentModel.get('hasDirtyAttributes')) {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});