define('web/mixins/loading-model', ['exports', 'ember'], function (exports, _ember) {
  var debounce = _ember['default'].run.debounce;
  var ControllerLoadingMixin = _ember['default'].Mixin.create({
    isLoading: false
  });

  exports.ControllerLoadingMixin = ControllerLoadingMixin;
  var RouteLoadingMixin = _ember['default'].Mixin.create({
    beforeModel: (function () {
      this._super();
      if (this.controller) {
        this.controller.set('isLoading', true);
      }
    }).on('beforeModel'),

    afterModel: function afterModel() {
      this._super();

      if (this.controller) {
        this.controller.set('isLoading', false);
      }
    },

    actions: {
      loading: function loading() {
        debounce(function () {
          return true;
        }, 50);
      }
    }
  });
  exports.RouteLoadingMixin = RouteLoadingMixin;
});