define("web/routes/create/event", ["exports", "ember", "web/mixins/record-create", "ic-ajax", "web/config/environment"], function (exports, _ember, _webMixinsRecordCreate, _icAjax, _webConfigEnvironment) {

  /* Retrieve the metatags available from the meta-tagging-service */
  var metatags = (0, _icAjax["default"])({
    method: 'GET',
    url: _webConfigEnvironment["default"].metatagUrl + "/tags/tag.event",
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  }).then(function (response) {
    return response;
  })["catch"](function (e) {
    return { children: [] };
  }); // or set it to blank array if service is down

  exports["default"] = _ember["default"].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ["name", "slug", "from", "to", "parkingFrom", "parkingTo", "flexDate", "flexDateText", "transient", "live", "presellVendor", "eventTypePath"],
    recordBelongsTo: ["landmarkId", "tagId"],

    model: function model() {
      return _ember["default"].RSVP.hash({
        metatags: metatags
      });
    },

    afterModel: function afterModel(model) {
      this._super();

      if (this.get('currentUser.userOptions.eventCreation')) {
        model.live = true;
      }
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('metatags', model.metatags);
      controller.set('availableVendors', model.availableVendors);
    },

    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.controller.setProperties({
        landmarkSearch: null,
        errorMessages: null,
        timeTo: null,
        timeFrom: null,
        parkingTimeTo: null,
        parkingTimeFrom: null
      });
    }
  });
});