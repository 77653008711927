define("web/templates/authenticated-apps/edit", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 45,
            "column": 0
          }
        },
        "moduleName": "web/templates/authenticated-apps/edit.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered container--push-down");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "edit");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "edit-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4, "class", "section-form");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "app-name");
        var el8 = dom.createTextNode("App Name");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "app-description");
        var el8 = dom.createTextNode("Description");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "app-id");
        var el8 = dom.createTextNode("App ID / Secret");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("h4");
        dom.setAttribute(el7, "class", "app-id");
        var el8 = dom.createComment("");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100 input-col--select");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "min-role");
        var el8 = dom.createTextNode("Minimum Role");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 1, 1]);
        var element1 = dom.childAt(element0, [5, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[5] = dom.createMorphAt(element1, 5, 5);
        morphs[6] = dom.createMorphAt(dom.childAt(element0, [7, 1]), 3, 3);
        morphs[7] = dom.createMorphAt(element0, 9, 9);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "link-to", ["← Back to list of Authenticated Apps", "authenticated-apps"], ["class", "back-to-list"], ["loc", [null, [1, 0], [1, 92]]]], ["element", "action", ["updateRecord"], ["on", "submit"], ["loc", [null, [6, 33], [6, 70]]]], ["inline", "input", [], ["class", "app-name", "type", "text", "placeholder", "App Name", "value", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [10, 78], [10, 88]]]]], [], []], "autofocus", "autofocus"], ["loc", [null, [10, 12], [10, 112]]]], ["inline", "textarea", [], ["class", "app-description", "type", "text", "placeholder", "Description", "value", ["subexpr", "@mut", [["get", "model.description", ["loc", [null, [17, 91], [17, 108]]]]], [], []], "rows", 5, "required", "required"], ["loc", [null, [17, 12], [17, 137]]]], ["content", "model.id", ["loc", [null, [24, 31], [24, 43]]]], ["inline", "toggle-secret", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [25, 34], [25, 39]]]]], [], []], "class", "toggle-secret"], ["loc", [null, [25, 12], [25, 63]]]], ["inline", "select-menu", [], ["prompt", "Select a minimum role:", "changeSelectProp", ["subexpr", "action", [["subexpr", "mut", [["get", "model.minRole", ["loc", [null, [34, 44], [34, 57]]]]], [], ["loc", [null, [34, 39], [34, 58]]]]], [], ["loc", [null, [34, 31], [34, 59]]]], "content", ["subexpr", "@mut", [["get", "availRoles", ["loc", [null, [35, 22], [35, 32]]]]], [], []], "selectedValue", ["subexpr", "@mut", [["get", "model.minRole", ["loc", [null, [36, 28], [36, 41]]]]], [], []]], ["loc", [null, [32, 12], [36, 43]]]], ["inline", "partial", ["partials/update-delete"], [], ["loc", [null, [40, 8], [40, 44]]]]],
      locals: [],
      templates: []
    };
  })());
});