define('web/initializers/model', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports.initialize = initialize;

  function initialize() /* container, application */{
    _emberData['default'].Model.reopen({
      saveOriginalRelations: function saveOriginalRelations() {
        var _this = this;

        this.originalRelations = {};

        this.constructor.eachRelationship(function (key, relationship) {
          if (relationship.kind === 'belongsTo') {
            _this.originalRelations[key] = _this.get(key);
          }
          if (relationship.kind === 'hasMany') {
            _this.originalRelations[key] = _this.get(key).toArray();
          }
        }, this);

        this.constructor.eachAttribute(function (k, options) {
          if (options.type === 'array') {
            _this.originalRelations[k] = _this.get(k);
          }
        }, this);
      },

      onLoad: (function () {
        this.saveOriginalRelations();
      }).on('didLoad', 'didCreate', 'didUpdate'),

      checkReloading: _ember['default'].observer('isReloading', function () {
        if (!this.get('isReloading')) {
          this.saveOriginalRelations();
        }
      }),

      rollback: function rollback() {
        var _this2 = this;

        this._super();

        if (!this.originalRelations) {
          return;
        }

        Object.keys(this.originalRelations).forEach(function (key) {
          // careful, as Ember.typeOf for ArrayProxy is 'instance'
          if (_ember['default'].isArray(_this2.get(key))) {
            _this2.get(key).setObjects(_this2.originalRelations[key]);
            _this2.get(key).filterBy('hasDirtyAttributes').invoke('rollback');
            return;
          }

          if (_ember['default'].typeOf(_this2.get(key)) === 'instance') {
            _this2.set(key, _this2.originalRelations[key]);
            return;
          }
        }, this);
      },

      isDeepDirty: function isDeepDirty() {
        var _this3 = this;

        if (this._super('hasDirtyAttributes')) {
          return true;
        }

        if (!this.originalRelations) {
          return false;
        }

        return Object.keys(this.originalRelations).any(function (key) {
          if (_ember['default'].isArray(_this3.get(key))) {
            if (_this3.get(key).isAny('hasDirtyAttributes')) {
              return true;
            }

            if (_this3.get(key).get('length') !== _this3.originalRelations[key].length) {
              return true;
            }

            var dirty = false;

            _this3.get(key).forEach(function (item, index) {
              if (typeof item === 'object') {
                if (item.get('id') !== _this3.originalRelations[key][index].get('id')) {
                  dirty = true;
                }
              }
            }, _this3);

            return dirty;
          }

          if (_this3.get(key)) {
            if (_this3.get('ignoreDeepDirty') && _this3.get('ignoreDeepDirty').contains(key)) {
              return false;
            }
            if (_this3.originalRelations[key]) {
              return _this3.get(key).get('hasDirtyAttributes') || _this3.get(key).get('id') !== _this3.originalRelations[key].get('id');
            }
            return false;
          }

          return false;
        }, this);
      }
    });
  }

  exports['default'] = {
    name: 'model',
    initialize: initialize
  };
});