define('web/serializers/transaction', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      primeOrders: { embedded: 'always' },
      primeTransactions: { embedded: 'always' },
      parkhubTransactions: { embedded: 'always' },
      parkingSpots: { embedded: 'always' },
      externalTransactions: { embedded: 'always' }
    }
  });
});