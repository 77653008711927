define('web/components/search-results', ['exports', 'ember'], function (exports, _ember) {
  var alias = _ember['default'].computed.alias;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'section',
    classNames: ['search-results'],

    lotsLength: alias('model.lots.length'),
    landmarksLength: alias('model.landmarks.length'),
    eventsLength: alias('model.events.length'),
    usersLength: alias('model.users.length'),

    resetForType: (function () {
      this.setProperties({
        limit: 20,
        offset: 0
      });
    }).observes('type'),

    resultLength: (function () {
      if (_ember['default'].isPresent(this.get('lotsLength') || this.get('landmarksLength') || this.get('eventsLength') || this.get('usersLength'))) {
        return (this.get('lotsLength') || 0) + (this.get('landmarksLength') || 0) + (this.get('eventsLength') || 0) + (this.get('usersLength') || 0);
      }
    }).property('lotsLength', 'landmarksLength', 'eventsLength', 'usersLength')
  });
});