define('web/components/link-to-ph', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',
    attributeBindings: ['phLink:href'],
    target: '_blank',

    phLink: _ember['default'].computed('type', 'model.slug', function () {
      return this.get('currentUser.phEnv') + '/' + this.get('type') + 's/' + this.get('model.slug');
    })
  });
});