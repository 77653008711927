define('web/controllers/tags', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/loading-model', 'web/mixins/load-more'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadingModel, _webMixinsLoadMore) {
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    recordName: 'tag',
    modifyTag: _ember['default'].inject.controller('modals.modify-tag'),
    queryParams: ['search', 'limit', 'offset'],

    tagCols: [{ colName: 'Name' }, {}],

    actions: {
      modifyTag: function modifyTag(model, type) {
        this.get('modifyTag').setProperties({
          model: model,
          type: type
        });

        this.send('openModal', 'modify-tag');
      },

      tagTransition: function tagTransition(tagId) {
        this.transitionToRoute('tag', tagId);
      }
    }
  });
});