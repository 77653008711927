define('web/controllers/lots', ['exports', 'ember', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model'], function (exports, _ember, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsLoadMore.ControllerLoadMore, {
    queryParams: ['user_id', 'limit', 'offset', 'search', 'getAll'],
    user_id: null,
    getAll: false,

    liveOnly: computed('getAll', function () {
      if (!this.get('getAll')) {
        return true;
      }
      return false;
    }),

    recordName: 'lot',
    authenticated: _ember['default'].inject.controller(),

    ownershipsPending: computed.alias('authenticated.ownershipsPending'),

    lotCols: [{ colName: 'Name' }, {}, { colName: 'Operator' }, { colName: 'Region' }, {}],

    actions: {
      toggleSelected: function toggleSelected() {
        this.toggleProperty('getAll');
      },

      clearUserId: function clearUserId() {
        this.set('user_id', null);
      },

      lotTransition: function lotTransition(lot) {
        this.transitionToRoute('lot', lot.id);
      }
    }
  });
});