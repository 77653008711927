define('web/torii-adapters/parkhub', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Object.extend({
    // credentials as passed from torii.open
    open: function open(params) {
      return {
        currentUser: params.user
      };
    },

    fetch: function fetch() {
      return (0, _icAjax['default'])({
        method: 'GET',
        url: _webConfigEnvironment['default'].apiUrl + '/api/v2/profile',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      }).then(function (resp) {
        if (resp.statusCode === 401) {
          throw new Error('Invalid session');
        }
        return {
          currentUser: resp.user
        };
      });
    },

    close: function close() {
      return (0, _icAjax['default'])({
        method: 'DELETE',
        url: _webConfigEnvironment['default'].apiUrl + '/api/v2/session',
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});