define('web/helpers/pluralize', ['exports', 'ember'], function (exports, _ember) {
  exports.pluralize = pluralize;

  function pluralize(number, single, plural) {
    if (number === 1) {
      return single;
    } else {
      return typeof plural === 'string' ? plural : single + 's';
    }
  }

  exports['default'] = _ember['default'].Helper.helper(pluralize);
});