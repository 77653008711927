define('web/routes/pricing', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      return this.store.findAll('operator-cut').then(function (resp) {
        return resp.get('firstObject');
      })['catch'](function (err) {
        return _this.flashMessages.danger(err.message);
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }

  });
});