define('web/models/image', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    hero: _emberData['default'].attr('string'),
    sidekick: _emberData['default'].attr('string'),
    thumb: _emberData['default'].attr('string'),

    imageData: _emberData['default'].attr('file'),
    recordId: _emberData['default'].attr('string'),
    modelName: _emberData['default'].attr('string'),
    imageType: _emberData['default'].attr('string')
  });
});