define('web/routes/payment-accounts/merchant-accounts', ['exports', 'ember', 'web/config/environment'], function (exports, _ember, _webConfigEnvironment) {
  var RSVP = _ember['default'].RSVP;
  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Route.extend({
    cookies: _ember['default'].inject.service('cookies'),
    model: function model() {
      return RSVP.hash({
        paymentProcessors: this.store.findAll('payment-processor'),
        paymentProcessorTypes: this.store.findAll('payment-processor-type')
      });
    },
    isGlobalAdmin: computed(function () {
      var cookies = this.get("cookies").read();
      var cookieAvailable = cookies[_webConfigEnvironment['default'].coreCookieKey];

      if (cookieAvailable) {
        var cookiePayload = window.jwt_decode(cookieAvailable).payload;

        if (cookiePayload.gk || cookiePayload.g[0].r === "GLOBAL_ADMIN") {
          return true;
        }
      }

      return false;
    }),

    setupController: function setupController(controller, model) {
      this._super(controller, model);

      var processorTypes = [];

      model.paymentProcessorTypes.content.forEach(function (processorType) {
        var processorTypeName = processorType.getRecord().get('name');
        if (!['FAPS Ingenico', 'Authorize.Net', 'Datacap', 'Vantiv', 'TSYS', 'First Data', 'Elavon', 'Heartland', 'TSYS Summit', 'Summit', 'Tandem', 'Paymentech', 'Raft 10', 'Rapid Connect', 'TSYS Android Test'].includes(processorTypeName) && processorType.getRecord().get('active') === true) {
          processorTypes.push(processorType);
        }
      });

      model.paymentProcessorTypes.set('content', processorTypes);

      var paymentProcessors = _ember['default'].A();

      model.paymentProcessors.forEach(function (processor) {
        if (!processor.get('deleted')) {
          processor.set('processorTypeActive', processor.get('typeId').get('active'));
          paymentProcessors.pushObject(processor);
        }
      });

      controller.set('isGlobalAdmin', this.get('isGlobalAdmin'));
      controller.set('paymentProcessors', paymentProcessors);
      controller.set('paymentProcessorTypes', model.paymentProcessorTypes);
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        createPaymentProcessor: false,
        modifyPaymentProcessor: false
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      }
    }
  });
});