define('web/mixins/long-polling', ['exports', 'ember'], function (exports, _ember) {

  var POLL_INTERVAL = 10000;

  exports['default'] = _ember['default'].Mixin.create({
    activate: function activate() {
      this.set('pollingInfo', _ember['default'].run.later(this, this._poll, POLL_INTERVAL));
    },

    deactivate: function deactivate() {
      _ember['default'].run.cancel(this.get('pollingInfo'));
    },

    _poll: function _poll() {
      this.refresh();
      this.set('pollingInfo', _ember['default'].run.later(this, this._poll, POLL_INTERVAL));
    }
  });
});