define('web/transforms/object', ['exports', 'ember-data', 'web/utils/object-create-deep'], function (exports, _emberData, _webUtilsObjectCreateDeep) {
  exports['default'] = _emberData['default'].Transform.extend({
    deserialize: function deserialize(serialized) {
      return (0, _webUtilsObjectCreateDeep['default'])(serialized);
    },

    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});