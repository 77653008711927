define('web/serializers/landmark', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      lots: {
        serialize: false
      },
      externalValidations: {
        deserialize: 'records',
        serialize: 'records'
      },
      images: {
        deserialize: 'records',
        serialize: false
      }
    },

    serialize: function serialize(landmark, options) {

      var t = this._super(landmark, options);

      var resp = Object.keys(t).reduce(function (obj, k) {
        if (k === "location") {
          obj[k] = {
            lat: landmark.record.get('location.lat').toString(),
            lon: landmark.record.get('location.lon').toString()
          };
        }

        if (obj[k] === null) {
          obj[k] = undefined;
        }

        return obj;
      }, t);

      return resp;
    }

  });
});