define('web/routes/events/calendar', ['exports', 'ember', 'web/mixins/build-params'], function (exports, _ember, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var _this = this;

      var curParams = params;
      if (!this.controller) {
        curParams = {};
      }

      if (this.controller && this.controller.get('dateFrom') && this.controller.get('dateTo')) {
        curParams.dateFrom = this.controller.get('dateFrom');
        curParams.dateTo = this.controller.get('dateTo');
      }

      return this.store.query('event', (0, _webMixinsBuildParams.buildParams)(curParams, ['dateFrom', 'dateTo'])).then(function (events) {
        return _ember['default'].RSVP.all(events.map(function (ev) {
          return ev.get('landmarkId').then(function (lm) {
            ev.set('landmarkId', lm);
            return ev;
          });
        }));
      })['catch'](function (err) {
        return _this.flashMessages.danger(err.message);
      });
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        dateFrom: null,
        dateTo: null,
        showDialog: false,
        dialogEvent: null
      });
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
      },

      loading: function loading() {
        return false;
      }
    }
  });
});