define('web/helpers/sum', ['exports', 'ember'], function (exports, _ember) {
  exports.sum = sum;

  function sum(value) {
    return value.reduce(function (a, b) {
      return a + b;
    });
  }

  exports['default'] = _ember['default'].Helper.helper(sum);
});