define('web/controllers/event-parkings', ['exports', 'web/mixins/loading-model', 'web/mixins/load-more', 'web/mixins/records-search', 'ember'], function (exports, _webMixinsLoadingModel, _webMixinsLoadMore, _webMixinsRecordsSearch, _ember) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  var options = {
    findAllMatches: true,
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name']
  };

  var initialized = false;
  var initializedFuse = null;

  var fuse = function fuse(data) {
    if (initialized) {
      return initializedFuse;
    } else {
      initialized = true;
      initializedFuse = new Fuse([].concat(_toConsumableArray(data)), options);
      return initializedFuse;
    }
  };

  exports['default'] = _ember['default'].Controller.extend(_webMixinsLoadingModel.ControllerLoadingMixin, _webMixinsRecordsSearch.ControllerSearchMixin, _webMixinsLoadMore.ControllerLoadMore, {
    recordName: 'event',

    queryParams: ['limit', 'offset', 'search', 'live'],

    eventCols: [{ colName: 'Hot' }, { colName: 'Date / Time' }, { colName: 'Event' }, {}],
    sortProps: ['parkingFrom:asc'],
    sortedEvents: _ember['default'].computed.sort('model', 'sortProps'),

    eventStateOptions: _ember['default'].A([_ember['default'].Object.create({ id: "LIVE", name: "Live" }), _ember['default'].Object.create({ id: "UNLIVE", name: "Not Live" })]),

    actions: {
      changeEventStateOptions: function changeEventStateOptions(option) {
        if (option.id === 'UNLIVE') {
          this.set('live', false);
        } else {
          this.set('live', true);
        }
      },

      eventTransition: function eventTransition(eventId) {
        this.transitionToRoute('event', eventId);
      },

      eventParkingTransition: function eventParkingTransition(epId) {
        this.transitionToRoute('event-parking.details', epId);
      }
    }
  });
});