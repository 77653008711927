define('web/routes/approve-ownerships', ['exports', 'ember', 'web/mixins/load-more', 'web/mixins/loading-model'], function (exports, _ember, _webMixinsLoadMore, _webMixinsLoadingModel) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isPresent = _ember['default'].isPresent;
  var debounce = _ember['default'].run.debounce;

  var makeParams = function makeParams() {
    var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var keys = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    return keys.reduce(function (hash, k) {
      var val = get(params, k);
      if (isPresent(val)) {
        set(hash, k, val);
      }

      return hash;
    }, {});
  };

  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadMore.RouteLoadMore, _webMixinsLoadingModel.RouteLoadingMixin, {
    queryParams: {
      region: {
        refreshModel: true
      }
    },

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        lots: this.store.query('lot', makeParams(params, ['getUnclaimed'])),
        ownerships: this.store.query('ownership', makeParams(params, ['limit', 'offset', 'getPending', 'getApproved']))
      }).then(_ember['default'].Object.create.bind(_ember['default'].Object));
    },

    actions: {
      invalidateModel: function invalidateModel() {
        this.refresh();
        return true;
      },

      loading: function loading() {
        debounce(function () {
          return true;
        }, 50);
      }
    }

  });
});