define('web/serializers/payment-processor-type', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    serialize: function serialize(paymentProcessorType, options) {
      var t = this._super(paymentProcessorType, options);

      Object.keys(t).forEach(function (k) {
        if (t[k] === null) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});