define('web/components/sales-block-group', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var equal = _ember['default'].computed.equal;
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['sales-block__group'],
    attributeBindings: ['borderOffset:style'],

    // These use 'hot-event-block' component
    isEvent: equal('type', 'event'),
    isLandmark: equal('type', 'landmark'),
    isRegion: equal('type', 'region'),

    // These use 'user-block' component
    isLot: equal('type', 'lot'),
    isOperator: equal('type', 'operator'),
    isSubOperator: equal('type', 'subOperator'),
    isAttendant: equal('type', 'attendant'),

    borderOffset: computed('model', function () {
      return ('margin-left: ' + this.get('model.length') + 'px').htmlSafe();
    })
  });
});