define("web/controllers/search", ["exports", "ember", "web/mixins/loading-model"], function (exports, _ember, _webMixinsLoadingModel) {
  var computed = _ember["default"].computed;
  exports["default"] = _ember["default"].Controller.extend(_webMixinsLoadingModel.ControllerLoadingMixin, {
    queryParams: ["q", "type", "limit", "offset", "getAll"],
    type: null,
    limit: 20,
    offset: 0,
    getAll: true,
    q: null,

    availableFilters: ["events", "lots", "users"],
    selectedFilters: computed("type", {
      get: function get() {
        return [this.get("type")] || [];
      },
      set: function set(k, newVal) {
        this.set("type", newVal.pop() || null);
        return newVal;
      }
    }),

    searchVal: computed("q", {
      get: function get() {
        return this.get("q") || null;
      },
      set: function set(k, newVal) {
        return newVal;
      }
    }),

    _setSearch: function _setSearch(e) {
      return e === "" ? this.set("q", null) : this.set("q", e);
    },

    actions: {
      searchChanged: function searchChanged(e) {
        this._setSearch(e);
      },

      changeTypes: function changeTypes(val, checked) {
        return checked ? this.set("selectedFilters", [val]) : this.set("selectedFilters", []);
      }
    }
  });
});