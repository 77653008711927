define('web/adapters/promotion', ['exports', 'web/adapters/application', 'ember-cli-form-data/mixins/form-data-adapter'], function (exports, _webAdaptersApplication, _emberCliFormDataMixinsFormDataAdapter) {
  exports['default'] = _webAdaptersApplication['default'].extend(_emberCliFormDataMixinsFormDataAdapter['default'], {
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ['POST', 'PUT'],

    ajaxOptions: function ajaxOptions(url, type, options) {
      var data;

      if (options && 'data' in options) {
        data = options.data;
      }

      var hash = this._super.apply(this, arguments);

      if (typeof FormData === 'function' && data && this.formDataTypes.contains(type)) {
        var formData = new FormData();
        var root = Object.keys(data)[0];

        Object.keys(data[root]).forEach(function (key) {
          if (data[root][key] !== undefined) {
            formData.append(root + '[' + key + ']', data[root][key]);
          }
        });

        hash.processData = false;
        hash.contentType = false;
        hash.data = formData;
      }

      return hash;
    }
  });
});