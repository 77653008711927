define('web/mixins/build-params', ['exports', 'ember'], function (exports, _ember) {
  exports.buildParams = buildParams;
  exports.filterParams = filterParams;
  exports.buildParamString = buildParamString;
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var isPresent = _ember['default'].isPresent;

  function buildParams() {
    var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var keys = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    return keys.reduce(function (hash, k) {
      var val = get(params, k);
      if (isPresent(val)) {
        if (val instanceof Date) {
          val = val.toJSON();
        }

        set(hash, k, val);
      }

      return hash;
    }, {});
  }

  function filterParams(params) {
    if (params) {
      params = Object.keys(params).reduce(function (p, k) {
        var val = get(params, k);
        if (_ember['default'].isPresent(val)) {
          set(p, k, val);
        }
        return p;
      }, {});
    } else {
      params = {};
    }
    return params;
  }

  function buildParamString() {
    var params = arguments.length <= 0 || arguments[0] === undefined ? {} : arguments[0];
    var keys = arguments.length <= 1 || arguments[1] === undefined ? [] : arguments[1];

    var finalParams = buildParams(params, keys);

    if (!_ember['default'].isEmpty(finalParams)) {
      var paramString = '?' + Object.keys(finalParams).reduce(function (str, k) {
        var val = finalParams[k];

        if (typeof val !== 'string' && typeof val !== 'number' && typeof val !== 'boolean') {
          // Array provided
          var string = val.reduce(function (arrString, curString, i) {
            var index = encodeURIComponent('[' + i + ']');
            return '' + arrString + k + index + '=' + curString + '&';
          }, '');

          return str + string;
        }

        return '' + str + k + '=' + val + '&';
      }, '');

      return paramString.slice(0, -1);
    }

    return '';
  }
});