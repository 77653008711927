define('web/controllers/authenticated', ['exports', 'ember', 'ember-data', 'web/config/environment'], function (exports, _ember, _emberData, _webConfigEnvironment) {
  var computed = _ember['default'].computed;
  var run = _ember['default'].run;
  exports['default'] = _ember['default'].Controller.extend({
    phTour: _ember['default'].inject.controller('modals.ph-tour'),
    isAvatarMenuOpen: false,
    environment: _webConfigEnvironment['default'].environment,
    liveUrl: _webConfigEnvironment['default'].liveUrl,
    vipParkerUrl: _webConfigEnvironment['default'].vipParkerUrl,
    dateRangeUrl: _webConfigEnvironment['default'].analyticsUrl + '/date-range',
    isNavOpen: false,

    ownershipsPending: computed('session', {
      get: function get() {
        if (this.get('session')) {
          return _emberData['default'].PromiseObject.create({
            promise: this.store.query('ownership', {
              getPending: true,
              getApproved: false
            }).then(function (resp) {
              return resp;
            })
          });
        }
      }
    }),

    actions: {
      showMobileNav: function showMobileNav() {
        var isMobile = _ember['default'].$('body').width() < 600;

        if (isMobile) {
          if (this.get('isNavOpen')) {
            _ember['default'].$('.site-nav').animate({
              width: "0"
            });

            this.set('isNavOpen', false);
          } else {
            _ember['default'].$('.site-nav').css({
              display: "block"
            }).animate({
              width: "250px"
            });

            this.set('isNavOpen', true);
          }
        }
      },

      phTour: function phTour(model) {
        this.get('phTour').setProperties({
          model: model
        });

        this.send('openModal', 'ph-tour');
      },

      toggleAvatarMenu: function toggleAvatarMenu() {
        var _this = this;

        this.toggleProperty('isAvatarMenuOpen');

        if (this.get('isAvatarMenuOpen')) {
          if (!_ember['default'].$('body').hasClass('shepherd-active')) {
            run.next(function () {
              _ember['default'].$(document).one('click', function () {
                _this.set('isAvatarMenuOpen', false);
              });

              _ember['default'].$('.user-avatar__menu').one('click', function (e) {
                run.debounce(function () {
                  e.stopPropagation();
                  return false;
                }, 100);
              });
            });
          }
        }
      }
    }
  });
});