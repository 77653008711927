define('web/mixins/error-message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    _errors: function _errors(err) {
      var errorMessage;
      var errorIsChild;

      if (err.message) {
        this.set('errorMessages', [err.message]);
      } else if (err.jqXHR) {
        if (err.jqXHR.responseJSON.message) {
          this.set('errorMessages', [err.jqXHR.responseJSON.message]);
        } else if (err.jqXHR.statusText) {
          this.set('errorMessages', [err.jqXHR.statusText]);
        } else {
          errorMessage = err.jqXHR.responseText.split('"')[9];

          if (errorMessage === 'child \\') {
            errorIsChild = err.jqXHR.responseText.split('[')[1].split(']')[0];
            this.set('errorMessages', [errorIsChild.replace('\\', '').replace('\\', '')]);
          } else {
            if (errorMessage.indexOf('\\') === -1) {
              this.set('errorMessages', [errorMessage]);
            } else {
              this.set('errorMessages', [errorMessage.replace('\\', '')]);
            }
          }
        }
      } else if (_ember['default'].isPresent(err.responseText)) {
        errorMessage = err.responseText.split('"')[9];

        if (errorMessage === 'child \\') {
          errorIsChild = err.responseText.split('[')[1].split(']')[0];
          this.set('errorMessages', [errorIsChild.replace('\\', '').replace('\\', '')]);
        } else {
          this.set('errorMessages', [errorMessage.replace('\\', '')]);
        }
      } else if (err.error.message) {
        this.set('errorMessages', [err.error.message]);
      } else {
        this.set('errorMessages', ['An error has occurred.']);
      }

      _ember['default'].Logger.error('server error:', err);
    }
  });
});