define('web/controllers/application', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    isModalOpen: false,

    actions: {

      showLoader: function showLoader() {
        _ember['default'].$('.loader-container').fadeIn();
      },

      hideLoader: function hideLoader() {
        _ember['default'].$('.loader-container').fadeOut();
      }
    }
  });
});