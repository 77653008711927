define('web/initializers/feature-detect', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() /* container, application */{
    var supportsBackgroundBlendMode = window.getComputedStyle(document.body).backgroundBlendMode;
    var b = document.documentElement;

    if (supportsBackgroundBlendMode !== undefined) {
      b.className += ' background-blend-mode';
    } else {
      b.className += ' no-background-blend-mode';
    }
  }

  exports['default'] = {
    name: 'feature-detect',
    initialize: initialize
  };
});