define("web/templates/external-transactions/failed-barcodes", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 8
            },
            "end": {
              "line": 10,
              "column": 42
            }
          },
          "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" to ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["content", "offset", ["loc", [null, [10, 28], [10, 38]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 6
            },
            "end": {
              "line": 16,
              "column": 6
            }
          },
          "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn--outline btn--x-dark btn--sm");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" 100\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element2);
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["previousHundred"], [], ["loc", [null, [13, 56], [13, 84]]]], ["inline", "inline-svg", ["/assets/images/admin-icons/back-hundred"], ["class", "icon icon--back-hundred"], ["loc", [null, [14, 10], [14, 98]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 6
              },
              "end": {
                "line": 28,
                "column": 6
              }
            },
            "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "abs-center--horizontal margin-top--24 animated fadeIn");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "inline-svg", ["assets/images/cw"], ["class", "icon icon--dark"], ["loc", [null, [26, 10], [26, 67]]]]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 14
                  },
                  "end": {
                    "line": 44,
                    "column": 14
                  }
                },
                "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "icon-badge icon-badge--small icon-badge--success");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["assets/images/admin-icons/whitelist-check"], ["class", "icon icon--failure-reason"], ["loc", [null, [42, 18], [42, 110]]]]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 14
                  },
                  "end": {
                    "line": 48,
                    "column": 14
                  }
                },
                "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "icon-badge icon-badge--small icon-badge--error");
                var el2 = dom.createTextNode("\n                  ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "inline-svg", ["assets/images/admin-icons/blacklist-x"], ["class", "icon icon--failure-reason"], ["loc", [null, [46, 18], [46, 106]]]]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.4.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 60,
                      "column": 16
                    },
                    "end": {
                      "line": 69,
                      "column": 16
                    }
                  },
                  "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "tooltip__header");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("h4");
                  var el3 = dom.createTextNode("External Data");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "tooltip__body");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("div");
                  dom.setAttribute(el2, "class", "code-snippet");
                  var el3 = dom.createTextNode("\n                      ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n                    ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "format-json", [["get", "failedBarcode.externalData", ["loc", [null, [66, 36], [66, 62]]]]], [], ["loc", [null, [66, 22], [66, 64]]]]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.4.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 55,
                    "column": 14
                  },
                  "end": {
                    "line": 70,
                    "column": 14
                  }
                },
                "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
              },
              isEmpty: false,
              arity: 2,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "tooltip-on-parent", [], ["place", "left", "spacing", 15], 0, null, ["loc", [null, [60, 16], [69, 38]]]]],
              locals: ["parent", "attrs"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 8
                },
                "end": {
                  "line": 73,
                  "column": 8
                }
              },
              "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1, "class", "tbody__tr");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createTextNode("\n              ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "tbody__td tbody__td--md");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("            ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [13]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
              morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
              morphs[4] = dom.createMorphAt(dom.childAt(element0, [9]), 0, 0);
              morphs[5] = dom.createMorphAt(dom.childAt(element0, [11]), 0, 0);
              morphs[6] = dom.createElementMorph(element1);
              morphs[7] = dom.createMorphAt(element1, 1, 1);
              return morphs;
            },
            statements: [["inline", "check-box", [], ["id", ["subexpr", "@mut", [["get", "failedBarcode.id", ["loc", [null, [33, 19], [33, 35]]]]], [], []], "value", ["subexpr", "@mut", [["get", "failedBarcode", ["loc", [null, [34, 22], [34, 35]]]]], [], []], "collection", ["subexpr", "@mut", [["get", "stagedForRetry", ["loc", [null, [35, 27], [35, 41]]]]], [], []], "class", "tbody__td__checkbox", "changeChecked", ["subexpr", "action", ["changeChecked"], [], ["loc", [null, [37, 30], [37, 54]]]]], ["loc", [null, [32, 14], [37, 56]]]], ["block", "if", [["get", "failedBarcode.retried", ["loc", [null, [40, 20], [40, 41]]]]], [], 0, 1, ["loc", [null, [40, 14], [48, 21]]]], ["content", "failedBarcode.barcode", ["loc", [null, [50, 48], [50, 73]]]], ["content", "failedBarcode.externalSource.source", ["loc", [null, [51, 48], [51, 87]]]], ["content", "failedBarcode.errorMessage", ["loc", [null, [52, 48], [52, 78]]]], ["inline", "date-format", [["get", "failedBarcode.updatedAt", ["loc", [null, [53, 62], [53, 85]]]], "lll"], [], ["loc", [null, [53, 48], [53, 93]]]], ["element", "action", ["showTooltip", ["get", "failedBarcode.tooltipId", ["loc", [null, [54, 72], [54, 95]]]]], [], ["loc", [null, [54, 48], [54, 97]]]], ["block", "more-info", [], ["showDialog", "showDialog", "tooltipEvent", "manual", "tooltipVisibility", ["subexpr", "and", [["get", "showDialog", ["loc", [null, [58, 39], [58, 49]]]], ["subexpr", "eq", [["get", "tooltipEventId", ["loc", [null, [58, 54], [58, 68]]]], ["get", "failedBarcode.tooltipId", ["loc", [null, [58, 69], [58, 92]]]]], [], ["loc", [null, [58, 50], [58, 94]]]]], [], ["loc", [null, [58, 34], [58, 95]]]]], 2, null, ["loc", [null, [55, 14], [70, 28]]]]],
            locals: ["failedBarcode"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 6
              },
              "end": {
                "line": 74,
                "column": 6
              }
            },
            "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model", ["loc", [null, [29, 16], [29, 21]]]]], [], 0, null, ["loc", [null, [29, 8], [73, 17]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 21,
              "column": 4
            },
            "end": {
              "line": 76,
              "column": 4
            }
          },
          "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "if", [["get", "isLoading", ["loc", [null, [24, 12], [24, 21]]]]], [], 0, 1, ["loc", [null, [24, 6], [74, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 91,
                "column": 2
              },
              "end": {
                "line": 97,
                "column": 2
              }
            },
            "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "fetch-async-button", [], ["class", "btn btn--success action-btn animated fadeIn", "asyncAction", ["subexpr", "action", ["retryBarcodes"], [], ["loc", [null, [93, 18], [93, 42]]]], "default", "Retry Selected", "pending", "Processing...", "fulfilled", "Success!"], ["loc", [null, [92, 4], [96, 29]]]]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 90,
              "column": 0
            },
            "end": {
              "line": 98,
              "column": 0
            }
          },
          "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "ember-wormhole", [], ["to", "wormhole-action-btn"], 0, null, ["loc", [null, [91, 2], [97, 21]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes", "wrong-type"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 99,
            "column": 0
          }
        },
        "moduleName": "web/templates/external-transactions/failed-barcodes.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    Failed External Transactions\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "table-info margin-top--18");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "table-info__stat");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "stats");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "ital");
        var el6 = dom.createTextNode(" of  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "margin-top");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container--push-down container--centered");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [3, 1]);
        var element5 = dom.childAt(element4, [1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(element5, 1, 1);
        morphs[2] = dom.createMorphAt(element5, 2, 2);
        morphs[3] = dom.createMorphAt(element5, 6, 6);
        morphs[4] = dom.createMorphAt(element4, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element3, [7]), 1, 1);
        morphs[7] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "inline-svg", ["assets/images/icon_add-barcode"], ["class", "icon icon--25 icon--dark margin-right--12"], ["loc", [null, [3, 4], [3, 101]]]], ["block", "if", [["get", "offsetExists", ["loc", [null, [10, 14], [10, 26]]]]], [], 0, null, ["loc", [null, [10, 8], [10, 49]]]], ["content", "offsetLength", ["loc", [null, [10, 49], [10, 65]]]], ["inline", "plural-count", [["get", "model.meta.pagination.total", ["loc", [null, [10, 113], [10, 140]]]], "failed barcode", "failed barcodes"], [], ["loc", [null, [10, 98], [10, 177]]]], ["block", "if", [["get", "offsetExists", ["loc", [null, [12, 12], [12, 24]]]]], [], 1, null, ["loc", [null, [12, 6], [16, 13]]]], ["block", "ph-table", [], ["cols", ["subexpr", "@mut", [["get", "barcodeCols", ["loc", [null, [22, 11], [22, 22]]]]], [], []]], 2, null, ["loc", [null, [21, 4], [76, 17]]]], ["inline", "load-more", [], ["backToTop", ["subexpr", "action", ["backToTop"], [], ["loc", [null, [81, 16], [81, 36]]]], "loadMore", ["subexpr", "action", ["loadMore"], [], ["loc", [null, [82, 15], [82, 34]]]], "canLoadMore", ["subexpr", "@mut", [["get", "canLoadMore", ["loc", [null, [83, 18], [83, 29]]]]], [], []], "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [84, 12], [84, 17]]]]], [], []], "offsetExists", ["subexpr", "@mut", [["get", "offsetExists", ["loc", [null, [85, 19], [85, 31]]]]], [], []]], ["loc", [null, [80, 4], [85, 34]]]], ["block", "if", [["subexpr", "gt", [["get", "stagedForRetry.length", ["loc", [null, [90, 10], [90, 31]]]], 0], [], ["loc", [null, [90, 6], [90, 34]]]]], [], 3, null, ["loc", [null, [90, 0], [98, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});