define('web/helpers/to-lowercase', ['exports', 'ember'], function (exports, _ember) {
  exports.toLowercase = toLowercase;

  function toLowercase(params /*, hash*/) {
    if (_ember['default'].isPresent(params)) {
      return params.toLowerCase();
    }
  }

  exports['default'] = _ember['default'].Helper.helper(toLowercase);
});