define('web/adapters/ownership', ['exports', 'web/adapters/application', 'ember-cli-form-data/mixins/form-data-adapter'], function (exports, _webAdaptersApplication, _emberCliFormDataMixinsFormDataAdapter) {
  exports['default'] = _webAdaptersApplication['default'].extend(_emberCliFormDataMixinsFormDataAdapter['default'], {
    // Overwrite to change the request types on which Form Data is sent
    formDataTypes: ['PUT', 'PATCH'],

    updateRecord: function updateRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      serializer.serializeIntoHash(data, type, snapshot);

      var id = snapshot.id;
      var url = this.buildURL(type.modelName, id, snapshot, 'updateRecord');

      delete data.ownership.assignAllNewAttendants;

      return this.ajax(url, "PUT", {
        data: data,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    },

    createRecord: function createRecord(store, type, snapshot) {
      var data = {};
      var serializer = store.serializerFor(type.modelName);
      var url = this.buildURL(type.modelName, null, snapshot, 'createRecord');

      serializer.serializeIntoHash(data, type, snapshot, { includeId: true });

      delete data.ownership.assignAllNewAttendants;

      return this.ajax(url, "POST", {
        data: data,
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});