define("web/templates/create/exemption", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 33,
            "column": 0
          }
        },
        "moduleName": "web/templates/create/exemption.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered container--push-down");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "edit");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "edit-body");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("form");
        dom.setAttribute(el4, "class", "section-form");
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n         		");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "class", "label label--bold");
        dom.setAttribute(el7, "for", "reason");
        var el8 = dom.createTextNode("Exemption Reason:");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n           		");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "input-row");
        var el6 = dom.createTextNode("\n          ");
        dom.appendChild(el5, el6);
        var el6 = dom.createElement("div");
        dom.setAttribute(el6, "class", "input-col--100");
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createElement("label");
        dom.setAttribute(el7, "for", "region-typeahead");
        dom.setAttribute(el7, "class", "label label--bold");
        var el8 = dom.createTextNode("Lots");
        dom.appendChild(el7, el8);
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n            ");
        dom.appendChild(el6, el7);
        var el7 = dom.createComment("");
        dom.appendChild(el6, el7);
        var el7 = dom.createTextNode("\n          ");
        dom.appendChild(el6, el7);
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("\n        ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("input");
        dom.setAttribute(el5, "type", "submit");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2, 1, 1, 1]);
        var element1 = dom.childAt(element0, [7]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element0, [3, 1]), 3, 3);
        morphs[4] = dom.createMorphAt(element0, 5, 5);
        morphs[5] = dom.createAttrMorph(element1, 'class');
        morphs[6] = dom.createAttrMorph(element1, 'value');
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "link-to", ["← Back to Exemptions", "exemptions"], ["class", "back-to-list"], ["loc", [null, [1, 0], [1, 68]]]], ["element", "action", ["create"], ["on", "submit"], ["loc", [null, [6, 33], [6, 64]]]], ["inline", "textarea", [], ["id", "reason", "type", "text", "placeholder", "Type Reason Here...", "value", ["subexpr", "@mut", [["get", "model.reason", ["loc", [null, [11, 88], [11, 100]]]]], [], []], "rows", 2], ["loc", [null, [11, 13], [11, 109]]]], ["inline", "typeahead-input", [], ["selectedItems", ["subexpr", "@mut", [["get", "model.lots", ["loc", [null, [19, 28], [19, 38]]]]], [], []], "placeholder", "Add...", "typeaheadRequest", ["subexpr", "action", ["lotRequest"], [], ["loc", [null, [21, 31], [21, 52]]]], "typeaheadFocused", ["subexpr", "action", ["lotFocused"], [], ["loc", [null, [22, 31], [22, 52]]]]], ["loc", [null, [18, 12], [22, 54]]]], ["inline", "error-messages", [], ["errorMessages", ["subexpr", "@mut", [["get", "errorMessages", ["loc", [null, [26, 39], [26, 52]]]]], [], []], "clearErrorMessage", "clearErrorMessage"], ["loc", [null, [26, 8], [26, 92]]]], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "isCreated", ["loc", [null, [27, 41], [27, 50]]]], "btn--success", "btn--success"], [], ["loc", [null, [27, 36], [27, 82]]]]]]], ["attribute", "value", ["get", "btnText", ["loc", [null, [27, 92], [27, 99]]]]]],
      locals: [],
      templates: []
    };
  })());
});