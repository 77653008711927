define("web/templates/components/ph-table/testimonial-row", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 15,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/ph-table/testimonial-row.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--md");
        dom.setAttribute(el1, "style", "position: relative;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--md");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "user-image");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "user-avatar__bg");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--md");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--md");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--md");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "tbody__td--md");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [2, 1, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'style');
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [6]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(fragment, [8]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(fragment, [10]), 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["icon-badge icon-badge--small ", ["subexpr", "if", [["get", "whitelist", ["loc", [null, [2, 48], [2, 57]]]], "icon-badge--success", "icon-badge--error"], [], ["loc", [null, [2, 43], [2, 101]]]]]]], ["inline", "inline-svg", [["get", "iconPath", ["loc", [null, [3, 17], [3, 25]]]]], ["class", "icon icon--failure-reason"], ["loc", [null, [3, 4], [3, 61]]]], ["attribute", "style", ["get", "bgImage", ["loc", [null, [8, 41], [8, 48]]]]], ["content", "testimonial.name", ["loc", [null, [11, 26], [11, 46]]]], ["content", "testimonial.city", ["loc", [null, [12, 26], [12, 46]]]], ["content", "testimonial.createDate", ["loc", [null, [13, 26], [13, 52]]]], ["inline", "inline-svg", ["assets/images/arrow-down2"], ["class", "icon icon-expand-view"], ["loc", [null, [14, 26], [14, 98]]]]],
      locals: [],
      templates: []
    };
  })());
});