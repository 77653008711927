define('web/mixins/record-create', ['exports', 'ember', 'web/mixins/error-message'], function (exports, _ember, _webMixinsErrorMessage) {
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var set = _ember['default'].set;
  var run = _ember['default'].run;
  var ControllerRecordCreate = _ember['default'].Mixin.create(_webMixinsErrorMessage['default'], {
    isCreating: false,
    isCreated: false,
    errorMessages: null,
    image: null,

    supportedFileTypes: ['*.png', '*.jpeg'],

    btnText: (function () {
      var resourceText = this.get('modelName').charAt(0).toUpperCase() + this.get('modelName').slice(1);

      if (this.get('isCreated')) {
        return resourceText + ' Created!';
      } else if (this.get('isCreating')) {
        return 'Creating ' + resourceText + '...';
      }

      return 'Create ' + resourceText;
    }).property('modelName', 'isCreated', 'isCreating'),

    actions: {
      clearErrorMessage: function clearErrorMessage() {
        this.set('errorMessages', null);
      },

      scrollToBottom: function scrollToBottom() {
        _ember['default'].$('html, body').animate({
          scrollTop: document.body.scrollHeight
        }, 600);
      },

      clearSearches: function clearSearches() {
        var _this = this;

        var searches = this.get('searches');
        if (searches) {
          searches.forEach(function (s) {
            _this.set(s + 'Search', null);
          });
        }
      },

      selectImage: function selectImage(data) {
        this.set('image', data);
      },

      selectIconLogo: function selectIconLogo(data) {
        this.set('iconLogo', data);
      }
    }
  });

  exports.ControllerRecordCreate = ControllerRecordCreate;
  var RouteRecordCreate = _ember['default'].Mixin.create({
    model: function model() {
      return {};
    },

    _modelName: (function () {
      return this.create && this.create.modelName || this.routeName.slice(this.routeName.indexOf('.') + 1);
    }).property(),

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.setProperties({
        isCreated: false,
        isCreating: false,
        modelName: this.get('_modelName')
      });
    },

    _uploadImages: function _uploadImages(image, newRec) {
      var img = this.store.createRecord('image', {
        imageData: image,
        recordId: newRec.get('id'),
        modelName: newRec.get('constructor.modelName')
      });

      return img.save();
    },

    _saveValidate: function _saveValidate(resp) {
      var _this2 = this;

      this.controller.setProperties({
        isCreating: false,
        isCreated: true,
        errorMessages: null,
        image: null
      });

      this.controller.send('clearSearches');

      run.later(function () {
        if (_this2.get('model.state')) {
          _this2.set('model.state', 'Alabama');
        }

        if (_this2.controller.get('timeFrom') && _this2.controller.get('timeTo')) {
          _this2.controller.setProperties({
            parkingTimeFrom: null,
            parkingTimeTo: null,
            timeFrom: null,
            timeTo: null
          });
        }

        if (_this2.get('_modelName') === 'promotion') {
          _this2.transitionTo('home-content.details', get(resp, 'id'));
        } else {
          _this2.transitionTo(_this2.get('_modelName'), get(resp, 'id'));
        }
      }, 2000);
    },

    actions: {
      create: function create() {
        var _this3 = this;

        var vals = this.get('recordVals') || [];
        var hasManys = this.get('recordHasMany') || [];
        var belongsTo = this.get('recordBelongsTo') || [];

        var m = this.currentModel;
        var mName = this.get('_modelName');

        var newModel = getProperties(m, vals);

        var model = this.store.createRecord(mName, newModel);

        this.controller.set('isCreating', true);

        hasManys.forEach(function (k) {
          model.get(k).addObjects(get(m, k));
        });

        belongsTo.forEach(function (k) {
          set(model, k, get(m, k));
        });

        if (_ember['default'].isPresent(this.controller.get('iconLogo'))) {
          var k = this.controller.get('dataKeyOnModel');
          set(model, k, this.controller.get('iconLogo'));
        }

        if (mName === 'user' && model.get('email') === '' || model.get('email') === null) {
          model.set('email', model.get('username') + '@parkhub.com');
        }

        model.save().then(function (resp) {
          _this3.controller.send('scrollToBottom');

          if (_ember['default'].isPresent(_this3.controller.get('image'))) {
            return _this3._uploadImages(_this3.controller.get('image'), resp).then(function () {
              _this3._saveValidate(resp);
            })['catch'](function () {
              _this3.controller.set('isCreating', false);
              _this3.flashMessages.danger('The image you selected is too large.');
              model.destroyRecord();
            });
          } else {
            _this3._saveValidate(resp);
          }
        })['catch'](function (err) {
          _this3.controller.set('isCreating', false);

          if (err.message.includes('email or username already exists')) {
            _this3.flashMessages.danger('Username already exists.');
          } else {
            _this3.flashMessages.danger(err.message);
          }

          _this3.store.unloadRecord(model);
          _ember['default'].Logger.error('Server error from Create controller:', err);
          _this3.controller.send('openUnathorizedVendorModal', err);
        });
      }
    }
  });
  exports.RouteRecordCreate = RouteRecordCreate;
});