define('web/components/event-table-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['tbody__tr', 'tbody__tr--clickable'],
    classNameBindings: ['event.eventOptions.optOut:opt-out'],

    click: function click() {
      this.attrs.eventParkingTransition(this.get('event.id'));
    }
  });
});