define('web/routes/event/details', ['exports', 'ember', 'ic-ajax', 'web/mixins/reset-scroll', 'web/mixins/record-editor', 'web/config/environment'], function (exports, _ember, _icAjax, _webMixinsResetScroll, _webMixinsRecordEditor, _webConfigEnvironment) {

  /* Retrieve the metatags available from the meta-tagging-service */
  var metatags = (0, _icAjax['default'])({
    method: 'GET',
    url: _webConfigEnvironment['default'].metatagUrl + '/tags/tag.event',
    contentType: 'application/json',
    dataType: 'json',
    crossDomain: true,
    xhrFields: {
      withCredentials: true
    }
  })['catch'](function () {
    return { children: [] };
  }); // or set it to blank array if service is down

  exports['default'] = _ember['default'].Route.extend(_webMixinsResetScroll['default'], _webMixinsRecordEditor.RouteRecordEditor, {

    model: function model() {
      var eventId = this.modelFor('event').get('id');

      var hash = {
        event: this.modelFor('event'),
        images: this.modelFor('event').get('images'),
        metatags: metatags,
        selectedMetatag: (0, _icAjax['default'])({
          method: 'POST',
          url: _webConfigEnvironment['default'].metatagUrl + '/associations/tags',
          contentType: 'application/json',
          data: JSON.stringify({
            externalKeys: [eventId],
            associationType: 'PORTAL_V2_EVENT'
          }),
          dataType: 'json',
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        })['catch'](function () {
          return { tags: [] };
        }),
        additionalCharges: (0, _icAjax['default'])({
          method: 'GET',
          url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges',
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        }),
        additionalChargesUserOptions: (0, _icAjax['default'])({
          method: 'GET',
          url: _webConfigEnvironment['default'].platformAPIUrl + '/api/v1/transactions/additional-charges/user-options',
          contentType: 'application/json',
          dataType: 'json',
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          }
        })
      };

      return _ember['default'].RSVP.hash(hash).then(function (resps) {
        var defaultSettings = resps.additionalCharges.defaultSettings;
        var userOptions = resps.additionalChargesUserOptions;
        var event = resps.additionalCharges.events ? resps.additionalCharges.events.filter(function (e) {
          return e.id === eventId;
        })[0] : null;

        var selectedMetatag = resps.selectedMetatag;

        /* if statement necessary in case core-metatag is down */
        if (selectedMetatag.tags.length > 0) {
          selectedMetatag = selectedMetatag.tags[0].displayName;
        }

        if (!resps.additionalCharges.landmarks) {
          resps.additionalCharges.landmarks = [];
        }

        var includesLandmark = resps.additionalCharges.landmarks ? resps.additionalCharges.landmarks.map(function (l) {
          return l.id;
        }).includes(resps.event.get('landmarkId.id')) : false;
        var additionalChargesEnabled = userOptions.additionalChargesEnabled && includesLandmark;

        var response = {
          event: resps.event,
          images: resps.images,
          metatags: resps.metatags,
          selectedMetatag: selectedMetatag,
          additionalCharges: resps.additionalCharges,
          additionalChargesUserOptions: resps.additionalChargesUserOptions,
          additionalChargesEnabled: additionalChargesEnabled,
          applyToAllLandmarks: defaultSettings.applyToAllLandmarks,
          applyForUpcomingEvents: event ? event.transactionTypes.length > 0 : false,
          transactionTypes: _ember['default'].Object.create({
            cash: event ? event.transactionTypes.includes("cash") : false,
            credit: event ? event.transactionTypes.includes("credit") : false,
            payment: event ? event.transactionTypes.includes("payment") : false
          })
        };

        return response;
      });
    },

    afterModel: function afterModel(model, transition) {
      this._super(model, transition);

      this.set('confirming', false);
    },

    deactivate: function deactivate() {
      this._super();
      this.controller.notifyPropertyChange('timeFrom');
      this.controller.notifyPropertyChange('timeTo');
      this.controller.notifyPropertyChange('parkingTimeFrom');
      this.controller.notifyPropertyChange('parkingTimeTo');
    },

    actions: {
      invalidateModel: function invalidateModel(model) {
        var _this = this;

        /* refresh the page to ensure the updated external attributes (like metatag)
         * reflect the truth, and not what is stored in the Ember model */
        this.refresh();

        /* If there's a problem updating the metatag, tell the user.
         * NOTE: This can be a PUT or POST operation depending on if there
         * is already an associated tag. */
        var updatedMetatag = this.controllerFor('event.details').get('updatedMetatag');
        this.model('event').then(function (eventModel) {
          if (eventModel.selectedMetatag.tags[0].displayName !== updatedMetatag) {
            _this.flashMessages.danger('There was a problem updating the event type.');
          }
        });
      }
    }
  });
});