define("web/templates/components/inventory-slider", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/inventory-slider.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inventory__stats");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        dom.setAttribute(el2, "class", "inventory__text original");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createTextNode(" of ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        dom.setAttribute(el2, "class", "inventory__text__label chart-block__text__label");
        var el3 = dom.createTextNode("Sold");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inventory__sold cash");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inventory__sold cred");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inventory__sold pre");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inventory__sold exmpt");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "inventory__remaining");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var element3 = dom.childAt(fragment, [6]);
        var element4 = dom.childAt(fragment, [8]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(element0, 2, 2);
        morphs[2] = dom.createAttrMorph(element1, 'style');
        morphs[3] = dom.createAttrMorph(element2, 'style');
        morphs[4] = dom.createAttrMorph(element3, 'style');
        morphs[5] = dom.createAttrMorph(element4, 'style');
        return morphs;
      },
      statements: [["inline", "thousands-separator", [["get", "allCounts", ["loc", [null, [2, 60], [2, 69]]]]], [], ["loc", [null, [2, 38], [2, 71]]]], ["inline", "thousands-separator", [["get", "totalSpots", ["loc", [null, [2, 110], [2, 120]]]]], [], ["loc", [null, [2, 88], [2, 122]]]], ["attribute", "style", ["concat", ["flex: 0 0 ", ["get", "percentSold.cashPercent", ["loc", [null, [7, 21], [7, 44]]]], "%;\n  -webkit-flex: 0 0 ", ["get", "percentSold.cashPercent", ["loc", [null, [8, 22], [8, 45]]]], "%;\n  -ms-flexbox: 0 0 ", ["get", "percentSold.cashPercent", ["loc", [null, [9, 21], [9, 44]]]], "%;"]]], ["attribute", "style", ["concat", ["flex: 0 0 ", ["get", "percentSold.creditPercent", ["loc", [null, [11, 21], [11, 46]]]], "%;\n  -webkit-flex: 0 0 ", ["get", "percentSold.creditPercent", ["loc", [null, [12, 22], [12, 47]]]], "%;\n  -ms-flexbox: 0 0 ", ["get", "percentSold.creditPercent", ["loc", [null, [13, 21], [13, 46]]]], "%;"]]], ["attribute", "style", ["concat", ["flex: 0 0 ", ["get", "percentSold.prepaidPercent", ["loc", [null, [15, 21], [15, 47]]]], "%;\n  -webkit-flex: 0 0 ", ["get", "percentSold.prepaidPercent", ["loc", [null, [16, 22], [16, 48]]]], "%;\n  -ms-flexbox: 0 0 ", ["get", "percentSold.prepaidPercent", ["loc", [null, [17, 21], [17, 47]]]], "%;"]]], ["attribute", "style", ["concat", ["flex: 0 0 ", ["get", "percentSold.exemptPercent", ["loc", [null, [19, 21], [19, 46]]]], "%;\n  -webkit-flex: 0 0 ", ["get", "percentSold.exemptPercent", ["loc", [null, [20, 22], [20, 47]]]], "%;\n  -ms-flexbox: 0 0 ", ["get", "percentSold.exemptPercent", ["loc", [null, [21, 21], [21, 46]]]], "%;"]]]],
      locals: [],
      templates: []
    };
  })());
});