define('web/components/ph-calendar/ph-occurrence', ['exports', 'ember', 'web/components/as-calendar/occurrence'], function (exports, _ember, _webComponentsAsCalendarOccurrence) {
  var get = _ember['default'].get;
  exports['default'] = _webComponentsAsCalendarOccurrence['default'].extend({
    classNameBindings: [':as-calendar-occurrence', 'model.content.content.eventOptions.optOut:occurrence-inactive', 'occurrenceIsActive:tooltip-open'],
    layoutName: 'components/ph-calendar/ph-occurrence',
    showDialog: 'showDialog',
    duplicateStyle: null,

    occurrenceIsActive: _ember['default'].computed('content.content.id', 'dialogEvent.content.id', function () {
      return this.get('dialogEvent.content.id') === this.get('content.content.id');
    }),

    _dupLevel: 0,

    setUpOccurrenceOffset: _ember['default'].on('didInsertElement', function () {
      this._setUpOccurrenceOffset();
    }),

    _setUpOccurrenceOffset: function _setUpOccurrenceOffset() {
      var _this = this;

      var thisStartsAt = moment(get(this.model.content, 'startsAt'));
      var thisId = get(this.model.content, 'dupId');

      var allOccurrences = this.get('model.calendar').get('occurrences').map(function (o) {
        var id = get(o.content, 'dupId');
        var from = get(o.content, 'startsAt');
        var to = get(o.content, 'endsAt');

        if (id !== thisId) {
          return { name: get(o.content.content, 'name'), from: from, to: to };
        }
      });

      allOccurrences.forEach(function (o) {
        if (_ember['default'].isPresent(o)) {
          if (thisStartsAt.isAfter(get(o, 'from')) && thisStartsAt.isBefore(get(o, 'to')) || thisStartsAt.isSame(get(o, 'from'))) {
            _this.set('_dupLevel', _this.get('_dupLevel') + 1);
          }
        }
      });
    },

    idSelector: _ember['default'].computed('model.content.content.id', function () {
      return ('#' + this.get('model.content.content.id')).htmlSafe();
    }),

    _style: _ember['default'].computed('_height', '_top', '_dupLevel', function () {
      return ('top: ' + this.get('_top') + 'px;\n            margin-left: ' + 10 * this.get('_dupLevel') + 'px;\n            z-index: ' + (1000 + this.get('_dupLevel')) + ';\n            width: calc(8em - ' + 10 * this.get('_dupLevel') + 'px);\n            height: ' + this.get('_height') + 'px;').htmlSafe();
    }),

    sendShowDialog: _ember['default'].on('click', function () {
      this.attrs.showDialog(this.get('model.content'));
    })
  });
});