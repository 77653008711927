define('web/controllers/create/user', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordCreate.ControllerRecordCreate, {
    searches: ['user'],

    availRoles: ['attendant', 'operator', 'admin'],
    availOperatorRoles: ['attendant'],
    availStatuses: ['rejected', 'suspended', 'pending', 'approved', 'resumed'],

    showOperatorTypeAhead: (function () {
      if (this.get('model.role') === 'attendant') {
        return true;
      }
    }).property('model.role'),

    showCompanyName: (function () {
      var r = this.get('model.role');
      return r === 'operator' || r === 'attendant';
    }).property('model.role'),

    showTaxOption: (function () {
      var r = this.get('model.role');
      return r === 'operator' && this.get('currentUser.isAdmin');
    }).property('model.role'),

    email: computed('model.username', {
      get: function get() {
        if (_ember['default'].isPresent(this.get('model.username'))) {
          var e = this.get('model.username') + '@parkhub.com';
          this.set('model.email', e);
          return e;
        } else {
          this.set('model.email', undefined);
          return undefined;
        }
      },

      set: function set(k, email) {
        return email;
      }
    }),

    query: function query(text, selectedItems, cb, type, _ref) {
      var role = _ref.role;

      var q = text;
      var l = this.getWithDefault('limit', 5);

      var suggestions;

      var params = { search: q, limit: l, role: role };

      this.store.query(type, params).then(function (resp) {
        suggestions = resp;
        cb(suggestions);
      }, function (reason) {
        _ember['default'].Logger.error('failed to get results for', q, reason);
      });
    },

    actions: {
      create: function create() {
        var isAdmin = this.get('currentUser.isAdmin');

        if (!isAdmin) {
          this.set('model.role', 'attendant');
        }

        if (!this.get('model.role')) {
          this.flashMessages.danger('Role is required.');
          return false;
        }

        var defaultEmail = uuid.v4() + '@parkhub.com';

        this.set('model.email', defaultEmail);

        return true;
      },

      updateTaxOption: function updateTaxOption(newVal, checked, key) {
        this.set('model.taxOptionAvailable', checked);
      },

      operatorRequest: function operatorRequest(text, selectedItems, cb) {
        var r = 'operator';

        if (_ember['default'].isBlank(text)) {
          return;
        }
        this.query(text, selectedItems, cb, 'user', { role: r });
      },

      operatorFocused: function operatorFocused(curSelectedItems, cb) {
        var r = 'operator';

        if (_ember['default'].isBlank(curSelectedItems) || _ember['default'].isBlank(curSelectedItems.content)) {
          this.query('*', null, cb, 'user', { role: r });
        }
      }
    }
  });
});