define('web/components/ph-table/users-table-row', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'tr',
    classNames: ['tbody__tr'],
    classNameBindings: ['user.isPending:pending-row:tbody__tr--clickable'],

    userApprove: 'userApprove',
    userDisprove: 'userDisprove',

    click: function click() {
      if (!this.get('user.isPending') && !this.get('user').get('currentState.isSaving')) {
        this.attrs.userTransition(this.get('user'));
      }
    },

    bgImage: (function () {
      var p = this.get('user.images.firstObject.hero');
      if (p) {
        return ('background-image: url(' + p + ')').htmlSafe();
      } else {
        return 'background-image: url("assets/images/default-avatar.jpg")'.htmlSafe();
      }
    }).property('user.images'),

    actions: {
      userApprove: function userApprove(user) {
        this.sendAction('userApprove', user);
      },

      userDisprove: function userDisprove(user) {
        this.sendAction('userDisprove', user);
      }
    }
  });
});