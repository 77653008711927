define('web/models/vip-parker', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = _emberData['default'].Model.extend({
    eventId: belongsTo('event', { async: true }),
    lotIds: attr('array'),
    exemptionId: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    status: attr('string'),
    isDuplicate: attr('boolean'),
    createdAt: attr('utc'),
    updatedAt: attr('utc')
  });
});