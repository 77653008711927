define("web/router", ["exports", "ember", "web/config/environment"], function (exports, _ember, _webConfigEnvironment) {

  var Router = _ember["default"].Router.extend({
    location: _webConfigEnvironment["default"].locationType
  });

  Router.map(function () {
    this.route("home");
    this.route("error", { path: "/*path" });

    this.route("authenticated", { path: "/" }, function () {
      this.route("authenticated-apps", { resetNamespace: true }, function () {
        this.route("edit", { path: "edit/:authenticated-app_id" });
        this.route("new");
      });
      this.route("landmarks", { resetNamespace: true });
      this.route("landmark", { path: "landmarks/:landmark_id", resetNamespace: true }, function () {
        this.route("details");
        this.route("sales");
      });
      this.route("lots", { resetNamespace: true });
      this.route("additional-charges", { resetNamespace: true });
      this.route("lot", { path: "lots/:lot_id", resetNamespace: true }, function () {
        this.route("details");
        this.route("sales");
        this.route("reviews");
        this.route("pricing");
        this.route("calendar");
      });
      this.route("create", { resetNamespace: true }, function () {
        this.route("event");
        this.route("landmark");
        this.route("region");
        this.route("lot");
        this.route("user");
        this.route("promotion");
        this.route("exemption");
        this.route("permit");
      });
      this.route("regions", { resetNamespace: true });
      this.route("region", { path: "regions/:region_id", resetNamespace: true }, function () {
        this.route("details");
        this.route("sales");
      });
      this.route("home-content", { resetNamespace: true }, function () {
        this.route("details", { path: "details/:promotion_id" });
      });
      this.route("testimonials", { resetNamespace: true });
      this.route("pricing", { resetNamespace: true });

      this.route("events", { resetNamespace: true }, function () {
        this.route("list");
        this.route("calendar");
      });

      this.route("event", { path: "events/:event_id", resetNamespace: true }, function () {
        this.route("details");
        this.route("sales");
        this.route("reports", function () {
          this.route("attendants");
        });
      });
      this.route("users", { resetNamespace: true });
      this.route("user", { path: "users/:user_id", resetNamespace: true }, function () {
        this.route("details");
        this.route("sales");
        this.route("operator-cuts");
      });

      this.route("event-parkings", { resetNamespace: true });
      this.route("event-parking", { path: "event-parkings/:event_id", resetNamespace: true }, function () {
        this.route("details");
      });

      this.route("event-parking", { path: "event-parking/:event_id", resetNamespace: true });

      this.route("exemptions", { resetNamespace: true });

      this.route("exemption", { path: "exemptions/:exemption_id", resetNamespace: true }, function () {
        this.route("details");
      });
      this.route("ownerships", { resetNamespace: true });
      this.route("my-account", { resetNamespace: true });
      this.route("approve-ownerships", { resetNamespace: true });

      this.route("payment-accounts", { resetNamespace: true }, function () {
        this.route("merchant-accounts", { path: '/merchant-accounts' });
        this.route("history");
      });
      this.route("search", { resetNamespace: true });
      this.route("tags", { resetNamespace: true });

      this.route("tag-pricings", { resetNamespace: true });
      this.route("tag-pricing", { path: "tag-pricings/:tag_id", resetNamespace: true }, function () {
        this.route("details");
      });
      this.route("amenities", { resetNamespace: true });
      this.route("notifications", { resetNamespace: true });
      this.route("user-options", { resetNamespace: true });

      this.route("permits", { resetNamespace: true });
      this.route("permit", { path: "permits/:permit_id", resetNamespace: true }, function () {
        this.route("details");
      });
      this.route("external-transactions", { resetNamespace: true }, function () {
        this.route("upload");
        this.route("failed-barcodes");
        this.route("receipt-barcodes");
      });
    });
  });

  exports["default"] = Router;
});