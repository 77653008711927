define('web/serializers/external-event', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    attrs: {
      eventIdentifier: { serialize: false },
      eventNameLong: { serialize: false },
      eventTimestamp: { serialize: false },
      externalTransactionsCount: { serialize: false }
    },

    serialize: function serialize(snapshot, options) {
      var t = this._super(snapshot, options);
      var allowNull = ['eventId'];

      Object.keys(t).forEach(function (k) {
        if (t[k] === null && !allowNull.contains(k)) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});