define('web/routes/lot/calendar', ['exports', 'ember', 'ember-data'], function (exports, _ember, _emberData) {
  exports['default'] = _ember['default'].Route.extend({
    afterModel: function afterModel(model) {
      var _this = this;

      var landmarks = model.get('landmarks').mapBy('id');

      landmarks.forEach(function (id) {
        var params = {
          fromLandmark: id
        };

        if (_this.controller && _this.controller.get('dateFrom') && _this.controller.get('dateTo')) {
          params.dateFrom = _this.controller.get('dateFrom');
          params.dateTo = _this.controller.get('dateTo');
        }

        return _this.store.query('event', params).then(function (resp) {
          resp.forEach(function (event) {
            return _emberData['default'].PromiseObject.create({
              promise: event.get('landmarkId').then(function (resp) {
                event.set('landmarkId', resp);
              })
            });
          });

          model.set('events', resp);
        });
      });
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        dateFrom: null,
        dateTo: null
      });
    }
  });
});