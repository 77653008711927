define('web/services/current-user', ['exports', 'ember'], function (exports, _ember) {
  var _this = this;

  exports['default'] = _ember['default'].Service.extend({
    id: null,
    role: null,
    userOptions: {
      eventCreation: null
    },
    lastName: null,
    firstName: null,
    isAdmin: false,
    isOperator: false,
    isSubOperator: false,
    isGhosting: false,
    landmarkIds: [],
    customerId: null,
    ghostingOperators: [],
    ghostingName: null,
    hasCoreUserCookie: false,
    hasGhostingOperators: false,
    timezones: [{ short: 'PST', long: 'Pacific Standard Time' }, { short: 'PDT', long: 'Pacific Daylight Time' }, { short: 'EST', long: 'Eastern Standard Time' }, { short: 'EDT', long: 'Eastern Daylight Time' }, { short: 'MST', long: 'Mountain Standard Time' }, { short: 'MDT', long: 'Mountain Daylight Time' }, { short: 'CDT', long: 'Central Standard Time' }, { short: 'CST', long: 'Central Daylight Time' }],

    timezone: _ember['default'].computed(function () {
      var str = moment.tz(new Intl.DateTimeFormat().resolvedOptions().timeZone).format('z');

      return !str ? 'UTC' : str;
    }),

    phEnv: _ember['default'].computed(function () {
      switch (window.location.host) {
        // local
        case 'localhost:4201':
          return 'http://localhost:4200';

        // qa
        case 'qa-admin.park-hub.com':
          return 'http://qa.park-hub.com';

        // dev
        case 'dev-admin.park-hub.com':
          return 'http://dev-admin.park-hub.com';

        // staging
        case 'admin.park-hub.com':
          return 'http://admin.park-hub.com';

        // production
        case 'admin.parkhub.com':
          return 'http://parkhub.com';
      }
    }),

    reset: function reset() {
      _this.id = null;
      _this.role = null;
      _this.userOptions = null;
      _this.lastName = null;
      _this.firstName = null;
      _this.isAdmin = false;
      _this.isOperator = false;
      _this.isSubOperator = false;
      _this.isGhosting = false;
      _this.landmarkIds = [];
      _this.customerId = null;
      _this.ghostingOperators = [];
      _this.ghostingName = null;
      _this.hasGhostingOperators = false;
      _this.hasCoreUserCookie = false;
    }
  });
});