define('web/models/region', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _emberData['default'].Model.extend({
    name: _emberData['default'].attr('string'),
    zip: _emberData['default'].attr('string'),
    location: _emberData['default'].attr('object'),
    isGlobal: _emberData['default'].attr('boolean'),
    createdAt: _emberData['default'].attr('string'),
    updatedAt: _emberData['default'].attr('string'),
    radius: _emberData['default'].attr('number'),
    landmark: _emberData['default'].hasMany('landmark', { async: true }),
    live: _emberData['default'].attr('boolean'),
    lots: _emberData['default'].hasMany('lot', { async: true }),

    images: _emberData['default'].hasMany('image', { async: true }),

    trimId: computed('id', {
      get: function get() {
        var id = this.get('id');
        return id.slice(-4);
      }
    })
  });
});