define('web/serializers/order', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend(_emberData['default'].EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,

    attrs: {
      primeOrders: { embedded: 'always' },
      parkingSpots: { embedded: 'always' },
      externalOrders: { embedded: 'always' }
    },

    serialize: function serialize(order, options) {
      var t = this._super(order, options);

      Object.keys(t).forEach(function (k) {
        if (t[k] === null) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});