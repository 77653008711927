define("web/templates/approve-ownerships", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 8
            },
            "end": {
              "line": 14,
              "column": 42
            }
          },
          "moduleName": "web/templates/approve-ownerships.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(" to ");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["content", "offset", ["loc", [null, [14, 28], [14, 38]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 6
            },
            "end": {
              "line": 20,
              "column": 6
            }
          },
          "moduleName": "web/templates/approve-ownerships.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "btn--outline btn--dark btn--sm");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" 100\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element4);
          morphs[1] = dom.createMorphAt(element4, 1, 1);
          return morphs;
        },
        statements: [["element", "action", ["previousHundred"], [], ["loc", [null, [17, 54], [17, 82]]]], ["inline", "inline-svg", ["/assets/images/admin-icons/back-hundred"], ["class", "icon icon--back-hundred"], ["loc", [null, [18, 10], [18, 98]]]]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 32,
                  "column": 34
                },
                "end": {
                  "line": 32,
                  "column": 112
                }
              },
              "moduleName": "web/templates/approve-ownerships.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "truncate-text", [["get", "ownership.lotId.name", ["loc", [null, [32, 87], [32, 107]]]], 22], [], ["loc", [null, [32, 71], [32, 112]]]]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.4.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 33,
                  "column": 34
                },
                "end": {
                  "line": 33,
                  "column": 115
                }
              },
              "moduleName": "web/templates/approve-ownerships.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "truncate-text", [["get", "ownership.userId.name", ["loc", [null, [33, 89], [33, 110]]]], 22], [], ["loc", [null, [33, 73], [33, 115]]]]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.4.1",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 4
              },
              "end": {
                "line": 45,
                "column": 4
              }
            },
            "moduleName": "web/templates/approve-ownerships.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1, "class", "tbody__tr");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "tbody__td--md");
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "href", "#");
            dom.setAttribute(el3, "class", "user-status--disprove");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("a");
            dom.setAttribute(el3, "href", "#");
            dom.setAttribute(el3, "class", "user-status--approve");
            var el4 = dom.createTextNode("\n            ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [9]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element1, [3]);
            var morphs = new Array(8);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element0, [7]), 0, 0);
            morphs[4] = dom.createElementMorph(element2);
            morphs[5] = dom.createMorphAt(element2, 1, 1);
            morphs[6] = dom.createElementMorph(element3);
            morphs[7] = dom.createMorphAt(element3, 1, 1);
            return morphs;
          },
          statements: [["block", "link-to", ["lot", ["get", "ownership.lotId.id", ["loc", [null, [32, 51], [32, 69]]]]], [], 0, null, ["loc", [null, [32, 34], [32, 124]]]], ["block", "link-to", ["user", ["get", "ownership.userId.id", ["loc", [null, [33, 52], [33, 71]]]]], [], 1, null, ["loc", [null, [33, 34], [33, 127]]]], ["inline", "truncate-text", [["get", "ownership.lotId.address1", ["loc", [null, [34, 50], [34, 74]]]], 22], [], ["loc", [null, [34, 34], [34, 79]]]], ["inline", "or-dash", [["get", "ownership.lotId.region.name", ["loc", [null, [35, 44], [35, 71]]]], 2], [], ["loc", [null, [35, 34], [35, 75]]]], ["element", "action", ["ownershipDeny", ["get", "ownership", ["loc", [null, [37, 77], [37, 86]]]]], [], ["loc", [null, [37, 52], [37, 88]]]], ["inline", "inline-svg", ["/assets/images/admin-icons/blacklist-x"], ["class", "icon icon--disprove"], ["loc", [null, [38, 12], [38, 95]]]], ["element", "action", ["ownershipApprove", ["get", "ownership", ["loc", [null, [40, 79], [40, 88]]]]], [], ["loc", [null, [40, 51], [40, 90]]]], ["inline", "inline-svg", ["/assets/images/admin-icons/whitelist-check"], ["class", "icon icon--approve"], ["loc", [null, [41, 12], [41, 98]]]]],
          locals: ["ownership"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 2
            },
            "end": {
              "line": 47,
              "column": 2
            }
          },
          "moduleName": "web/templates/approve-ownerships.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "each", [["get", "model", ["loc", [null, [30, 12], [30, 17]]]]], [], 0, null, ["loc", [null, [30, 4], [45, 13]]]]],
        locals: ["model"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 58,
            "column": 0
          }
        },
        "moduleName": "web/templates/approve-ownerships.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "page-header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "page-header__title");
        var el3 = dom.createTextNode("Approve Ownerships");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "btn-group");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "container container--centered container--push-down");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "table-info");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        dom.setAttribute(el3, "class", "table-info__stat");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "stats");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "ital");
        var el6 = dom.createTextNode(" of  ");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container--push-down container--centered");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [2]);
        var element6 = dom.childAt(element5, [1, 1]);
        var element7 = dom.childAt(element6, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 3]), 1, 1);
        morphs[1] = dom.createMorphAt(element7, 1, 1);
        morphs[2] = dom.createMorphAt(element7, 2, 2);
        morphs[3] = dom.createMorphAt(element7, 6, 6);
        morphs[4] = dom.createMorphAt(element6, 3, 3);
        morphs[5] = dom.createMorphAt(element5, 3, 3);
        morphs[6] = dom.createMorphAt(dom.childAt(element5, [5]), 1, 1);
        return morphs;
      },
      statements: [["inline", "link-to", ["← Back to Lots", "lots"], ["class", "btn-group__btn"], ["loc", [null, [5, 4], [5, 62]]]], ["block", "if", [["get", "offsetExists", ["loc", [null, [14, 14], [14, 26]]]]], [], 0, null, ["loc", [null, [14, 8], [14, 49]]]], ["content", "offsetLength", ["loc", [null, [14, 49], [14, 65]]]], ["inline", "plural-count", [["get", "model.ownerships.meta.pagination.total", ["loc", [null, [14, 113], [14, 151]]]], "ownership", "ownerships"], [], ["loc", [null, [14, 98], [14, 178]]]], ["block", "if", [["get", "offsetExists", ["loc", [null, [16, 12], [16, 24]]]]], [], 1, null, ["loc", [null, [16, 6], [20, 13]]]], ["block", "ph-table", [], ["model", ["subexpr", "@mut", [["get", "model.ownerships", ["loc", [null, [25, 10], [25, 26]]]]], [], []], "hasBlock", true, "cols", ["subexpr", "@mut", [["get", "ownershipCols", ["loc", [null, [27, 9], [27, 22]]]]], [], []], "isLoading", ["subexpr", "@mut", [["get", "isLoading", ["loc", [null, [28, 14], [28, 23]]]]], [], []]], 2, null, ["loc", [null, [24, 2], [47, 15]]]], ["inline", "load-more", [], ["backToTop", ["subexpr", "action", ["backToTop"], [], ["loc", [null, [51, 16], [51, 36]]]], "loadMore", ["subexpr", "action", ["loadMore"], [], ["loc", [null, [52, 15], [52, 34]]]], "canLoadMore", ["subexpr", "@mut", [["get", "canLoadMore", ["loc", [null, [53, 18], [53, 29]]]]], [], []], "model", ["subexpr", "@mut", [["get", "model.ownerships", ["loc", [null, [54, 12], [54, 28]]]]], [], []], "offsetExists", ["subexpr", "@mut", [["get", "offsetExists", ["loc", [null, [55, 19], [55, 31]]]]], [], []]], ["loc", [null, [50, 4], [55, 34]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});