define('web/routes/create/region', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['name', 'zip', 'radius', 'location'],
    recordAsyncs: [],

    deactivate: function deactivate() {
      this.controller.setProperties({
        errorMessages: null
      });
    }
  });
});