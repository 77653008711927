define('web/helpers/money-format', ['exports', 'ember'], function (exports, _ember) {
  exports.moneyFormat = moneyFormat;

  function moneyFormat(amount) {
    if (amount === null) {
      return '$0';
    }
    amount = (amount / 100).toFixed(2);

    var delimiter = ','; // replace comma if desired
    var a = amount.split('.', 2);
    var d = a[1];
    var i = parseInt(a[0]);
    if (isNaN(i)) {
      return '';
    }
    var minus = '';
    if (i < 0) {
      minus = '-';
    }
    i = Math.abs(i);
    var n = i.toString();
    a = [];
    while (n.length > 3) {
      var nn = n.substr(n.length - 3);
      a.unshift(nn);
      n = n.substr(0, n.length - 3);
    }
    if (n.length > 0) {
      a.unshift(n);
    }
    n = a.join(delimiter);
    if (d.length < 1) {
      amount = n;
    } else {
      amount = n + '.' + d;
    }
    amount = minus + amount;
    return '$' + amount;
  }

  exports['default'] = _ember['default'].Helper.helper(moneyFormat);
});