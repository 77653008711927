define('web/controllers/user/operator-cuts', ['exports', 'ember'], function (exports, _ember) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      changeParkhub: function changeParkhub(ph) {
        var model = get(this.get('model'), 'operatorCut');
        set(model, 'parkhub', ph);
      },

      changePrime: function changePrime(pr) {
        var model = get(this.get('model'), 'operatorCut');
        set(model, 'prime', pr);
      },

      saveOperatorCuts: function saveOperatorCuts(cb) {
        var _this = this;

        var operatorCut = get(this.get('model'), 'operatorCut');
        var promise = operatorCut.save();
        cb(promise);

        promise.then(function () {
          return _this.flashMessages.success('Successfully updated operator cuts.');
        })['catch'](function () {
          return _this.flashMessages.danger('There was an error updating operator cuts.');
        });
      }
    }
  });
});