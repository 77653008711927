define('web/components/stripe-input', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var isBlank = _ember['default'].isBlank;
  exports['default'] = _ember['default'].TextField.extend({
    classNameBindings: ['errorMessages:error'],
    cents: null,

    errorMessages: null,

    input: function input(e) {
      this.changeValue(e.target.value);
    },

    changeValue: function changeValue(val) {
      var cents = val * 100;

      this.attrs.setCents(parseInt(Math.round(cents)));
    },

    value: computed('cents', function (name, newVal) {
      if (newVal !== undefined) {
        this.set('errorMessages', null);
        if (isBlank(newVal)) {
          this.attrs.setCents(0);
          return newVal;
        }

        var moneyPieces = newVal.split('.');

        if (moneyPieces.length > 0 && moneyPieces.length < 3) {
          this.set('errorMessages', null);
          var dollarsPiece = moneyPieces[0];
          if (isBlank(dollarsPiece)) {
            dollarsPiece = '0';
          }

          var dollarsInt = parseInt(dollarsPiece);
          if (dollarsInt === dollarsInt) {
            this.set('errorMessages', null);
            var centsPiece = moneyPieces[1];
            if (isBlank(centsPiece)) {
              centsPiece = '0';
            }

            if (centsPiece.length > 0 && centsPiece.length < 3) {
              this.set('errorMessages', null);
              var centsInt = parseInt(centsPiece);
              if (centsInt === centsInt) {
                this.set('errorMessages', null); // is NAN
                var newCents = parseInt(dollarsPiece) * 100 + parseInt(centsPiece);
                this.attrs.setCents(newCents);

                return newVal;
              } else {
                // is NAN
                // TODO: cents formatting error
                this.set('errorMessages', ['Cents - formatting error']);
              }
            } else {
              // TODO: cents length error
              this.set('errorMessages', ['Cents - length error']);
            }
          } else {
            // is NAN
            // TODO: dollar formatting error
            this.set('errorMessages', ['Dollars - formatting error']);
          }
        } else {
          // TODO: too many decimals error
          this.set('errorMessages', ['Too many decimals']);
        }
      }
      if (newVal && isNaN(newVal)) {
        this.set('errorMessages', ['Not a Number.']);
      }

      var cents = this.get('cents');
      return (parseInt(cents) / 100).toFixed(2);
    })
  });
});