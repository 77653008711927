define('web/components/sales-summary-chart', ['exports', 'ember', 'web/helpers/money-format'], function (exports, _ember, _webHelpersMoneyFormat) {
  var computed = _ember['default'].computed;

  var BASE_CHART_CONFIG = {
    legend: { show: true },
    zoom: { enabled: true },
    axis: {
      x: {
        type: 'timeseries',
        tick: { culling: false }
      },
      y: {
        tick: {
          format: function format(y) {
            return (0, _webHelpersMoneyFormat.moneyFormat)(y);
          }
        },
        show: false
      }
    },
    bar: {
      width: { ratio: 0.6 }
    }
  };

  exports['default'] = _ember['default'].Component.extend({
    classNames: ['chart-block__group'],
    viewBy: 'week',

    isWeek: _ember['default'].computed.equal('viewBy', 'week'),
    isMonth: _ember['default'].computed.equal('viewBy', 'month'),

    barConfig: computed('data.[]', 'isWeek', 'isMonth', {
      get: function get() {
        var config = _ember['default'].copy(BASE_CHART_CONFIG);
        if (this.get('isWeek')) {
          config.axis.x.tick.format = function (x) {
            return moment(x).format('MM/DD/YYYY');
          };
        } else {
          config.axis.x.tick.format = function (x) {
            return moment(x).format('MMMM YYYY');
          };
        }

        return config;
      }
    }),

    barData: computed('data.[]', 'viewBy', {
      get: function get() {
        var json = this.get('data').map(function (report) {
          return {
            'localDate': moment(report.get('localDate')).add(12, 'h'),
            'Prime': report.get('primeNet'),
            'ParkHub.com': report.get('parkhubNet')
          };
        });

        return {
          'json': json,
          colors: {
            'Prime': '#8EC5E0',
            'Parkhub': '#084f7d'
          },
          keys: {
            x: 'localDate',
            value: ['Prime', 'ParkHub.com']
          },
          type: 'bar',
          flag: this.get('viewBy')
        };
      }
    }),

    actions: {
      toggle: function toggle(direction, data) {
        this.attrs.toggle(direction, data);
      },
      setViewBy: function setViewBy(e) {
        this.set('viewBy', e);
        this.attrs.changeView(e);
      },
      goToToday: function goToToday() {
        this.set('viewBy', 'week');
        this.attrs.goToToday();
      },

      transitionToReports: function transitionToReports() {
        var from = this.get('viewBy') === 'month' ? moment(this.get('data.lastObject.localDate')).add(8, 'h').startOf('month').toJSON() : moment(this.get('data.firstObject.localDate')).add(8, 'h').toJSON();
        var to = this.get('viewBy') === 'month' ? moment(this.get('data.lastObject.localDate')).add(8, 'h').endOf('month').toJSON() : moment(this.get('data.lastObject.localDate')).add(8, 'h').toJSON();

        this.attrs.transitionToReports(from, to);
      }
    }
  });
});