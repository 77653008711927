define('web/models/external-validation', ['exports', 'ember-data'], function (exports, _emberData) {
  var attr = _emberData['default'].attr;
  exports['default'] = _emberData['default'].Model.extend({
    landmarkId: attr('string'),
    lotId: attr('string'),
    externalId: attr('string'),
    externalValidationTypeId: attr('string'),
    uri: attr('string'),
    enabled: attr('boolean'),
    isParking: attr('boolean')
  });
});