define('web/routes/create/exemption', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['reason'],
    recordHasMany: ['lots'],

    model: function model() {
      return {
        lots: []
      };
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        errorMessages: null
      });
    }
  });
});