define('web/components/check-box', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    attributeBindings: ['type', 'value', 'disabled'],
    tagName: 'input',
    type: 'checkbox',
    checked: false,

    _checkboxSetup: false,

    didRender: function didRender() {
      this._super();
      if (!this.get('_checkboxSetup')) {
        this._setupCheckbox();
        this.set('_checkboxSetup', true);
      }
    },

    didUpdateAttrs: function didUpdateAttrs() {
      if (_ember['default'].isPresent(this.attrs.collection)) {
        return this._reevaluateExistsInCollection();
      }

      this.$().prop('checked', this.attrs.value.value);
    },

    _setupCheckbox: function _setupCheckbox() {
      if (_ember['default'].isPresent(this.attrs.collection)) {
        var collectionHasVal = this.get('collection').contains(this.get('value'));
        this.$().prop('checked', collectionHasVal);
        this._updateElementValue();
      } else if (this.get('value')) {
        this.$().prop('checked', true);
        this._updateElementValue();
      }
    },

    _updateElementValue: function _updateElementValue() {
      this.set('checked', this.$().prop('checked'));
    },

    _reevaluateExistsInCollection: function _reevaluateExistsInCollection() {
      var collectionHasVal = this.get('collection').contains(this.get('value'));
      this.$().prop('checked', collectionHasVal);
      this._updateElementValue();
    },

    change: function change() {
      this._updateElementValue();
      this.attrs.changeChecked(this.get('value'), this.get('checked'), this.get('key'));
    }
  });
});