define('web/components/c3-chart', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['ph-chart'],
    classNameBindings: ['data.type'],

    _type: null,
    _unloadFlag: null,

    _chart: undefined,

    didRender: function didRender() {
      this._super();
      var unloadType = this.get('data.type') !== this.get('_type');
      var unloadFlag = this.get('data.flag') !== this.get('_unloadFlag');

      if (unloadType || unloadFlag) {
        this.set('_chart', undefined);
      }

      var chart = this.get('chart');
      this.set('_type', this.get('data.type'));
      this.set('_unloadFlag', this.get('data.flag'));

      var data = this.get('data') || { json: [] };
      chart.load(data);
    },

    data: computed({
      get: function get() {
        return { json: [], type: 'area' };
      },
      set: function set(k, newData) {
        return newData;
      }
    }),

    chart: computed('_chart', 'data', 'config.[]', {
      get: function get() {
        if (_ember['default'].isEqual(this.get('_chart'), undefined)) {
          var container = this.get('element');

          if (_ember['default'].isEqual(container, undefined)) {
            return undefined;
          }

          var config = this.get('config');
          config['data'] = this.get('data') || { json: [] };
          config['bindto'] = container;
          var chart = c3.generate(config);
          this.set('_chart', chart);
          return chart;
        }

        return this.get('_chart');
      }
    }),

    willDestroyElement: function willDestroyElement() {
      this._super();
      _ember['default'].set(this, 'element', undefined);
    }
  });
});
/* globals c3 */