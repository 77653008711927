define('web/routes/exemption/details', ['exports', 'ember', 'web/mixins/record-editor'], function (exports, _ember, _webMixinsRecordEditor) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordEditor.RouteRecordEditor, {
    afterModel: function afterModel(model) {
      model.get('lots').then(function () {
        return model.save();
      });
    },

    deactivate: function deactivate() {
      if (this.currentModel.get('hasDirtyAttributes')) {
        this.currentModel.rollbackAttributes();
      }
    }
  });
});