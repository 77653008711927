define('web/torii-providers/parkhub', ['exports', 'ember', 'ic-ajax', 'web/config/environment'], function (exports, _ember, _icAjax, _webConfigEnvironment) {
  exports['default'] = _ember['default'].Object.extend({
    // credentials as passed from torii.open
    open: function open(credentials) {
      return (0, _icAjax['default'])({
        method: 'POST',
        url: _webConfigEnvironment['default'].apiUrl + '/api/v2/session',
        data: {
          user: {
            email: credentials.email,
            password: credentials.password
          }
        },
        crossDomain: true,
        xhrFields: {
          withCredentials: true
        }
      });
    }
  });
});