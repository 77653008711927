define('web/components/load-more', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['flex-container', 'flex-container--centered'],
    scrollBound: false,
    scrolled: false,

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      if (!this.get('scrollBound')) {

        _ember['default'].$(window).bind('scroll', function () {
          if (document.body.scrollHeight === document.body.scrollTop + window.innerHeight) {

            _ember['default'].run.debounce(_this, _this._didEnterViewport, 300);
          }
        });

        this.set('scrollBound', true);
      }
    },

    _didEnterViewport: function _didEnterViewport() {
      var _this2 = this;

      var top = window.pageYOffset || document.documentElement.scrollTop;
      if (top > 0 && parseInt(this.get('model.length')) > 19) {
        this.set('scrolled', true);

        _ember['default'].run.next(function () {
          _ember['default'].$(window).on('scroll', function () {
            if (document.body.scrollTop === 0) {
              _this2.set('scrolled', false);
            }
          });
        });
      }
    },

    unbindScroll: (function () {
      _ember['default'].$(window).unbind('scroll');
    }).on('willDestroyElement'),

    actions: {
      backToTop: function backToTop() {
        this.set('scrolled', false);
        this.attrs.backToTop();
      },

      loadMore: function loadMore() {
        this.attrs.loadMore();
      }
    }
  });
});