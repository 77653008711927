define('web/routes/create/permit', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsRecordCreate.RouteRecordCreate, {
    recordVals: ['internalId', 'barcode', 'firstName', 'lastName', 'email', 'type', 'phone', 'affiliation', 'classification', 'classificationSub', 'active', 'validFrom', 'validTo'],
    recordHasMany: ['lots'],

    model: function model() {
      return {
        lots: []
      };
    }
  });
});