define('web/mixins/serialize-record', ['exports', 'ember'], function (exports, _ember) {
  exports.serialize = serialize;

  function serialize(store, modelName, record) {
    var model = store.modelFor(modelName);
    var serializer = store.serializerFor(modelName);

    store.push(serializer.normalize(model, record));

    return store.peekRecord(modelName, _ember['default'].get(record, 'id'));
  }
});