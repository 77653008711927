define('web/serializers/tag', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    serialize: function serialize(snapshot, options) {
      var t = this._super(snapshot, options);

      var recordIsNew = snapshot.record.get('isNew');
      var attrsToStayNull = ['landmarkId'];

      Object.keys(t).forEach(function (k) {
        var attrShouldStayNull = attrsToStayNull.contains(k);
        var attrIsNull = t[k] === null;

        var shouldRemove = attrIsNull && (!attrShouldStayNull || recordIsNew);

        if (shouldRemove) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});