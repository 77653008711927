define('web/helpers/sort-numbers', ['exports', 'ember'], function (exports, _ember) {
  exports.sortNumbers = sortNumbers;

  function sortNumbers(params, dir) {
    return params.sort(function (a, b) {
      if (a === b) {
        return 0;
      } else if (a < b) {
        return dir === 'asc' ? -1 : 1;
      } else {
        return dir === 'asc' ? 1 : -1;
      }
    }).filter(function (n) {
      return !isNaN(n);
    });
  }

  exports['default'] = _ember['default'].Helper.helper(sortNumbers);
});