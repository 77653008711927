define('web/routes/search', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/mixins/loading-model', 'web/mixins/build-params'], function (exports, _ember, _webMixinsResetScroll, _webMixinsLoadingModel, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadingModel.RouteLoadingMixin, _webMixinsResetScroll['default'], {
    queryParams: {
      q: { refreshModel: true },
      type: { refreshModel: true },
      limit: { refreshModel: true },
      offset: { refreshModel: true }
    },

    model: function model(params) {
      var _this = this;

      return this.store.query('searchResult', (0, _webMixinsBuildParams.filterParams)(params)).then(function (resp) {
        return resp.get('firstObject');
      })['catch'](function (err) {
        return _this.flashMessages.danger(err.message);
      });
    },

    deactivate: function deactivate() {
      this.controller.setProperties({
        type: null,
        q: null,
        searchVal: null
      });
    }
  });
});