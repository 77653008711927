define('web/components/svg-icon', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'svg',
    classNames: ['icon'],
    classNameBindings: ['cls'],

    cls: (function () {
      return 'icon-' + this.get('type');
    }).property('type'),

    icon: (function () {
      return '#' + this.get('cls');
    }).property('cls')
  });
});