define('web/helpers/get-min-max', ['exports', 'ember'], function (exports, _ember) {
  exports.getMinMax = getMinMax;

  function getMinMax(arrOfNumbers, minMax) {
    if (minMax === 'min') {
      if (!_ember['default'].isEmpty(arrOfNumbers)) {
        return arrOfNumbers.reduce(function (p, c) {
          return p > c ? c : p;
        });
      }
    }
    if (minMax === 'max') {
      if (!_ember['default'].isEmpty(arrOfNumbers)) {
        return arrOfNumbers.reduce(function (p, c) {
          return p > c ? p : c;
        });
      }
    }
    return arrOfNumbers;
  }

  exports['default'] = _ember['default'].Helper.helper(getMinMax);
});