define('web/controllers/create/promotion', ['exports', 'ember', 'web/mixins/record-create'], function (exports, _ember, _webMixinsRecordCreate) {
  var computed = _ember['default'].computed;
  exports['default'] = _ember['default'].Controller.extend(_webMixinsRecordCreate.ControllerRecordCreate, {
    // ------------------- For adding icon to promotion
    imgKeyOnModel: 'icon',
    dataKeyOnModel: 'iconData',
    // -------------------

    link: computed('model.link', {
      get: function get() {
        return this.get('model.link');
      },
      set: function set(key, newVal) {
        var n = newVal;
        if (n.search(/^http[s]?\:\/\//) === -1) {
          n = 'http://' + n;
        }
        this.set('model.link', n);
        return n;
      }
    })
  });
});