define('web/models/report', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].Model.extend({
    date: _emberData['default'].attr('utc'),
    primeNet: _emberData['default'].attr('number'),
    parkhubNet: _emberData['default'].attr('number'),
    range: _emberData['default'].attr('string'),
    localDate: _emberData['default'].attr('string'),

    timeStamp: _emberData['default'].attr('date'),
    cashTotals: _emberData['default'].attr('number'),
    cashCounts: _emberData['default'].attr('number'),
    creditTotals: _emberData['default'].attr('number'),
    creditCounts: _emberData['default'].attr('number'),
    prepaidTotals: _emberData['default'].attr('number'),
    paymentTotals: _emberData['default'].attr('number'),
    prepaidCounts: _emberData['default'].attr('number'),
    paymentCounts: _emberData['default'].attr('number'),
    exemptTotals: _emberData['default'].attr('number'),
    exemptCounts: _emberData['default'].attr('number'),

    totalRevenue: (function () {
      return this.get('cashTotals') + this.get('creditTotals') + this.get('exemptTotals') + this.get('prepaidTotals') + this.get('paymentTotals');
    }).property('cashTotals', 'creditTotals', 'exemptTotals', 'prepaidTotals', 'paymentTotals'),

    totalCounts: (function () {
      return this.get('cashCounts') + this.get('creditCounts') + this.get('exemptCounts') + this.get('prepaidCounts') + this.get('paymentCounts');
    }).property('cashCounts', 'creditCounts', 'exemptCounts', 'prepaidCounts', 'paymentCounts'),

    total: (function () {
      var phN = this.get('parkhubNet');
      var pN = this.get('primeNet');

      return phN + pN;
    }).property('primeNet', 'parkhubNet')
  });
});