define('web/controllers/home', ['exports', 'ember', 'web/config/environment'], function (exports, _ember, _webConfigEnvironment) {
  var set = _ember['default'].set;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['return_to'],
    authenticated: _ember['default'].inject.controller(),
    cookies: _ember['default'].inject.service('cookies'),
    rememberMe: false,

    email: null,
    password: null,

    actions: {
      changeUsername: function changeUsername(un) {
        set(this, 'email', un);
      },

      changePassword: function changePassword(pw) {
        set(this, 'password', pw);
      },

      changeRememberMe: function changeRememberMe(val, checked) {
        if (checked) {
          set(this, 'rememberMe', true);
        } else {
          set(this, 'rememberMe', false);
        }
      },

      signInViaParkhub: function signInViaParkhub(callback) {
        var _this = this;

        var coreCookieCheck = this.get('cookies').read(_webConfigEnvironment['default'].coreCookieKey);

        // If the cookie environment doesn't match delete it before signing in.
        if (coreCookieCheck) {
          var cookiePayload = window.jwt_decode(coreCookieCheck).payload;
          var cookieEnvironmentMatches = cookiePayload.ne === _webConfigEnvironment['default'].environment;

          if (!cookieEnvironmentMatches || !cookiePayload.lk) {
            this.get('cookies').clear(_webConfigEnvironment['default'].coreCookieKey, {
              domain: '.parkhub.com',
              path: '/'
            });
          }
        }

        var promise = this.get('session').open('parkhub', {
          email: get(this, 'email'),
          password: get(this, 'password')
        });

        if (!get(this, 'rememberMe')) {
          localStorage.removeItem('username');
        } else {
          localStorage.setItem('username', get(this, 'email'));
        }

        callback(promise);

        promise.then(function (resp) {
          document.cookie = '_gtmUID=' + resp.currentUser.customerId;

          var user = resp.currentUser;

          _this.flashMessages.success('Welcome, ' + user.firstName + ' you have been signed in successfully!');

          _this.get('currentUser').setProperties({
            id: user.id,
            role: user.role,
            userOptions: user.userOptions,
            lastName: user.lastName,
            firstName: user.firstName,
            isAdmin: user.role === 'admin' ? true : false,
            isOperator: user.role === 'operator' ? true : false,
            isSubOperator: user.role === 'subOperator' ? true : false
          });

          _this.setProperties({
            email: null,
            password: null
          });

          _this.get('authenticated').notifyPropertyChange('ownershipsPending');

          if (_this.get('currentUser.savedTransition')) {
            _this.get('currentUser.savedTransition').retry();
            _this.get('currentUser').set('savedTransition', null);
          } else {
            _this.transitionToRoute('/');
          }
        })['catch'](function (err) {
          _this.flashMessages.danger('Invalid e-mail or password provided.');
          _ember['default'].Logger.error('Error returning session: ', err);
        });
      }
    }
  });
});