define('web/serializers/bank-account', ['exports', 'ember-data'], function (exports, _emberData) {
  exports['default'] = _emberData['default'].RESTSerializer.extend({
    isNewSerializerAPI: true,

    attrs: {
      admin: { serialize: false },
      balance: { serialize: false },
      isDefault: { serialize: false },
      userId: { serialize: false }
    },

    serializeAttribute: function serializeAttribute(snapshot, json, key, attributes) {
      if (snapshot.changedAttributes()[key] || snapshot.record.get('isNew')) {
        //TODO: is new && occurs in payload (booleans pass)

        var t = this._super(snapshot, json, key, attributes);

        Object.keys(json).forEach(function (k) {
          if (json[k] === null) {
            json[k] = undefined;
          }
        });

        return t;
      } else {
        return;
      }
    },

    serialize: function serialize(snapshot, options) {
      var t = this._super(snapshot, options);

      Object.keys(t).forEach(function (k) {
        if (t[k] === null) {
          t[k] = undefined;
        }
      });

      return t;
    }
  });
});