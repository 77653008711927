define('web/routes/landmarks', ['exports', 'ember', 'web/mixins/reset-scroll', 'web/mixins/records-search', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/build-params'], function (exports, _ember, _webMixinsResetScroll, _webMixinsRecordsSearch, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadingModel.RouteLoadingMixin, _webMixinsRecordsSearch.RouteSearchMixin, _webMixinsResetScroll['default'], _webMixinsLoadMore.RouteLoadMore, {
    queryParams: {
      region: {
        refreshModel: true
      },
      search: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var _this = this;

      if (params.search) {
        params.getAll = true;
      }

      return this.store.query('landmark', (0, _webMixinsBuildParams.filterParams)(params))['catch'](function (err) {
        return _this.flashMessages.danger(err.message);
      });
    }
  });
});