define('web/components/search-result-item', ['exports', 'ember'], function (exports, _ember) {
  var computed = _ember['default'].computed;
  var equal = _ember['default'].computed.equal;
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'li',
    classNames: ['search-results__item'],

    isUser: equal('type', 'user'),

    resultImage: computed('model.images', function () {
      var p = this.get('model.images.firstObject.hero');
      if (p) {
        return ('background-image: url(' + p + ')').htmlSafe();
      } else {
        return 'background-image: url(assets/images/placeholder-md.jpg)'.htmlSafe();
      }
    }),

    firstLast: computed('model.firstName', 'model.lastName', function () {
      return (this.get('model.firstName') + ' ' + this.get('model.lastName')).htmlSafe();
    })
  });
});