define('web/controllers/amenities', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    amenityCols: [{ colName: 'name' }, {}],
    modifyAmenity: _ember['default'].inject.controller('modals.modify-amenity'),

    actions: {
      modifyAmenity: function modifyAmenity(model, type) {
        this.get('modifyAmenity').setProperties({
          model: model,
          type: type
        });

        this.send('openModal', 'modify-amenity');
      }
    }
  });
});