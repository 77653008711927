define("web/templates/components/lot-selling-cube", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "fragmentReason": {
            "name": "triple-curlies"
          },
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "web/templates/components/lot-selling-cube.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["oversell-notification circle-notification circle-notification--error ", ["subexpr", "if", [["get", "exiting", ["loc", [null, [2, 89], [2, 96]]]], "exiting"], [], ["loc", [null, [2, 84], [2, 108]]]]]]]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.4.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 0
            },
            "end": {
              "line": 35,
              "column": 0
            }
          },
          "moduleName": "web/templates/components/lot-selling-cube.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "lot-selling-cube/event-row", [], ["reservation", ["subexpr", "@mut", [["get", "reservation", ["loc", [null, [31, 16], [31, 27]]]]], [], []], "lot", ["subexpr", "@mut", [["get", "lot", ["loc", [null, [32, 8], [32, 11]]]]], [], []], "saveEventOptions", ["subexpr", "action", ["saveEventOptions"], [], ["loc", [null, [33, 21], [33, 48]]]], "transitionToEvent", ["subexpr", "action", ["transitionToEvent"], [], ["loc", [null, [34, 22], [34, 50]]]]], ["loc", [null, [30, 2], [34, 52]]]]],
        locals: ["reservation"],
        templates: []
      };
    })();
    return {
      meta: {
        "fragmentReason": {
          "name": "missing-wrapper",
          "problems": ["wrong-type", "multiple-nodes"]
        },
        "revision": "Ember@2.4.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "web/templates/components/lot-selling-cube.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "event-parking__lot-inventory");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "event-parking__img");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "event-parking__info");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h5");
        dom.setAttribute(el3, "class", "event-parking__stat");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "event-parking__breakdown");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "uppercase cash");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Cash");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "uppercase cred");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Cred");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "uppercase pre");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Pre");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "uppercase exmpt");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" Exmpt");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element3, [5]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createAttrMorph(element2, 'style');
        morphs[2] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [5]), 0, 0);
        morphs[7] = dom.createMorphAt(dom.childAt(element4, [7]), 0, 0);
        morphs[8] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showNotification", ["loc", [null, [1, 6], [1, 22]]]]], [], 0, null, ["loc", [null, [1, 0], [3, 7]]]], ["attribute", "style", ["get", "bgImage", ["loc", [null, [6, 42], [6, 49]]]]], ["inline", "truncate-text", [["get", "lot.name", ["loc", [null, [8, 52], [8, 60]]]], 35], [], ["loc", [null, [8, 36], [8, 65]]]], ["inline", "inventory-slider", [], ["record", ["subexpr", "@mut", [["get", "lot", ["loc", [null, [11, 13], [11, 16]]]]], [], []], "totalSpots", ["subexpr", "@mut", [["get", "lot.totalSpots", ["loc", [null, [12, 17], [12, 31]]]]], [], []], "cashCounts", ["subexpr", "@mut", [["get", "lot.report.cashCounts", ["loc", [null, [13, 17], [13, 38]]]]], [], []], "creditCounts", ["subexpr", "@mut", [["get", "lot.report.creditCounts", ["loc", [null, [14, 19], [14, 42]]]]], [], []], "prepaidCounts", ["subexpr", "@mut", [["get", "lot.report.prepaidCounts", ["loc", [null, [15, 20], [15, 44]]]]], [], []], "exemptCounts", ["subexpr", "@mut", [["get", "lot.report.exemptCounts", ["loc", [null, [16, 19], [16, 42]]]]], [], []], "allCounts", ["subexpr", "@mut", [["get", "allCounts", ["loc", [null, [17, 16], [17, 25]]]]], [], []], "setOversell", ["subexpr", "action", ["setOversell"], [], ["loc", [null, [18, 18], [18, 40]]]]], ["loc", [null, [10, 4], [18, 42]]]], ["content", "lot.report.cashCounts", ["loc", [null, [21, 32], [21, 57]]]], ["content", "lot.report.creditCounts", ["loc", [null, [22, 32], [22, 59]]]], ["content", "lot.report.prepaidCounts", ["loc", [null, [23, 31], [23, 59]]]], ["content", "lot.report.exemptCounts", ["loc", [null, [24, 33], [24, 60]]]], ["block", "each", [["get", "lot.reservations", ["loc", [null, [29, 8], [29, 24]]]]], [], 1, null, ["loc", [null, [29, 0], [35, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});