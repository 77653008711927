define('web/breakpoints', ['exports'], function (exports) {
  exports['default'] = {
    palm: '(max-width: 480px)',
    lap: '(min-width: 481px) and (max-width: 1023px)',
    smallportable: '(max-width: 767px)',
    portable: '(max-width: 1023px)',
    mid: '(min-width: 768px)',
    desk: '(min-width: 1024px)',
    ipad: '(min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait)',
    xlarge: '(min-width: 1440px)',
    print: 'print',
    navSmall: '(max-width: 670px), (max-height: 678px)'
  };
});