define('web/components/map-box-marker', ['exports', 'web/config/environment', 'ember', 'web/mixins/has-map-parent'], function (exports, _webConfigEnvironment, _ember, _webMixinsHasMapParent) {

  var L = window.L; // TODO: would be nice to import this

  var computed = _ember['default'].computed;
  var copy = _ember['default'].copy;
  var get = _ember['default'].get;
  var isBlank = _ember['default'].isBlank;
  var observer = _ember['default'].observer;
  exports['default'] = _ember['default'].Component.extend(_webMixinsHasMapParent['default'], {
    tagName: computed('template', function () {
      return this.get('template') ? 'div' : '';
    }),

    coordinates: null,
    markerOptions: get(_webConfigEnvironment['default'], 'mapbox.markerOptions') || null,
    popupOptions: get(_webConfigEnvironment['default'], 'mapbox.popupOptions') || null,

    isPopupOpen: false,
    selectedIcon: null,

    _markerOptions: computed('markerOptions', function () {
      var markerOptions = this.get('markerOptions');
      if (markerOptions) {
        if (markerOptions.icon instanceof L.Icon) {
          return markerOptions;
        } else {
          var markerOptionsCopy = copy(markerOptions);
          markerOptionsCopy.icon = L.icon(markerOptions.icon);
          return markerOptionsCopy;
        }
      }

      return null;
    }),

    _curMarker: null,

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      var contentLayer = this.get('contentLayer');
      if (contentLayer) {
        var curMarker = this.get('_curMarker');
        if (curMarker) {
          contentLayer.removeLayer(curMarker);
          this.set('_curMarker', null);
        }

        if (this.get('template')) {
          contentLayer.off('popupopen', this._onPopupOpened, this);
          contentLayer.off('popupclose', this._onPopupClosed, this);
        }
      }
    },

    _observeCoordinates: observer('contentLayer', 'coordinates', '_markerOptions', function () {
      _ember['default'].run.once(this, this._updateCoordinates);
    }).on('init'),

    _updateCoordinates: function _updateCoordinates() {
      var contentLayer = this.get('contentLayer');
      if (!contentLayer) {
        return;
      }

      var curMarker = this.get('_curMarker');
      if (curMarker) {
        contentLayer.removeLayer(curMarker);
        this.set('_curMarker', null);
      }

      var coordinates = this.get('coordinates');
      if (isBlank(coordinates)) {
        return;
      }

      var marker = L.marker(coordinates, this.get('_markerOptions'));
      this.set('marker', marker);

      if (this.get('template')) {
        marker.bindPopup(this.element, this.get('popupOptions'));

        contentLayer.on('popupopen', this._onPopupOpened, this);
        contentLayer.on('popupclose', this._onPopupClosed, this);
      }

      marker.addTo(contentLayer);

      this.set('_curMarker', marker);

      this.get('map').contentChanged();
    },

    _onPopupOpened: function _onPopupOpened(e) {
      if (e.popup.getContent() === this.element) {
        var newIcon = e.layer._icon;
        _ember['default'].$(newIcon).addClass('active');
        this.set('selectedIcon', newIcon);

        this.get('map.mapbox').setView(e.layer._latlng, 14);
        _ember['default'].run(this, this._openPopup, e.popup);
      }
    },

    _onPopupClosed: function _onPopupClosed(e) {
      if (e.popup.getContent() === this.element) {
        var curIcon = this.get('selectedIcon');
        _ember['default'].$(curIcon).removeClass('active');
        this.set('selectedIcon', null);

        // this.get('map.mapbox').fitBounds(this.get('map.layer').getBounds(), {padding: [50,50]});
        _ember['default'].run(this, this.set, 'isPopupOpen', false);
      }
    },

    _openPopup: function _openPopup(popup) {
      this.set('isPopupOpen', true);
      _ember['default'].run.next(popup, popup.update);
    }
  });
});