define('web/routes/events/list', ['exports', 'ember', 'web/mixins/load-more', 'web/mixins/loading-model', 'web/mixins/records-search', 'web/mixins/build-params'], function (exports, _ember, _webMixinsLoadMore, _webMixinsLoadingModel, _webMixinsRecordsSearch, _webMixinsBuildParams) {
  exports['default'] = _ember['default'].Route.extend(_webMixinsLoadingModel.RouteLoadingMixin, _webMixinsRecordsSearch.RouteSearchMixin, _webMixinsLoadMore.RouteLoadMore, {
    queryParams: {
      search: { refreshModel: true }
    },

    model: function model(params) {
      var _this = this;

      return this.store.query('event', (0, _webMixinsBuildParams.filterParams)(params))['catch'](function (err) {
        return _this.flashMessages.danger(err.message);
      });
    }
  });
});